import React, { Component } from 'react';
import { Provider } from 'react-redux';

import Main from 'modules/main/components/Main/Main';

// Store
import store from 'store/store';

import './assets/style/style.scss';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <Main />
        </div>
      </Provider>
    );
  }
}

export default App;
