import React from 'react';
import cx from 'classnames';

import './FailMessage.scss';

const FailMessage = ({className, text}) => {
  return (
      <div className={cx('fail-message', {
        [className]: className,
      })}>
        <div className="fail-message__text">{text}</div>
      </div>
  )
};

export default FailMessage;
