import React from 'react';
import { Link } from 'react-router-dom';

import { NAVIGATION } from 'constants/index';
import { ReactComponent as IconLogo } from 'assets/images/logo.svg';

import './Footer.scss';

const Footer = () => {
  const paragraphList = [
    {
      id: 1,
      text: (
        <p className="footer__paragraph">
          Несанкционированное копирование, распространение, а также публикация материалов сайта в
          любых целях запрещены.
        </p>
      ),
    },
    {
      id: 2,
      text: (
        <p className="footer__paragraph">
          Брокерские услуги предоставляются ООО «АЛОР +» на основании Лицензии № 077-04827-100000 от
          13.03.2001 г., выдана Федеральной службой по финансовым рынкам без ограничения срока
          действия.
        </p>
      ),
    },
    {
      id: 3,
      text: (
        <p className="footer__paragraph">
          Депозитарные услуги предоставляются ООО «АЛОР +» на основании Лицензии № 077-10965-000100
          от 22.01.2008 г., выдана Федеральной службой по финансовым рынкам без ограничения срока
          действия.
        </p>
      ),
    },
    {
      id: 4,
      text: (
        <p className="footer__paragraph">
          Предоставленные на настоящем сайте услуги предоставляются ООО «АЛОР +» в рамках
          деятельности по инвестиционному консультированию. сведения об инвестиционном советнике ООО
          «АЛОР +» внесены в {'  '}
          <a
            href="https://www.cbr.ru/registries/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__paragraph-link"
          >
            реестр
          </a>{' '}
          Банком России 21.12.2018 года.
        </p>
      ),
    },
    {
      id: 5,
      text: (
        <p className="footer__paragraph">
          Раскрытие информации -{' '}
          <a
            href="https://www.alorbroker.ru/openinfo/openinfo"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__paragraph-link"
          >
            https://www.alorbroker.ru/openinfo/openinfo
          </a>
        </p>
      ),
    },
    {
      id: 6,
      text: (
        <p className="footer__paragraph">
          Сведения о ставках доходности, результатах инвестиционных решений являются индикативными,
          представлены исключительно для наглядности и не должны рассматриваться в качестве гарантий
          или обещаний в будущем эффективности деятельности (доходности вложений). Результаты
          инвестиционных решений клиента зависят от множества факторов, в том числе от суммы
          вложений, выбранного тарифного плана, сложившейся рыночной ситуации. Проведение операций
          типа «шорт» сопряжено с дополнительными рисками изменения цены финансового инструмента,
          что может привести к потере денежных средств.
        </p>
      ),
    },
    {
      id: 7,
      text: (
        <p className="footer__paragraph">
          Перед заключением договора клиенту следует самостоятельно оценить целесообразность,
          экономическую обоснованность, юридические и иные последствия, риски и выгоды от операций с
          ценными бумагами/контрактами/валютой, иными предлагаемыми Компанией инвестиционными
          продуктами, принимая решения исключительно своей волей и в своем интересе, в том числе
          предварительно изучив условия заключенных с Компанией договоров и ознакомившись с {'  '}
          <a
            href="https://storage.alorbroker.ru/upload/%D0%94%D0%B5%D0%BA%D0%BB%D0%B0%D1%80%D0%B0%D1%86%D0%B8%D1%8F%20%D0%BE%20%D1%80%D0%B8%D1%81%D0%BA%D0%B0%D1%85_01.12.2019.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__paragraph-link"
          >
            Декларацией о рисках
          </a>
          , связанных с проведением операций на рынке ценных бумаг и срочном рынке (Приложение 5 к
          Регламенту брокерского обслуживания Компании).
        </p>
      ),
    },
    {
      id: 8,
      text: (
        <p className="footer__paragraph">
          По любым возникающим вопросам, а также в случае необходимости получения дополнительной
          информации просьба обращаться к сотрудникам Компании по указанным выше телефонам и
          адресам.
        </p>
      ),
    },
    // {
    //   id: 8,
    //   text: (
    //     <p className="footer__paragraph">
    //       Правообладатель программного обеспечения для ЭВМ «Tradematic Trader» - ООО «Трэйдматик».
    //       «Автоследование» является зарегистрированным торговым знаком ООО «Трэйдматик».
    //     </p>
    //   ),
    // },
  ];

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__flex">
          <div className="footer__left">
            <Link to="/" className="footer__logo">
              <IconLogo className="footer__logo-img" />
            </Link>
          </div>
          <div className="footer__right">
            <nav className="footer__nav" role="navigation">
              <ul className="footer__nav-list">
                {NAVIGATION.map(({ title, href }) => (
                  <li className="footer__nav-list-item" key={title}>
                    <Link to={href} className="footer__nav-list-item-link">
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>

        <div className="footer__disclaimer">
          {paragraphList.map((item) => (
            <div className="footer__paragraph" key={item.id}>
              {item.text}
            </div>
          ))}
        </div>

        <div className="footer__bottom">
          <p className="footer__copyright">
            © 1997 – {new Date().getFullYear()}. Брокерская компания «АЛОР БРОКЕР»
          </p>

          <div className="footer__bottom-right">
            <a href="tel:8-800-775-11-99" className="footer__phone">
              8 800 775-11-99
            </a>
            <a href="mailto:FM@alor.ru" className="footer__email">
              FM@alor.ru
            </a>
            <div className="footer__social">
              <a
                href="//vk.com/alorbroker"
                target={'blank'}
                rel={'noopener'}
                className="footer__social-item"
              >
                ВКОНТАКТЕ
              </a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="//www.facebook.com/ALORBROKER"
                target={'blank'}
                rel={'noopener'}
                className="footer__social-item"
              >
                FACEBOOK
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
