import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import StrategiesItem from 'modules/main/components/StrategiesItem/StrategiesItem';
// import StrategiesListTumblers from 'modules/main/components/StrategiesListTumblers/StrategiesListTumblers';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import FailMessage from 'modules/shared/components/FailMessage/FailMessage';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { LOADING, FAIL, NO_STRATEGIES, SUCCESS, YELLOW } from 'constants/index';

import './StrategiesList.scss';

const StrategiesList = React.forwardRef(({ strategies, status, className, isPage }, ref) => {
  // const [isFondMarket, setIsFondMarket] = useState(false);
  // const [isCurrencyMarket, setIsCurrencyMarket] = useState(false);
  // const [isStockInstrument, setIsStockInstrument] = useState(false);
  // const [isBondsInstrument, setIsBondsInstrument] = useState(false);
  // const [filteredStrategies, setFilteredStrategies] = useState(strategies);

  // const filterByMarket = (data) => {
  //   if (isFondMarket && data.marketname === 'ММВБ') {
  //     return true;
  //   }
  //
  //   if (isCurrencyMarket && data.marketname === 'SPBEX') {
  //     return true;
  //   }
  //
  //   if ((isFondMarket && isCurrencyMarket) || (!isFondMarket && !isCurrencyMarket)) {
  //     return true;
  //   }
  //
  //   return false;
  // };

  // const filterByType = (data) => {
  //   if (
  //     isStockInstrument &&
  //     data.instrumenttypes.find((instrument) => instrument.toLowerCase() === 'акции')
  //   ) {
  //     return true;
  //   }
  //
  //   if (
  //     isBondsInstrument &&
  //     data.instrumenttypes.find((instrument) => instrument.toLowerCase() === 'облигации')
  //   ) {
  //     return true;
  //   }
  //
  //   if ((isBondsInstrument && isStockInstrument) || (!isBondsInstrument && !isStockInstrument)) {
  //     return true;
  //   }
  //   return false;
  // };

  /*const tumblersList = [
    {
      id: 'market-1',
      value: 'Фондовый',
      isActive: isFondMarket,
      group: 'market',
      onClick: () => setIsFondMarket((prevState) => !prevState),
    },
    {
      id: 'market-2',
      value: 'Валютный',
      isActive: isCurrencyMarket,
      group: 'market',
      onClick: () => setIsCurrencyMarket((prevState) => !prevState),
    },
    {
      id: 'instruments-1',
      value: 'Акции',
      isActive: isStockInstrument,
      group: 'instruments',
      onClick: () => setIsStockInstrument((prevState) => !prevState),
    },
    {
      id: 'instruments-2',
      value: 'Облигации',
      isActive: isBondsInstrument,
      group: 'instruments',
      onClick: () => setIsBondsInstrument((prevState) => !prevState),
    },
  ];*/

  /* useEffect(() => {
    if (strategies && strategies.length) {
      if (!isFondMarket && !isCurrencyMarket && !isStockInstrument && !isBondsInstrument) {
        setFilteredStrategies(strategies);
      } else if (isFondMarket && isCurrencyMarket && isStockInstrument && isBondsInstrument) {
        setFilteredStrategies(strategies);
      } else {
        const result = strategies.filter(filterByMarket).filter(filterByType);

        setFilteredStrategies(result);
      }
    }
    /!* eslint-disable react-hooks/exhaustive-deps *!/
  }, [strategies, isFondMarket, isCurrencyMarket, isStockInstrument, isBondsInstrument]);
  /!* eslint-disable react-hooks/exhaustive-deps *!/*/

  return (
    <section
      className={cx('strategies-list', {
        [className]: className,
      })}
      ref={ref}
    >
      <div className="strategies-list__container container">
        <h2 className="strategies-list__heading">Стратегии</h2>

        {/* <div className="strategies-list__filters">
          <StrategiesListTumblers tumblersList={tumblersList} />
        </div>*/}

        <div className="strategies-list__main">
          {status === LOADING && (
            <div className="strategies-list__loader">
              <CircleLoader />
            </div>
          )}

          {status === FAIL && (
            <FailMessage text="К сожалению нам не удалось отобразить стратегии. Попробуйте обновить страницу или вернуться на сайт позже" />
          )}

          {status === NO_STRATEGIES && (
            <FailMessage text="Вашему инвестпрофилю не соответствует ни одна из представленных стратегий" />
          )}

          {status === SUCCESS &&
            strategies &&
            Array.isArray(strategies) &&
            strategies.length >= 1 &&
            strategies.map((item) => (
              <StrategiesItem
                className="strategies-list__item"
                strategiesData={item}
                key={item.strategyid}
                // realUsers={realUsers.filter((realUser) => realUser.strategy === item.name)}
              />
            ))}
        </div>
        {!isPage && (
          <div className="strategies-list__footer">
            {status === SUCCESS && (
              <StandardButton
                isLink
                href="/strategies"
                className="strategies-list__show-all-btn"
                title="Посмотреть стратегии"
                bgType={YELLOW}
              />
            )}
          </div>
        )}
      </div>
    </section>
  );
});

StrategiesList.propTypes = {
  strategies: PropTypes.arrayOf(
    PropTypes.shape({
      strategyid: PropTypes.string,
      author: PropTypes.string,
      risklevelid: PropTypes.string,
      name: PropTypes.string,
      taskid: PropTypes.string,
      taskresult: PropTypes.shape({
        apr: PropTypes.string,
      }),
    }),
  ),
  status: PropTypes.string,
  isPage: PropTypes.bool,
  className: PropTypes.string,
};

StrategiesList.defaultProps = {
  strategies: [],
  status: null,
  className: null,
  isPage: false,
};

export default StrategiesList;
