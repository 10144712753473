import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// Actions
import { removeRedirect } from 'modules/main/actions';

const AccessRoute = ({
  component: Component,
  isPrivate,
  isLoggedIn,
  locationDetector,
  redirectTo,
  dispatch,
  ...rest
}) => {
  return (
    <Route
      /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
      {...rest}
      /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
      render={({ location }) => {
        if ((isLoggedIn && isPrivate) || (!isLoggedIn && !isPrivate)) {
          const componentProps = {};

          if (locationDetector) {
            componentProps[locationDetector.propName] =
              location.pathname.indexOf(locationDetector.pattern) > -1;
          }

          /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
          return <Component {...componentProps} />;
          /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
        }

        if (isPrivate && !isLoggedIn) {
          return <Redirect to="/" />;
        }

        if (!isPrivate && isLoggedIn) {
          const localRedirectTo = redirectTo;
          if (localRedirectTo) {
            dispatch(removeRedirect());
          }
          return <Redirect to={localRedirectTo || '/lk'} />;
        }

        return null;
      }}
    />
  );
};

AccessRoute.propTypes = {
  // eslint-disable-next-line
  component: PropTypes.any.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  locationDetector: PropTypes.shape({
    pattern: PropTypes.string,
    propName: PropTypes.string,
  }),
  redirectTo: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

AccessRoute.defaultProps = {
  locationDetector: null,
  redirectTo: null,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.lk.isLoggedIn,
  redirectTo: state.main.redirectTo,
});

export default connect(mapStateToProps)(AccessRoute);
