import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import WorkSchemeBlock from 'modules/main/components/WorkSchemeBlock/WorkSchemeBlock';

import './WorkScheme.scss';

const WorkScheme = ({ className, items }) => {
  return (
    <div
      className={cx('work-scheme', {
        [className]: className,
      })}
    >
      <div className="work-scheme__inner-wrap">
        {items.map((item) => (
          <WorkSchemeBlock key={item.id} info={item} />
        ))}
      </div>
    </div>
  );
};

WorkScheme.defaultProps = {
  className: null,
  items: [],
};

WorkScheme.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WorkScheme;
