import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StrategyCharacteristicItem from 'modules/main/components/StrategyCharacteristicItem/StrategyCharacteristicItem';
import Chart from 'modules/shared/components/Chart/Chart';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import PopupWrap from 'modules/shared/components/PopupWrap/PopupWrap';
import LkChangeFeaturesPopup from 'modules/lk/components/LkChangeFeaturesPopup/LkChangeFeaturesPopup';
import LkCommentsPopup from 'modules/lk/components/LkCommentsPopup/LkCommentsPopup';

import { declOfNum, formatDateMMDDYYYY, formatNumber, riskDefiner } from 'helpers';
import { getBgChartForStrategy } from 'modules/main/actions';
import { YELLOW } from 'constants/index';

import './LkStrategyItem.scss';

const LkStrategyItem = ({ className, data, dispatch }) => {
  const [chartData, setChartData] = useState([]);
  const [isChartDataReady, setIsChartDataReady] = useState(false);
  const [chartDataError, setChartDataError] = useState('');
  const [isChangeFeaturesPopupOpen, setIsChangeFeaturesPopupOpen] = useState(false);
  const [isCommentPopupOpen, setIsCommentPopupOpen] = useState(false);
  // const [isChangeStrategyCompaniesListAvailable, setIsChangeStrategyCompaniesListAvailable] = useState(true);

  useEffect(() => {
    setIsChartDataReady(false);

    dispatch(getBgChartForStrategy(data.taskid, 10))
      .then((innerRes) => {
        if (innerRes && innerRes.length) {
          setChartData(
            innerRes.map((item) => {
              return {
                x: formatDateMMDDYYYY(item.timestamp),
                y: Number(item.equity),
              };
            }),
          );
          setChartDataError('');
        } else {
          setChartDataError('Недостаточно данных для вывода графика');
        }

        setIsChartDataReady(true);
      })
      .catch(() => {
        setIsChartDataReady(true);
        setChartDataError('Недостаточно данных для вывода графика');
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data.taskid]);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <div
      className={cx('lk-strategy-item', {
        [className]: className,
      })}
    >
      <div className="lk-strategy-item__top">
        <p className="lk-strategy-item__number">{data && data.account}</p>
      </div>

      <div className="lk-strategy-item__desc-block">
        <h4 className="lk-strategy-item__title">{data && data.name}</h4>
        <div
          className="lk-strategy-item__desc"
          dangerouslySetInnerHTML={{ __html: data && data.fulldescription }}
        />
      </div>

      <div className="lk-strategy-item__main">
        <div className="lk-strategy-item__features-block">
          <div className="lk-strategy-item__features-top">
            <p className="lk-strategy-item__features-title">Состав стратегии</p>
          </div>
          <div className="lk-strategy-item__features-list">
            {/* ИНВЕСТИЦИОННЫЙ ПРОФИЛЬ */}
            {data && data.riskscoremin && (
              <StrategyCharacteristicItem
                className="lk-strategy-item__features-item"
                value={riskDefiner(data.riskscoremin)}
                title="Инвестиционный профиль"
              />
            )}
            {/* /ИНВЕСТИЦИОННЫЙ ПРОФИЛЬ */}

            {/* ИНСТРУМЕНТЫ */}
            {data && data.instrumenttypes && (
              <StrategyCharacteristicItem
                className="lk-strategy-item__features-item"
                value={data.instrumenttypes}
                isArr
                title="Инструменты"
              />
            )}
            {/* /ИНСТРУМЕНТЫ */}

            {/* МИНИМАЛЬНАЯ СУММА */}
            {data && data.minimuminvestment && (
              <StrategyCharacteristicItem
                className="lk-strategy-item__features-item"
                value={`${formatNumber(data.minimuminvestment)},00 ₽`}
                title="Минимальная сумма"
              />
            )}
            {/* /МИНИМАЛЬНАЯ СУММА */}

            {/* СРОК ИНВЕСТИЦИЙ */}
            {data && data.investmentperiod && (
              <StrategyCharacteristicItem
                className="lk-strategy-item__features-item"
                value={`${data.investmentperiod} ${declOfNum(Math.abs(data.investmentperiod), [
                  'месяц',
                  'месяца',
                  'месяцев',
                ])}`}
                title="Срок инвестиций"
              />
            )}
            {/* /СРОК ИНВЕСТИЦИЙ */}

            {/* СТОИМОСТЬ АВТОСЛЕДОВАНИЯ */}
            {data && data.cost && (
              <StrategyCharacteristicItem
                className="lk-strategy-item__features-item"
                value={data.cost}
                title="Стоимость Автоследования"
              />
            )}
            {/* /СТОИМОСТЬ АВТОСЛЕДОВАНИЯ */}
          </div>

          <div className="lk-strategy-item__button-list">
            <StandardButton
              className="lk-strategy-item__features-change"
              title="Изменить состав"
              isLink={false}
              bgType={YELLOW}
              onClick={() => setIsChangeFeaturesPopupOpen(true)}
              disabled={data && data.account}
            />

            <StandardButton
              className="lk-strategy-item__features-change"
              title="Добавить комментарий"
              isLink={false}
              bgType={YELLOW}
              onClick={() => setIsCommentPopupOpen(true)}
            />
          </div>
        </div>

        <div className="lk-strategy-item__chart">
          <Chart
            isChartDataReady={isChartDataReady}
            chartData={chartData}
            chartDataError={chartDataError}
          />
        </div>
      </div>

      <PopupWrap
        mounted={isChangeFeaturesPopupOpen}
        closePopup={() => setIsChangeFeaturesPopupOpen(false)}
      >
        <LkChangeFeaturesPopup
          id={data && data.strategyid}
          strategyData={data}
          closePopup={() => setIsChangeFeaturesPopupOpen(false)}
        />
      </PopupWrap>

      <PopupWrap mounted={isCommentPopupOpen} closePopup={() => setIsCommentPopupOpen(false)}>
        <LkCommentsPopup
          id={data && data.strategyid}
          closePopup={() => setIsCommentPopupOpen(false)}
        />
      </PopupWrap>
    </div>
  );
};

LkStrategyItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  data: PropTypes.shape({
    strategyid: PropTypes.string,
    strategy: PropTypes.string,
    account: PropTypes.string,
    status: PropTypes.string,
    strategystatusname: PropTypes.string,
    message: PropTypes.string,
    hwid: PropTypes.string,
    computer: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

LkStrategyItem.defauktProps = {
  className: null,
};

export default connect()(LkStrategyItem);
