import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './StrategyCharacteristicItem.scss';

const StrategyCharacteristicItem = ({ className, title, value, icon, isArr }) => {
  return (
    <div
      className={cx('strategy-characteristic-item', {
        [className]: className,
      })}
    >
      <div className="strategy-characteristic-item__title-wrap">
        {icon && (
          <div className="strategy-characteristic-item__title-icon-wrap">
            <img src={icon} alt="" className="strategy-characteristic-item__title-icon" />
          </div>
        )}

        <p className="strategy-characteristic-item__title">{title}</p>
      </div>

      {isArr ? (
        <div
          className={cx('strategy-characteristic-item__value-wrap', {
            'strategy-characteristic-item__value-wrap_with-icon': Boolean(icon),
          })}
        >
          {value.map((item) => (
            <p className="strategy-characteristic-item__value" key={item}>
              {item}
            </p>
          ))}
        </div>
      ) : (
        <p
          className={cx('strategy-characteristic-item__value', {
            'strategy-characteristic-item__value_with-icon': Boolean(icon),
          })}
        >
          {value}
        </p>
      )}
    </div>
  );
};

StrategyCharacteristicItem.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  isArr: PropTypes.bool,
};

StrategyCharacteristicItem.defaultProps = {
  icon: null,
  className: null,
  isArr: false,
};

export default StrategyCharacteristicItem;
