import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import FailMessage from 'modules/shared/components/FailMessage/FailMessage';
import PopupWrap from 'modules/shared/components/PopupWrap/PopupWrap';
import IirReportPopup from 'modules/lk/components/IirReportPopup/IirReportPopup';
import LkAccountItem from 'modules/lk/components/LkAccountItem/LkAccountItem';

import { LOADING, FAIL, YELLOW, YELLOW_BORDER } from 'constants/index';

import './LkMain.scss';

const LkMain = ({
  accounts,
  strategies,
  accountsLoadingStatus,
  getClientStrategiesFunction,
  isDataForIrrPopup,
}) => {
  const [isReportPopupOpen, setIsReportPopupOpen] = useState(false);
  const [isDealsPopupOpen, setIsDealsPopupOpen] = useState(false);

  return (
    <div className="lk-main">
      <div className="lk-main__container container">
        <div className="lk-main__top">
          <h2 className="lk-main__top-title">Мои счета</h2>

          <div className="lk-main__top-buttons">
            <StandardButton
              isLink={false}
              title="Отчет по ИИР"
              onClick={() => setIsReportPopupOpen(true)}
              bgType={YELLOW_BORDER}
              className="lk-main__top-buttons-item"
            />

            <StandardButton
              isLink={false}
              title="Отчет по сделкам"
              onClick={() => setIsDealsPopupOpen(true)}
              bgType={YELLOW_BORDER}
              className="lk-main__top-buttons-item"
            />

            <StandardButton
              isLink
              href="/strategies"
              title="Перейти к стратегиям"
              bgType={YELLOW}
              className="lk-main__top-buttons-item"
            />
          </div>
        </div>

        <div className="lk-main__list">
          {accounts.map((item) => (
            <LkAccountItem
              accountData={item}
              className="lk-main__list-item"
              strategy={
                strategies && strategies.filter((strategy) => strategy.account === item.account)[0]
              }
              getClientStrategiesFunction={getClientStrategiesFunction}
              key={item.account}
            />
          ))}
        </div>

        {accountsLoadingStatus === LOADING && (
          <div className="lk-main__loader">
            <CircleLoader />
          </div>
        )}

        {accountsLoadingStatus === FAIL && (
          <FailMessage
            className="lk-main__fail-message"
            text="К сожалению, нам не удалось загрузить Ваши счета. Попробуйте повторить запрос позже"
          />
        )}

        {!accountsLoadingStatus && accounts && accounts.length === 0 && (
          <FailMessage
            className="lk-main__fail-message"
            text={
              <p>
                Пополните счет в{' '}
                <a href="https://lk.alor.ru/login" target="_blank" rel="noopener noreferrer">
                  личном кабинете
                </a>{' '}
                АЛОР БРОКЕР или{' '}
                <a
                  href="https://storage.alorbroker.ru/openinfo/%D0%A0%D0%B5%D0%BA%D0%B2%D0%B8%D0%B7%D0%B8%D1%82%D1%8B_2020.09.29.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  банковским переводом
                </a>
                , используя реквизиты в личном кабинете.
              </p>
            }
          />
        )}

        <PopupWrap
          mounted={isReportPopupOpen}
          closePopup={() => setIsReportPopupOpen(false)}
          className={cx('', {
            'popup-wrap_iir': isDataForIrrPopup,
          })}
        >
          <IirReportPopup closePopup={() => setIsReportPopupOpen(false)} />
        </PopupWrap>

        <PopupWrap
          mounted={isDealsPopupOpen}
          closePopup={() => setIsDealsPopupOpen(false)}
          className={cx('', {
            'popup-wrap_iir': isDataForIrrPopup,
          })}
        >
          <IirReportPopup closePopup={() => setIsDealsPopupOpen(false)} isDealsPopup />
        </PopupWrap>
      </div>
    </div>
  );
};

LkMain.propTypes = {
  strategies: PropTypes.arrayOf(
    PropTypes.shape({
      strategyid: PropTypes.string,
      strategy: PropTypes.string,
      account: PropTypes.string,
      status: PropTypes.string,
      strategystatusname: PropTypes.string,
      message: PropTypes.string,
      hwid: PropTypes.string,
      computer: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  accountsLoadingStatus: PropTypes.string,
  balance: PropTypes.string,
  totalDifference: PropTypes.string,
  getClientStrategiesFunction: PropTypes.func.isRequired,
};

LkMain.defaultProps = {
  balance: '0,00',
  totalDifference: '0,00',
  accountsLoadingStatus: null,
};

const mapStateToProps = (state) => ({
  isDataForIrrPopup: state.lk.isDataForIrrPopup,
});

export default connect(mapStateToProps)(LkMain);
