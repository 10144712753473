import React, { useMemo, createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './ChartNav.scss';

const ChartNav = ({ list, className, currentNavItem, isRendered }) => {
  /**
   * Создаем массив из рефов для каждого из айтемов массива,
   * пришедшего из пропсов
   * */
  const itemsRefArr = useMemo(() => list.map(() => createRef()), [list]);

  const [tabsBarActiveStyle, setTabsBarActiveStyle] = useState(null);

  /** Устанавливаем стили для активного таба */
  useEffect(() => {
    if (isRendered && itemsRefArr) {
      setTabsBarActiveStyle({
        width: `${100 / itemsRefArr.length}%`,
        left: `${(100 / itemsRefArr.length) * currentNavItem}%`,
      });
    }
  }, [currentNavItem, isRendered, itemsRefArr]);

  return (
    <div
      className={cx('chart-nav', {
        [className]: className,
      })}
    >
      {list.map((item, index) => (
        <button
          className={cx('chart-nav__item', {
            'chart-nav__item_active': currentNavItem === item.id,
            'chart-nav__item_period': item.isPeriod,
          })}
          type="button"
          onClick={item.onClick}
          ref={itemsRefArr[index]}
          key={item.id}
          style={{
            width: `${100 / itemsRefArr.length}%`,
          }}
        >
          {item.title}
        </button>
      ))}

      <div className="chart-nav__tabs-bar">
        <div className="chart-nav__tabs-bar-active" style={tabsBarActiveStyle} />
      </div>
    </div>
  );
};

ChartNav.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      onClick: PropTypes.func,
      isPeriod: PropTypes.bool,
    }),
  ).isRequired,
  currentNavItem: PropTypes.number.isRequired,
};

ChartNav.defaultProps = {
  className: null,
};

export default ChartNav;
