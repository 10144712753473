import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { getLastDeals } from 'modules/main/actions';

import './Trades.scss';

const Trades = ({ className, id }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getLastDeals(id))
        .then((res) => {
          // setList(res.slice(Math.max(res.length - 5, 0)));
          setList(res);
        })
        .catch(() => {});
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [id]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      className={cx('trades', {
        [className]: className,
      })}
    >
      <h2 className="trades__title">Последние закрытые сделки</h2>

      <div className="trades__list">
        {list.map((item, i) => (
          <div className="trades__item" key={`${item.symbol}-${i}`}>
            <p className="trades__item-title">{item.symbol}</p>
            <p
              className={cx('trades__item-percent', {
                'trades__item-percent_negative': Number(item.changepct) < 0,
              })}
            >
              {Number(item.changepct) > 0 && '+'}
              {item.changepct}%
            </p>
            <p className="trades__item-date">
              {item.exitdatetime && item.exitdatetime.slice(0, 10)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

Trades.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

Trades.defaultProps = {
  className: null,
};

export default Trades;
