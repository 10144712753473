import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useWindowSize from '@rehooks/window-size';
import useToggleAndOutClick from 'use-toggle-and-outclick';
import ym from 'react-yandex-metrika';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import MobileMenu from 'modules/shared/components/MobileMenu/MobileMenu';
import ProfileDropdown from 'modules/shared/components/ProfileDropdown/ProfileDropdown';
import TopBar from 'modules/shared/components/TopBar/TopBar';

import { NAVIGATION, YELLOW, YELLOW_BORDER } from 'constants/index';
import { ReactComponent as IconLogo } from 'assets/images/logo.svg';
import { ReactComponent as IconBurger } from 'assets/images/menu-toggle.svg';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import fakePhoto from 'assets/images/default-avatar.svg';

import { setIsUserLoggedIn } from 'modules/lk/actions';
import { setIsMenuOpen } from 'modules/main/actions';
import { removeCookie, getCookie } from 'helpers/index';

import './Header.scss';

const Header = ({ isLoggedIn, isLight, name, dispatch, isMenuOpen }) => {
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const windowSize = useWindowSize();

  const openDropdown = () => {
    setIsMenuOpen(false);
    toggleDrop();
  };

  const logOut = () => {
    const userName = getCookie('alor_user_name');
    if (userName) {
      removeCookie(userName);
      removeCookie('alor_user_name');
      removeCookie('alor_refresh_token');
      removeCookie('alor_token');
      dispatch(setIsUserLoggedIn(false));
    }
    toggleDrop();
  };

  useEffect(() => {
    if (isDropOpen && windowSize.innerWidth <= 400) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isDropOpen, windowSize]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (windowSize.innerWidth > 1180) {
      dispatch(setIsMenuOpen(false));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [windowSize]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <header
      className={cx('header', {
        'header_menu-open': isMenuOpen,
        'header_dropdown-open': isDropOpen,
        'header_logged-in': isLoggedIn,
        header_light: isLight,
      })}
    >
      {windowSize.innerWidth > 780 ? <TopBar /> : null}
      <div className="header__container container">
        <Link to="/" className="header__logo" onClick={() => dispatch(setIsMenuOpen(false))}>
          <IconLogo className="header__logo-img" />
        </Link>

        <div
          className={cx('header__right', {
            'header__right_logged-in': isLoggedIn,
          })}
        >
          {windowSize.innerWidth > 1180 && (
            <nav className="header__nav" role="navigation">
              <ul className="header__nav-list">
                {NAVIGATION.map(({ title, href }) => (
                  <li className="header__nav-list-item-wrap" key={title}>
                    <Link
                      to={href}
                      onClick={() => dispatch(setIsMenuOpen(false))}
                      className="header__nav-list-item"
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}

          {isLoggedIn ? (
            <button className="header__avatar" onClick={openDropdown} type="button">
              <img src={fakePhoto} alt="" className="header__avatar-img" />
            </button>
          ) : (
            <>
              {windowSize.innerWidth > 1180 && (
                <div className="header__buttons">
                  <StandardButton
                    title="Открыть счет"
                    isLink
                    isOutAppLink
                    href="https://www.alorbroker.ru/open"
                    bgType={YELLOW}
                    className="header__buttons-item"
                    onClick={() => ym('reachGoal', 'openaccount')}
                  />
                  <StandardButton
                    title="Войти"
                    isLink
                    isOutAppLink
                    href={process.env.REACT_APP_SSO_AUTH_URL}
                    bgType={YELLOW_BORDER}
                    className="header__buttons-item"
                  />
                </div>
              )}
            </>
          )}

          {windowSize.innerWidth <= 1180 && (
            <button
              type="button"
              className="header__menu-toggle"
              onClick={() => {
                dispatch(setIsMenuOpen(!isMenuOpen));
              }}
            >
              {isMenuOpen ? (
                <IconClose className="header__menu-toggle-icon" />
              ) : (
                <IconBurger className="header__menu-toggle-icon" />
              )}
            </button>
          )}

          {isDropOpen && windowSize.innerWidth > 400 && (
            <ProfileDropdown ref={dropEl} name={name} goToLk={closeExplicitly} logOut={logOut} />
          )}
        </div>
      </div>

      {isDropOpen && windowSize.innerWidth <= 400 && (
        <ProfileDropdown ref={dropEl} name={name} goToLk={closeExplicitly} logOut={logOut} />
      )}

      <MobileMenu
        mounted={isMenuOpen && windowSize.innerWidth <= 1180}
        closeMenu={() => dispatch(setIsMenuOpen(false))}
        isLoggedIn={isLoggedIn}
      />
    </header>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isLight: PropTypes.bool,
  name: PropTypes.string,
  isMenuOpen: PropTypes.bool,
};

Header.defaultProps = {
  isLight: false,
  isMenuOpen: false,
  name: 'Юзер',
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.lk.isLoggedIn,
  isMenuOpen: state.main.isMenuOpen,
});

export default connect(mapStateToProps)(Header);
