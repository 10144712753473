import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './StrategyDesc.scss';

const StrategyDesc = ({ desc, className, title }) => {
  return (
    <div
      className={cx('strategy-desc', {
        [className]: className,
      })}
    >
      <h2 className="strategy-desc__title">{title}</h2>

      <div className="strategy-desc__main">
        <div className="strategy-desc__desc" dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
    </div>
  );
};

StrategyDesc.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  className: PropTypes.string,
};

StrategyDesc.defaultProps = {
  className: null,
};

export default StrategyDesc;
