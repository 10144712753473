import React from 'react';

import { ReactComponent as IconPeople } from 'assets/images/icon-two-people.svg';
import { ReactComponent as IconDiagram } from 'assets/images/icon-diagram.svg';
import { ReactComponent as IconMoney } from 'assets/images/icon-money-exchange.svg';

import './Features.scss';

const Features = () => {
  const features = [
    {
      id: 1,
      icon: <IconPeople className="features__item-icon" />,
      title: 'Более 1 млн человек ежегодно открывают брокерские счета',
      desc: 'С целью заработать больше, чем на банковском вкладе.',
    },
    {
      id: 2,
      icon: <IconDiagram className="features__item-icon" />,
      title: 'Торгуя самостоятельно, новички рискуют не получить нужный результат',
      desc: 'Инвестиции требуют значительного опыта, времени и знаний.',
    },
    {
      id: 3,
      icon: <IconMoney className="features__item-icon" />,
      title: 'Подключайте торговые стратегии профессиональных управляющих',
      desc:
        'Сделки по Вашему счету будут совершаться автоматически, следуя сигналам опытных трейдеров.',
    },
  ];
  return (
    <div className="features">
      <div className="features__container">
        <h2 className="features__title">Инвестиции с FOLLOW ME: легко, надежно, на автопилоте</h2>

        <div className="features__list">
          {features.map((item) => (
            <div className="features__item" key={item.id}>
              <div className="features__item-icon-wrap">{item.icon}</div>
              <div className="features__item-title">{item.title}</div>
              <div className="features__item-desc">{item.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
