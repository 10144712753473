import React from 'react';
import PropTypes from 'prop-types';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import iconMark from 'assets/images/icon-map-mark.svg';

const YandexMap = ({ center }) => {
  return (
    <YMaps>
      <Map
        style={{
          width: '100%',
          height: '100%',
        }}
        defaultState={{ center, zoom: 16 }}
      >
        <Placemark
          geometry={center}
          modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
          properties={{
            hintContent: '',
          }}
          options={{
            iconLayout: 'default#image',
            iconImageHref: iconMark,
          }}
        />
      </Map>
    </YMaps>
  );
};

YandexMap.defaultProps = {
  center: [55.75, 37.57],
};

YandexMap.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number),
};

export default YandexMap;
