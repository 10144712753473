import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { YELLOW, YELLOW_BORDER } from 'constants/index';

import './SmsForm.scss';

const SmsForm = ({
  code,
  onChangeCode,
  closeModal,
  isCodeValid,
  operationSignHandler,
  seconds,
  isTimeEnded,
  operationCreateHandler,
  operationDocuments,
}) => {
  const inputRef = useRef(null);

  /** Ставим фокус на инпуте */
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <div className="sms-form">
      <div className="sms-form__content">
        <div className="sms-form__code">
          <div className="sms-form__code-title">Введите СМС-код для подтверждения</div>
          <div className="sms-form__code-input-holder">
            <input
              ref={inputRef}
              inputMode="numeric"
              type="text"
              maxLength={5}
              max={5}
              value={code}
              onChange={onChangeCode}
              className="sms-form__code-input"
            />
          </div>
        </div>
        {!isTimeEnded && (
          <div className="sms-form__resend-msg">Выслать код повторно через: {seconds}</div>
        )}
        {isTimeEnded && (
          <div className="sms-form__resend-btn-wrap">
            <button type="button" className="sms-form__resend-btn" onClick={operationCreateHandler}>
              Выслать код повторно
            </button>
          </div>
        )}
        {operationDocuments.length > 0 && (
          <ul className="sms-form__doc-list">
            {operationDocuments.map((doc) => (
              <li key={doc.id} className="sms-form__doc-list-item">
                <a
                  href={`/doc/${doc.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sms-form__doc-list-item-link"
                >
                  {doc.title}
                </a>
              </li>
            ))}
          </ul>
        )}
        <div className="sms-form__btn-wrap">
          <StandardButton
            className="sms-form__btn"
            isLink={false}
            onClick={closeModal}
            title="Отмена"
            bgType={YELLOW_BORDER}
          />
          <StandardButton
            className="sms-form__btn"
            isLink={false}
            onClick={operationSignHandler}
            title="Продолжить"
            bgType={YELLOW}
            disabled={!isCodeValid}
          />
        </div>
      </div>
    </div>
  );
};

SmsForm.defaultProps = {
  operationDocuments: [],
};

SmsForm.propTypes = {
  code: PropTypes.string.isRequired,
  onChangeCode: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isCodeValid: PropTypes.bool.isRequired,
  operationSignHandler: PropTypes.func.isRequired,
  seconds: PropTypes.number.isRequired,
  isTimeEnded: PropTypes.bool.isRequired,
  operationCreateHandler: PropTypes.func.isRequired,
  operationDocuments: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SmsForm;
