import React from 'react';
import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import { BLUE, BLUE_BORDER, LOADING } from 'constants/index';

import './SyncStrategyForm.scss';

const SyncStrategyForm = ({ closePopup, syncStrategyHandler, status }) => {
  return (
    <div className="sync-strategy-form">
      {!status && (
        <>
          <h2 className="sync-strategy-form__title">
            Синхронизация стратегии
          </h2>
          <div className="sync-strategy-form__desc">Вы действительно хотите запустить синхронизацию?</div>

          <div className="sync-strategy-form__list">
            <StandardButton
              className="sync-strategy-form__list-item"
              isLink={false}
              onClick={closePopup}
              title="Отмена"
              bgType={BLUE_BORDER}
            />
            <StandardButton
              className="sync-strategy-form__list-item"
              isLink={false}
              onClick={syncStrategyHandler}
              title="Синхронизировать"
              bgType={BLUE}
            />
          </div>
        </>
      )}

      {status === LOADING && (
        <div className="sync-strategy-form__loader">
          <CircleLoader />
        </div>
      )}
    </div>
  );
};

SyncStrategyForm.propTypes = {
  closePopup: PropTypes.func.isRequired,
  syncStrategyHandler: PropTypes.func.isRequired,
  status: PropTypes.string,
};

SyncStrategyForm.defaultProps = {
  status: null,
};

export default SyncStrategyForm;
