import * as types from 'modules/lk/actions/action-types';

const initialState = {
  clientInfo: {},
  isLoggedIn: false,
  isDataForIrrPopup: false,
};

const lk = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        clientInfo: action.payload.res,
      };

    case types.SET_DATA_FOR_IIR_POPUP:
      return {
        ...state,
        isDataForIrrPopup: action.payload.isDataForIrrPopup,
      };

    case types.SET_IS_USER_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      };

    default:
      return state;
  }
};

export default lk;
