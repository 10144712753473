import React from 'react';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import fakePhoto from 'assets/images/user-default-avatar.svg';

import './AuthorInfo.scss';

const AuthorInfo = ({ authorInfo, isLoading }) => {
  return (
    <div className="author-info">
      {isLoading ? (
        <CircleLoader />
      ) : (
        <div className="author-info__main">
          <div className="author-info__top">
            <img src={authorInfo.image || fakePhoto} alt="" className="author-info__photo" />

            <div className="author-info__name">
              {authorInfo.name}
              {'  '} {authorInfo.lastname}
            </div>
          </div>

          <div className="author-info__info">
            <h3 className="author-info__info-title">Об авторе</h3>
            <div
              className="author-info__info-content"
              dangerouslySetInnerHTML={{ __html: authorInfo.comments }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorInfo;
