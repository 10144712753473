import React from 'react';
import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import { BLUE, BLUE_BORDER } from 'constants/index';

import './DeactivateStrategyWarning.scss';

const DeactivateStrategyWarning = ({ closePopup, deactivateStrategyHandler }) => {
  return (
    <div className="deactivate-strategy-warning">
      <div className="deactivate-strategy-warning__msg">
        Вы хотите отключить стратегию. Вы всегда сможете снова подключить эту стратегию.
      </div>
      <div className="deactivate-strategy-warning__btn-wrap">
        <StandardButton
          className="deactivate-strategy-warning__btn"
          isLink={false}
          onClick={closePopup}
          title="Отмена"
          bgType={BLUE_BORDER}
        />
        <StandardButton
          className="deactivate-strategy-warning__btn"
          isLink={false}
          onClick={deactivateStrategyHandler}
          title="Отключить"
          bgType={BLUE}
        />
      </div>
    </div>
  );
};

DeactivateStrategyWarning.propTypes = {
  closePopup: PropTypes.func.isRequired,
  deactivateStrategyHandler: PropTypes.func.isRequired,
};

export default DeactivateStrategyWarning;
