import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Breadcrumbs from 'modules/shared/components/Breadcrumbs/Breadcrumbs';
import ManagersList from 'modules/main/components/ManagersList/ManagersList';
import UserForm from 'modules/main/components/UserForm/UserForm';

import { FAIL, LOADING, NO_STRATEGIES, SUCCESS } from 'constants/index';

import { getStrategies } from 'modules/main/actions';

import './Managers.scss';

const Managers = () => {
  const dispatch = useDispatch();
  const [strategies, setStrategies] = useState([]);
  const [strategiesStatus, setStrategiesStatus] = useState('');

  /** Запрашиваем список стратегий */
  useEffect(() => {
    setStrategiesStatus(LOADING);
    dispatch(getStrategies())
      .then((res) => {
        if (res) {
          setStrategies(
            res.map((el) => {
              if (el.strategyid === '991') {
                return {
                  ...el,
                  userid: '12815',
                };
              }

              if (el.strategyid === '997') {
                return {
                  ...el,
                  userid: '12815',
                };
              }
              return el;
            }),
          );
          setStrategiesStatus(SUCCESS);
        } else {
          setStrategiesStatus(NO_STRATEGIES);
        }
      })
      .catch(() => {
        setStrategiesStatus(FAIL);
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <main className="managers">
      <div className="managers__container container">
        <Breadcrumbs
          link={window.location.pathname}
          anchor={'Управляющие'}
          className="managers__breadcrumbs"
        />

        <h1 className="managers__title">Управляющие</h1>

        {/* СПИСОК УПРАВЛЯЮЩИХ */}
        <ManagersList strategies={strategies} status={strategiesStatus} />
        {/* /СПИСОК УПРАВЛЯЮЩИХ */}

        <div className="managers__btn-wrap">
          <Link
            to="/"
            onClick={() => localStorage.setItem('scrollTo', 'strategies')}
            className="standard-button standard-button_yellow managers__btn"
          >
            Посмотреть стратегии
          </Link>
        </div>
      </div>

      <div className="managers__form-wrap">
        <UserForm
          className="managers__form"
          title="Получить консультацию"
          description="Оставьте контактную информацию, наши специалисты свяжутся с Вами в ближайшее время"
        />
      </div>
    </main>
  );
};

export default Managers;
