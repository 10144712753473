import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useWindowSize from '@rehooks/window-size';
import useInterval from 'react-useinterval';
import ym from 'react-yandex-metrika';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import DeactivateStrategyWarning from 'modules/main/components/DeactivateStrategyWarning/DeactivateStrategyWarning';
import SyncStrategyForm from 'modules/main/components/SyncStrategyForm/SyncStrategyForm';
import CloseAllPositionsPopup from 'modules/main/components/CloseAllPositionsPopup/CloseAllPositionsPopup';
import PopupWrap from 'modules/shared/components/PopupWrap/PopupWrap';
import Chart from 'modules/shared/components/Chart/Chart';
// import Profit from 'modules/shared/components/Profit/Profit';
import DeactivateStrategyPopup from 'modules/main/components/DeactivateStrategyPopup/DeactivateStrategyPopup';
import SmsContent from 'modules/main/components/SmsContent/SmsContent';
import ActivateStrategyResult from 'modules/main/components/ActivateStrategyResult/ActivateStrategyResult';

import {
  formatDateMMDDYYYYFromAccountData,
  formatNumber,
  getCookie,
  parseJwt,
} from 'helpers/index';
import {
  YELLOW_BORDER,
  FAIL,
  LOADING,
  SUCCESS,
  YELLOW,
  DEACTIVATE_WARNING,
  OPERATION_RESULT,
  SMS_FORM,
  AUTO_FOLLOW_OFF,
} from 'constants/index';
// import { getStrategies } from 'modules/main/actions';
import {
  turnOffStrategy,
  getAccountChart,
  syncStrategy,
  closeAllPositions,
} from 'modules/lk/actions';

import {
  getOperationContext,
  getOperationSmsCode,
  getStrategies,
  operationCreate,
  operationSign,
  refreshToken,
} from 'modules/main/actions';

import './LkAccountItem.scss';

const LkAccountItem = ({
  className,
  accountData,
  dispatch,
  getClientStrategiesFunction,
  strategy,
}) => {
  const windowSize = useWindowSize();
  const [strategies, setStrategies] = useState(null);
  const [strategyId, setStrategyId] = useState(null);

  // const [strategyName, setStrategyName] = useState('');
  // const [percent, setPercent] = useState('');
  // const [turnOffStrategyStatus, setTurnOffStrategyStatus] = useState(null);
  const [syncStrategyStatus, setSyncStrategyStatus] = useState(null);
  const [closeAllPositionsStatus, setCloseAllPositionsStatus] = useState(null);

  // const [isPopupTurnOffStrategyOpen, setIsPopupTurnOffStrategyOpen] = useState(false);
  const [isPopupDeactivateStrategyOpen, setIsPopupDeactivateStrategyOpen] = useState(false);

  const [activateStrategyStatus, setActivateStrategyStatus] = useState('');
  const [operationProcess, setOperationProcess] = useState('');

  const [code, setCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [operationID, setOperationID] = useState(null);

  const [seconds, setSeconds] = useState(0);
  const [isTimeEnded, setIsTimeEnded] = useState(false);
  const [interval, setInterval] = useState(1000);

  const [validations, setValidations] = useState([]);
  const [operationDocuments, setOperationDocuments] = useState([]);

  const [isPopupSyncStrategyOpen, setIsPopupSyncStrategyOpen] = useState(false);
  const [isPopupCloseAllPositionsOpen, setIsPopupCloseAllPositionsOpen] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [isChartDataReady, setIsChartDataReady] = useState(false);

  /** Получаем полный список стратегий */
  useEffect(() => {
    dispatch(getStrategies())
      .then((res) => {
        if (res) {
          setStrategies(res);
        }
      })
      .catch(() => {});
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Синхронизируем стратегию */
  const syncStrategyHandler = () => {
    setSyncStrategyStatus(LOADING);
    dispatch(syncStrategy(accountData && accountData.accountid))
      .then(() => {
        setTimeout(() => {
          setSyncStrategyStatus(SUCCESS);
        }, 5000);
      })
      .catch(() => {
        setSyncStrategyStatus(FAIL);
      });
  };

  /** Закрыть все позиции */
  const closeAllPositionsHandler = () => {
    setCloseAllPositionsStatus(LOADING);
    dispatch(closeAllPositions(accountData && accountData.accountid))
      .then(() => {
        setTimeout(() => {
          setCloseAllPositionsStatus(SUCCESS);
        }, 5000);
      })
      .catch(() => {
        setCloseAllPositionsStatus(FAIL);
      });
  };

  /*  useEffect(() => {
    if (strategy && strategy.strategy) {
      setStrategyName(strategy.strategy);
    }
  }, [strategy]);*/

  /*useEffect(() => {
    if (strategyName) {
      dispatch(getStrategies())
        .then((res) => {
          res.forEach((item) => {
            if (item.name === strategyName) {
              setPercent(item.taskresult.apr);
            }
          });
        })
        .catch(() => {});
    }
    /!* eslint-disable react-hooks/exhaustive-deps *!/
  }, [strategyName]);
  /!* eslint-enable react-hooks/exhaustive-deps *!/*/

  useEffect(() => {
    if (accountData.accountid) {
      dispatch(getAccountChart(accountData.accountid))
        .then((res) => {
          setChartData(
            res.map((item) => {
              return {
                x: formatDateMMDDYYYYFromAccountData(item.timestamp),
                y: Number(item.value),
              };
            }),
          );
          setIsChartDataReady(true);
        })
        .catch(() => {});
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [accountData.accountid]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Проверка на длину смс кода */
  useEffect(() => {
    setIsCodeValid(code.length === 5);
  }, [code]);

  /** Таймер для повторного запроса смс */
  const startTimer = () => {
    if (seconds > 0) {
      setSeconds((prevState) => prevState - 1);
    } else {
      setIsTimeEnded(true);
      setInterval(null);
    }
  };

  useInterval(startTimer, interval);

  /** Получаем номер подключенной стратегии, например 994 */
  useEffect(() => {
    if (strategy && strategies) {
      setStrategyId(strategies.find((el) => el.name === strategy.strategy).strategyid);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [strategy, strategies]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Закрыть модалку - Отключить стратегию */
  const popupDeactivateStrategyClose = () => {
    setIsPopupDeactivateStrategyOpen(false);
    setActivateStrategyStatus('');
    setOperationProcess('');
    localStorage.removeItem('operation-docs');
    setCode('');
  };

  /** Отключаем стратегию */
  const deactivateStrategyHandler = () => {
    setCode('');

    if (isTimeEnded) {
      setActivateStrategyStatus(LOADING);

      dispatch(refreshToken(getCookie('alor_token'), getCookie('alor_refresh_token')))
        .then(({ jwt }) => {
          if (jwt) {
            return dispatch(
              operationCreate(
                AUTO_FOLLOW_OFF,
                parseJwt(jwt).sub.slice(2),
                jwt,
                strategyId,
                strategy.account.split(' ')[0],
              ),
            );
          }
        })
        .then(({ createResult, accessToken }) => {
          if (createResult && createResult.success && createResult.operationId) {
            /** Если создание операции успешно, запрашиваем контекст операции, параметры:
             * operationId - id стратегии, например 12722,
             * accessToken - токен, */
            setOperationID(createResult.operationId); // зачем это сохранять???
            return dispatch(getOperationContext(createResult.operationId, accessToken));
          } else {
            /** Если при создании операции есть причины по которым невозможна операция,
             * сохраняем список причин для вывода */
            setValidations(createResult.validations);
            setActivateStrategyStatus(FAIL);
          }
        })
        .then(({ operationContext, accessToken }) => {
          if (operationContext && operationContext.agreementNumber && operationContext.documents) {
            /** Если получаем контекст операции, сохраняем и показываем документ,
             * и запрашиваем смску, параметры:
             * agreementNumber - номер учётки без первых двух символов P0,
             * operationId - id стратегии, например 12722,
             * accessToken - токен, */
            setOperationDocuments(operationContext.documents);
            localStorage.setItem('operation-docs', JSON.stringify(operationContext.documents));
            return dispatch(
              getOperationSmsCode(
                operationContext.agreementNumber,
                operationContext.id,
                accessToken,
              ),
            );
          } else {
            setActivateStrategyStatus(FAIL);
          }
        })
        .then(({ smsResult }) => {
          if (smsResult && smsResult.success) {
            setInterval(1000);
            setIsTimeEnded(false);
            setSeconds(60);

            setActivateStrategyStatus(SUCCESS);
          } else {
            setActivateStrategyStatus(FAIL);
          }
        })
        .catch(() => {
          setActivateStrategyStatus(FAIL);
        });
    } else {
      setActivateStrategyStatus(SUCCESS);
    }
  };

  /** Верификация смс кода */
  const operationSignHandler = () => {
    setCode('');
    setActivateStrategyStatus(LOADING);

    dispatch(refreshToken(getCookie('alor_token'), getCookie('alor_refresh_token')))
      .then(({ jwt }) => {
        if (jwt) {
          return dispatch(operationSign(parseJwt(jwt).sub.slice(2), operationID, code, jwt));
        }
      })
      .then(({ smsResult }) => {
        if (smsResult && smsResult.success) {
          return dispatch(turnOffStrategy(strategy.strategyid));
        } else {
          setActivateStrategyStatus(FAIL);
        }
      })
      .then(() => {
        setTimeout(() => {
          getClientStrategiesFunction();
          setInterval(1000);
          setIsTimeEnded(false);
          setSeconds(0);
          setOperationID(null);
          setActivateStrategyStatus(SUCCESS);
        }, 5000);
      })
      .catch(() => {
        setActivateStrategyStatus(FAIL);
      });
  };

  return (
    <div
      className={cx('lk-account-item', {
        [className]: className,
        // 'lk-account-item_no-strategy': !strategy,
      })}
    >
      <div className="lk-account-item__left">
        <div className="lk-account-item__left-main">
          <p className="lk-account-item__number">№ {accountData.account}</p>
          {strategy ? <p className="lk-account-item__title">{strategy.strategy}</p> : <></>}

          <div className="lk-account-item__balance-block">
            <p className="lk-account-item__balance-title">Баланс</p>
            <div className="lk-account-item__balance-itself">
              {formatNumber(accountData.value)} {'  '} {accountData.currencycode === 'RUB' && ' ₽'}{' '}
              {accountData.currencycode === 'USD' && ' $'}
            </div>
          </div>
        </div>

        {strategy && windowSize.innerWidth > 1220 ? (
          <div className="lk-account-item__buttons">
            <StandardButton
              className="lk-account-item__buttons-item"
              isLink={false}
              title="Отключить"
              bgType={YELLOW_BORDER}
              onClick={() => {
                ym('reachGoal', 'deactivated');
                setIsPopupDeactivateStrategyOpen(true);
                setOperationProcess(DEACTIVATE_WARNING);
              }}
              isLoading={activateStrategyStatus === LOADING}
            />
            {/*<StandardButton*/}
            {/*  className="lk-account-item__buttons-item"*/}
            {/*  isLink={false}*/}
            {/*  title="Закрыть все позиции"*/}
            {/*  bgType={YELLOW_BORDER}*/}
            {/*  onClick={() => setIsPopupCloseAllPositionsOpen(true)}*/}
            {/*  isLoading={closeAllPositionsStatus === LOADING}*/}
            {/*/>*/}
            <StandardButton
              className="lk-account-item__buttons-item"
              isLink={false}
              title="Синхронизировать"
              bgType={YELLOW}
              onClick={() => setIsPopupSyncStrategyOpen(true)}
              isLoading={syncStrategyStatus === LOADING}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="lk-account-item__right">
        {/*{strategy ? <Profit className="lk-account-item__profit" percent={percent} /> : <></>}*/}

        <Chart
          chartData={chartData}
          isChartDataReady={isChartDataReady}
          className="lk-account-item__chart"
          marginLeft={40}
          marginRight={0}
        />

        {/* {Object.entries(accountData.positionspercent) &&
        Object.entries(accountData.positionspercent).length ? (
          <div className="lk-account-item__portfolio-content">
            <p className="lk-account-item__portfolio-content-title">Состав портфеля</p>
            <div className="lk-account-item__portfolio-content-list">
              {Object.entries(accountData.positionspercent).map((group) => (
                <div className="lk-account-item__portfolio-content-item" key={group}>
                  {group.map((item, index) => (
                    <p className="lk-account-item__portfolio-content-item-part" key={item}>
                      {index === 1 ? `${Math.floor(Number(item))}%` : item}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}*/}

        {strategy && windowSize.innerWidth <= 1220 ? (
          <div className="lk-account-item__buttons">
            <StandardButton
              className="lk-account-item__buttons-item"
              isLink={false}
              title="Отключить"
              bgType={YELLOW_BORDER}
              onClick={() => {
                ym('reachGoal', 'deactivated');
                setIsPopupDeactivateStrategyOpen(true);
                setOperationProcess(DEACTIVATE_WARNING);
              }}
              isLoading={activateStrategyStatus === LOADING}
            />
            {/*<StandardButton*/}
            {/*  className="lk-account-item__buttons-item"*/}
            {/*  isLink={false}*/}
            {/*  title="Закрыть все позиции"*/}
            {/*  bgType={YELLOW_BORDER}*/}
            {/*  onClick={() => setIsPopupCloseAllPositionsOpen(true)}*/}
            {/*  isLoading={closeAllPositionsStatus === LOADING}*/}
            {/*/>*/}
            <StandardButton
              className="lk-account-item__buttons-item"
              isLink={false}
              title="Синхронизировать"
              bgType={YELLOW}
              onClick={() => setIsPopupSyncStrategyOpen(true)}
              isLoading={syncStrategyStatus === LOADING}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* ПОПАП - ОТКЛЮЧЕНИЯ СТРАТЕГИИ */}
      <PopupWrap
        mounted={isPopupDeactivateStrategyOpen}
        closePopup={popupDeactivateStrategyClose}
        className="deactivate-strategy-popup"
      >
        <DeactivateStrategyPopup
          status={activateStrategyStatus}
          isWarning={operationProcess === DEACTIVATE_WARNING}
          isResult={operationProcess === OPERATION_RESULT}
        />

        {operationProcess === DEACTIVATE_WARNING && (
          <DeactivateStrategyWarning
            closePopup={popupDeactivateStrategyClose}
            deactivateStrategyHandler={() => {
              /** Запускаем запросы на создание операции - отключить стратегию */
              deactivateStrategyHandler();

              /** Скрываем контент выбора счета */
              setOperationProcess(SMS_FORM);
            }}
          />
        )}

        {operationProcess === SMS_FORM && (
          <SmsContent
            status={activateStrategyStatus}
            closeModal={popupDeactivateStrategyClose}
            operationCreateHandler={() => deactivateStrategyHandler()}
            code={code}
            onChangeCode={({ target }) => setCode(target.value)}
            isCodeValid={isCodeValid}
            operationSignHandler={() => {
              /** Запускаем запросы на смс верификацию */
              operationSignHandler();

              /** Скрываем форму ввода смс */
              setOperationProcess(OPERATION_RESULT);
            }}
            seconds={seconds}
            isTimeEnded={isTimeEnded}
            isDeactivation
            validations={
              validations.length > 0 ? validations.filter((i) => i.isError) : validations
            }
            operationDocuments={operationDocuments}
          />
        )}

        {operationProcess === OPERATION_RESULT && (
          <ActivateStrategyResult
            closePopup={popupDeactivateStrategyClose}
            status={activateStrategyStatus}
            isDeactivation
          />
        )}
      </PopupWrap>
      {/* /ПОПАП - ОТКЛЮЧЕНИЯ СТРАТЕГИИ */}

      {/* ПОПАП - СИНХРОНИЗАЦИИ СТРАТЕГИИ */}
      <PopupWrap
        mounted={isPopupSyncStrategyOpen}
        closePopup={() => {
          setIsPopupSyncStrategyOpen(false);
          setSyncStrategyStatus(null);
        }}
      >
        {(!syncStrategyStatus || syncStrategyStatus === LOADING) && (
          <SyncStrategyForm
            closePopup={() => {
              setIsPopupSyncStrategyOpen(false);
              setSyncStrategyStatus(null);
            }}
            status={syncStrategyStatus}
            syncStrategyHandler={syncStrategyHandler}
          />
        )}
        {syncStrategyStatus === SUCCESS && <p>Синхронизация прошла успешно!</p>}
        {syncStrategyStatus === FAIL && (
          <p>
            К сожалению, что-то пошло не так и у нас не получилось синхронизировать стратегию.
            Попробуйте повторить позже
          </p>
        )}
      </PopupWrap>
      {/* /ПОПАП - СИНХРОНИЗАЦИИ СТРАТЕГИИ */}

      {/* ПОПАП - ЗАКРЫТЬ ВСЕ ПОЗИЦИИ */}
      <PopupWrap
        mounted={isPopupCloseAllPositionsOpen}
        closePopup={() => {
          setIsPopupCloseAllPositionsOpen(false);
          setCloseAllPositionsStatus(null);
        }}
      >
        {(!closeAllPositionsStatus || closeAllPositionsStatus === LOADING) && (
          <CloseAllPositionsPopup
            closePopup={() => {
              setIsPopupCloseAllPositionsOpen(false);
              setCloseAllPositionsStatus(null);
            }}
            status={closeAllPositionsStatus}
            closeAllPositionsHandler={closeAllPositionsHandler}
          />
        )}
        {closeAllPositionsStatus === SUCCESS && <p>Все позиции закрыты!</p>}
        {closeAllPositionsStatus === FAIL && (
          <p>
            К сожалению, что-то пошло не так и у нас не получилось закрыть все позиции. Попробуйте
            повторить позже.
          </p>
        )}
      </PopupWrap>
      {/* /ПОПАП - ЗАКРЫТЬ ВСЕ ПОЗИЦИИ */}
    </div>
  );
};

LkAccountItem.propTypes = {
  className: PropTypes.string,
  accountData: PropTypes.shape({
    positionspercent: PropTypes.shape(),
    currencycode: PropTypes.string,
    value: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  getClientStrategiesFunction: PropTypes.func.isRequired,
  strategy: PropTypes.shape({
    strategy: PropTypes.string,
  }),
};

LkAccountItem.defaultProps = {
  accountData: null,
  className: null,
  strategy: null,
};

export default connect()(LkAccountItem);
