export const GET_CLIENT_INFO_SUCCESS = 'GET_CLIENT_INFO_SUCCESS';
export const GET_CLIENT_INFO_FAIL = 'GET_CLIENT_INFO_FAIL';
export const SYNC_STRATEGY_SUCCESS = 'SYNC_STRATEGY_SUCCESS';
export const SYNC_STRATEGY_FAIL = 'SYNC_STRATEGY_FAIL';
export const GET_CLIENT_STRATEGIES_SUCCESS = 'GET_CLIENT_STRATEGIES_SUCCESS';
export const GET_CLIENT_STRATEGIES_FAIL = 'GET_CLIENT_STRATEGIES_FAIL';
export const TURN_OFF_STRATEGY_SUCCESS = 'TURN_OFF_STRATEGY_SUCCESS';
export const TURN_OFF_STRATEGY_FAIL = 'TURN_OFF_STRATEGY_FAIL';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const GENERATE_REPORT_FAIL = 'GENERATE_REPORT_FAIL';
export const GET_CLIENT_DETAILS_BY_USERNAME_SUCCESS = 'GET_CLIENT_DETAILS_BY_USERNAME_SUCCESS';
export const GET_CLIENT_DETAILS_BY_USERNAME_FAIL = 'GET_CLIENT_DETAILS_BY_USERNAME_FAIL';
export const SET_DATA_FOR_IIR_POPUP = 'SET_DATA_FOR_IIR_POPUP';
export const GET_ACCOUNT_CHART_SUCCESS = 'GET_ACCOUNT_CHART_SUCCESS';
export const GET_ACCOUNT_CHART_FAIL = 'GET_ACCOUNT_CHART_FAIL';
export const SET_IS_USER_LOGGED_IN = 'SET_IS_USER_LOGGED_IN';
export const GET_LIST_OF_ADMIN_INSTRUMENTS_SUCCESS = 'GET_LIST_OF_ADMIN_INSTRUMENTS_SUCCESS';
export const GET_LIST_OF_ADMIN_INSTRUMENTS_FAIL = 'GET_LIST_OF_ADMIN_INSTRUMENTS_FAIL';
export const SAVE_PORTFOLIO_FEATURES_LIST_SUCCESS = 'SAVE_PORTFOLIO_FEATURES_LIST_SUCCESS';
export const SAVE_PORTFOLIO_FEATURES_LIST_FAIL = 'SAVE_PORTFOLIO_FEATURES_LIST_FAIL';
export const SAVE_STRATEGY_COMMENT_SUCCESS = 'SAVE_STRATEGY_COMMENT_SUCCESS';
export const SAVE_STRATEGY_COMMENT_FAIL = 'SAVE_STRATEGY_COMMENT_FAIL';
export const GET_DEALS_FOR_REPORT_SUCCESS = 'GET_DEALS_FOR_REPORT_SUCCESS';
export const GET_DEALS_FOR_REPORT_FAIL = 'GET_DEALS_FOR_REPORT_FAIL';
export const GET_ADVISOR_STRATEGIES_LIST_SUCCESS = 'GET_ADVISOR_STRATEGIES_LIST_SUCCESS';
export const GET_ADVISOR_STRATEGIES_LIST_FAIL = 'GET_ADVISOR_STRATEGIES_LIST_FAIL';
export const CLOSE_ALL_POSITIONS_SUCCESS = 'CLOSE_ALL_POSITIONS_SUCCESS';
export const CLOSE_ALL_POSITIONS_FAIL = 'CLOSE_ALL_POSITIONS_FAIL';
