import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
// import { CSSTransition } from 'react-transition-group';

import { getStrategies } from 'modules/main/actions';
// import { getClientDetailsByUserName } from 'modules/lk/actions';

import TopBanner from 'modules/main/components/TopBanner/TopBanner';
import StrategiesList from 'modules/main/components/StrategiesList/StrategiesList';
import PartnerCta from 'modules/main/components/PartnerCta/PartnerCta';
import Features from 'modules/main/components/Features/Features';
import WorkFlow from 'modules/main/components/WorkFlow/WorkFlow';
import Partner from 'modules/main/components/Partner/Partner';

import { LOADING, FAIL, NO_STRATEGIES, SUCCESS } from 'constants/index';
// import { getCookie } from 'helpers';

import './Homepage.scss';

const HomePage = ({ dispatch, isLoggedIn, isMenuOpen }) => {
  const [strategiesStatus, setStrategiesStatus] = useState('');
  // const [userRisk, setUserRisk] = useState(0);
  const [strategies, setStrategies] = useState(null);
  // const [realUsers, setRealUsers] = useState([]);
  // const [isRendered, setIsRendered] = useState(false);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     dispatch(getClientDetailsByUserName(getCookie('alor_user_name')))
  //       // dispatch(getClientDetailsByUserName('P070955'))
  //       .then((res) => {
  //         if (res) {
  //           if (res.isqualified !== '1') {
  //             setUserRisk(res.riskscore);
  //           }
  //         }
  //       })
  //       .catch(() => {});
  //   }
  //   /* eslint-disable react-hooks/exhaustive-deps */
  // }, [isLoggedIn]);
  // /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setStrategiesStatus(LOADING);
    dispatch(getStrategies())
      .then((res) => {
        if (res) {
          setStrategies(
            res.map((el) => {
              if (el.strategyid === '991') {
                return {
                  ...el,
                  userid: '12815',
                };
              }

              if (el.strategyid === '997') {
                return {
                  ...el,
                  userid: '12815',
                };
              }
              return el;
            }),
          );
          setStrategiesStatus(SUCCESS);
        } else {
          setStrategiesStatus(NO_STRATEGIES);
        }
        // if (userRisk) {
        //   setStrategies(res.filter((item) => item.riskscoremin <= userRisk));
        //   setStrategiesStatus(null);
        //   if (!res.filter((item) => item.riskscoremin <= userRisk).length) {
        //     setStrategiesStatus(NO_STRATEGIES);
        //   }
        // } else {
        //   setStrategies(res);
        //   setStrategiesStatus(null);
        // }
      })
      .catch(() => {
        setStrategiesStatus(FAIL);
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  // useEffect(() => {
  //   dispatch(getStrategiesStatistic()).then((res) => {
  //     setRealUsers(res);
  //   });
  //   /* eslint-disable react-hooks/exhaustive-deps */
  // }, []);
  // /* eslint-enable react-hooks/exhaustive-deps */

  const strategiesListRef = useRef(null);
  const partnerRef = useRef(null);
  const workFlow = useRef(null);
  const howToConnect = useRef(null);
  const partnerCta = useRef(null);
  const scrollToRef = (ref) => {
    window.scroll({ top: ref.current.offsetTop, left: 0, behavior: 'smooth' });
  };

  /** При переходе на страницу, если в localStorage есть запись scrollToStrategies
   * делаем скролл к нужному блоку,
   * после окончания сткролла стираем запись в localStorage */
  useEffect(() => {
    const scrollTo = localStorage.getItem('scrollTo');

    const getOffsetTop = () => {
      switch (scrollTo) {
        case 'strategies':
          return strategiesListRef.current.offsetTop;
        case 'howToConnect':
          return howToConnect.current.offsetTop;
        default:
          return 0;
      }
    };

    if (scrollTo && strategiesStatus === SUCCESS) {
      window.scroll({ top: getOffsetTop(), left: 0, behavior: 'smooth' });
      localStorage.removeItem('scrollTo');
    }
    // /* eslint-disable react-hooks/exhaustive-deps */
  }, [strategiesStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      className={cx('homepage', {
        'homepage_is-menu-open': isMenuOpen,
      })}
    >
      <TopBanner
        className="homepage__top-banner"
        scrollToStrategies={() => scrollToRef(strategiesListRef)}
        scrollToPartner={() => scrollToRef(partnerRef)}
        isLoggedIn={isLoggedIn}
      />

      <Features />

      <StrategiesList
        strategies={
          strategies && Array.isArray(strategies) && strategies.length > 0 && strategies.slice(0, 4)
        }
        status={strategiesStatus}
        // realUsers={realUsers}
        ref={strategiesListRef}
      />

      <WorkFlow
        ref={{
          ref1: workFlow,
          ref2: howToConnect,
        }}
        scrollToPartnerCta={() => scrollToRef(partnerCta)}
        scrollToStrategies={() => scrollToRef(strategiesListRef)}
      />

      <Partner ref={partnerRef} />

      <div ref={partnerCta} className="homepage__form-wrap">
        <PartnerCta />
      </div>
    </div>
  );
};

HomePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isMenuOpen: PropTypes.bool,
};

HomePage.defaultProps = {
  isLoggedIn: false,
  isMenuOpen: false,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.lk.isLoggedIn,
  isMenuOpen: state.main.isMenuOpen,
});

export default connect(mapStateToProps)(HomePage);
