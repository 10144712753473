import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconValidError } from 'assets/images/close-circle-outline.svg';

import './SmsValidations.scss';

const SmsValidations = ({ list, isDeactivation }) => {
  return (
    <div className="sms-validations">
      <ul className="sms-validations__list">
        {list.map((item) => (
          <li key={item.message} className="sms-validations__list-item">
            <IconValidError className="sms-validations__list-item-icon" />
            <span
              className="sms-validations__list-item-text"
              dangerouslySetInnerHTML={{ __html: item.message }}
            />
          </li>
        ))}
      </ul>
      <div className="sms-validations__msg">
        Исправьте комментарии выше и&nbsp;попробуйте <br />{' '}
        {isDeactivation ? 'отключить' : 'подключить'} стратегию позже.
      </div>
    </div>
  );
};

SmsValidations.defaultProps = {
  isDeactivation: false,
};

SmsValidations.propTypes = {
  isDeactivation: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      isError: PropTypes.bool,
    }),
  ).isRequired,
};

export default SmsValidations;
