import React from 'react';

import image from 'assets/images/how-it-works.svg';

import './HowItWorksInfo.scss';

const HowItWorksInfo = () => {
  return (
    <div className="how-it-works-info">
      <div className="how-it-works-info__col how-it-works-info__col_text">
        <div className="how-it-works-info__body">
          <p className="how-it-works-info__text">
            Система «FOLLOW ME» позволяет преобразовывать автоматизированным способом торговый
            сигнал Управляющего в совершение операций на подключенном к стратегии счете без
            непосредственного участия клиента.
          </p>
          <p className="how-it-works-info__text">
            Такая возможность достигается с помощью специального программного обеспечения и
            интеграции с АЛОР БРОКЕР.
          </p>
          <p className="how-it-works-info__text">
            АЛОР БРОКЕР – профессиональный участник рынка ценных бумаг, предоставляющий доступ к
            совершению торговых операций на фондовых биржах.
          </p>
        </div>
      </div>
      <div className="how-it-works-info__col how-it-works-info__col_img">
        <img src={image} alt="" className="how-it-works-info__img" />
      </div>
    </div>
  );
};

export default HowItWorksInfo;
