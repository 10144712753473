import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import './DocumentPage.scss';

const DocumentPage = ({ match: { params = {} } }) => {
  const [doc, setDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  /** Забираем из localStorage нужный нам документ и выводим на страницу */
  useEffect(() => {
    const docs = JSON.parse(localStorage.getItem('operation-docs'));

    if (docs && Array.isArray(docs)) {
      setDoc(docs.find((i) => i.id === +params.uniqueId));
      setIsLoading(false);
    }
    return () => {
      localStorage.removeItem('operation-docs');
    };
  }, [params.uniqueId]);

  return (
    <div className="document-page">
      <div className="document-page__container container">
        {isLoading && <CircleLoader className="document-page__loader" />}

        {!isLoading && doc && (
          <div className="document-page__content" dangerouslySetInnerHTML={{ __html: doc.body }} />
        )}

        {!isLoading && !doc && <div className="document-page__content">Документ не найден.</div>}
      </div>
    </div>
  );
};

DocumentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uniqueId: PropTypes.string,
    }),
  }).isRequired,
};

export default DocumentPage;
