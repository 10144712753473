import React from 'react';
import PropTypes from 'prop-types';

import ManagersItem from 'modules/main/components/ManagersItem/ManagersItem';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { FAIL, LOADING, NO_STRATEGIES, SUCCESS } from 'constants/index';

import './ManagersList.scss';

const ManagersList = ({ strategies, status }) => {
  /** Удаляем дупликаты из массива */
  const rmDuplicates = (arr, key) => [...new Map(arr.map((item) => [item[key], item])).values()];

  return (
    <div className="managers-list">
      {status === LOADING && (
        <div className="managers-list__loader">
          <CircleLoader />
        </div>
      )}

      {status === FAIL && (
        <p className="managers-list__msg managers-list__msg_fail">
          К сожалению нам не удалось отобразить список управляющих. Попробуйте обновить страницу или
          вернуться на сайт позже.
        </p>
      )}

      {status === NO_STRATEGIES && (
        <p className="managers-list__msg managers-list__msg_empty">Список управляющих пуст.</p>
      )}

      {status === SUCCESS &&
        strategies.length > 0 &&
        rmDuplicates(strategies, 'userid').map((item) => (
          <ManagersItem key={item.strategyid} strategyData={item} className="managers-list__item" />
        ))}
    </div>
  );
};

ManagersList.defaultProps = {
  strategies: [],
};

ManagersList.propTypes = {
  strategies: PropTypes.arrayOf(PropTypes.shape({})),
  status: PropTypes.string.isRequired,
};

export default ManagersList;
