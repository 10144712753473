import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import ym from 'react-yandex-metrika';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import UploadFiles from 'modules/shared/components/UploadFiles/UploadFiles';

import { validateEmail } from 'helpers/index';
import { sendUserDataForConsultation } from 'modules/main/actions';

import { ReactComponent as IconCheckbox } from 'assets/images/icon-check.svg';
import { LOADING, FAIL, SUCCESS, YELLOW } from 'constants/index';

import './BecomePartnerForm.scss';

const BecomePartnerForm = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const [formSendingStatus, setFormSendingStatus] = useState(null);

  /** Check Email validation */
  useEffect(() => {
    if (email && email.length >= 5) {
      setIsEmailValid(validateEmail(email));
    } else {
      setIsEmailValid(false);
    }
  }, [email]);

  const sendForm = () => {
    setFormSendingStatus(LOADING);

    dispatch(sendUserDataForConsultation(name, phone, email, isChecked))
      .then(() => {
        setFormSendingStatus(SUCCESS);
        setName('');
        setPhone('');
        setEmail('');
        setTimeout(() => {
          setFormSendingStatus(null);
        }, 10000);
      })
      .catch(() => {
        setFormSendingStatus(FAIL);
        setTimeout(() => {
          setFormSendingStatus(null);
        }, 10000);
      });
  };

  return (
    <div className="become-a-partner-form">
      <div className="become-a-partner-form__content">
        <div className="become-a-partner-form__head">
          <h2 className="become-a-partner-form__title">Предложить стратегию</h2>
          <p className="become-a-partner-form__sub-title">
            Оставьте контактную информацию, наши специалисты свяжутся с Вами в ближайшее время
          </p>
        </div>
        <div className="become-a-partner-form__body">
          <div className="become-a-partner-form__body-col become-a-partner-form__body-col_fields">
            <div className="become-a-partner-form__field">
              <div className="become-a-partner-form__input-holder">
                <input
                  type="text"
                  placeholder={'Имя'}
                  name="name"
                  value={name}
                  required
                  className="become-a-partner-form__input"
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
            </div>
            <div className="become-a-partner-form__field">
              <div className="become-a-partner-form__input-holder">
                <InputMask
                  className="become-a-partner-form__input"
                  type="tel"
                  placeholder={'Телефон'}
                  name="phone"
                  maskChar={null}
                  required
                  mask="+7\ (999) 999-99-99"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </div>
            </div>
            <div className="become-a-partner-form__field">
              <div className="become-a-partner-form__input-holder">
                <input
                  className="become-a-partner-form__input"
                  type="email"
                  placeholder={'Почта'}
                  name="email"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              {!isEmailValid && email && email.length > 10 && (
                <p className="become-a-partner-form__input-error">Введите валидный e-mail</p>
              )}
            </div>
          </div>
          <div className="become-a-partner-form__body-col become-a-partner-form__body-col_upload-file">
            <div className="become-a-partner-form__field">
              <UploadFiles />
            </div>
            <div className="become-a-partner-form__field">
              <label className="become-a-partner-form__privacy">
                <span className="become-a-partner-form__privacy-checkbox-wrap">
                  <button
                    className="become-a-partner-form__privacy-checkbox"
                    role="checkbox"
                    type="button"
                    aria-label="Согласен на обработку персональных данных"
                    aria-checked={isChecked}
                    onClick={() => setIsChecked((prevState) => !prevState)}
                  />

                  {isChecked && (
                    <IconCheckbox className="become-a-partner-form__privacy-checkbox-icon" />
                  )}
                </span>

                <a
                  href="https://www.alorbroker.ru/assent"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="become-a-partner-form__privacy-text"
                >
                  Согласен на обработку персональных данных
                </a>
              </label>
            </div>
          </div>
        </div>
        <div className="become-a-partner-form__footer">
          <StandardButton
            title="Отправить"
            className="become-a-partner-form__btn"
            isLink={false}
            onClick={() => {
              ym('reachGoal', 'formpartner');
              sendForm();
            }}
            disabled={!name || !isEmailValid || !phone || !isChecked}
            isLoading={formSendingStatus === LOADING}
            bgType={YELLOW}
          />
        </div>

        {formSendingStatus === SUCCESS && (
          <div className="become-a-partner-form__message become-a-partner-form__message_success">
            Ваше запрос на консультацию отправлен! С Вами свяжется наш менеджер.
          </div>
        )}

        {formSendingStatus === FAIL && (
          <div className="become-a-partner-form__message become-a-partner-form__message_fail">
            К сожалению, у нас не получилось отправить запрос. Попробуйте повторить позже.
          </div>
        )}
      </div>
    </div>
  );
};

export default BecomePartnerForm;
