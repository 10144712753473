import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  Crosshair,
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  AreaSeries,
  makeWidthFlexible,
  LineSeries,
} from 'react-vis';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import { formatDateMMDDYYFromDate, chartHintFormatDate } from 'helpers';

import './Chart.scss';

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

const Chart = ({
  className,
  isChartDataReady,
  chartData,
  chartDataError,
  hideYAxis,
  hideXAxis,
  height,
  marginRight,
  marginLeft,
  isNeedToShowHint,
}) => {
  const [isHintVisible, setIsHintVisible] = useState(false);
  const [crossHairValues, setCrossHairValues] = useState(null);

  return (
    <div
      className={cx('chart', {
        [className]: className,
      })}
    >
      {isChartDataReady ? (
        <div className="chart__inner">
          {!chartDataError ? (
            <>
              <FlexibleXYPlot
                height={height || 210}
                // margin={hideYAxis ? { right: 20 } : { right: 60 }}
                margin={{ right: marginRight || 60, left: marginLeft || 0 }}
              >
                <VerticalGridLines />
                <HorizontalGridLines />

                {!hideXAxis && (
                  <XAxis
                    tickFormat={(v, index) => {
                      if (index % 2 === 0) {
                        return `${formatDateMMDDYYFromDate(new Date(v))}`;
                      }
                    }}
                  />
                )}

                {!hideYAxis && <YAxis width={60} orientation="right" />}
                <AreaSeries
                  className="area-series-example"
                  curve={'curveMonotoneX'}
                  data={chartData}
                  fill="rgba(67, 83, 255, 0.08)"
                  color="rgba(67, 83, 255, 0.08)"
                />
                <LineSeries
                  data={chartData}
                  stroke="#2058BA"
                  strokeWidth={3}
                  curve={'curveMonotoneX'}
                  onSeriesMouseOver={() => setIsHintVisible(true)}
                  onSeriesMouseOut={() => setIsHintVisible(false)}
                  onNearestXY={(value) => {
                    setCrossHairValues([value]);
                  }}
                />

                {crossHairValues && crossHairValues[0] && isNeedToShowHint && isHintVisible && (
                  <Crosshair values={crossHairValues} style={{ background: 'black' }}>
                    <div className="chart__hint">
                      <p className="chart__hint-date">
                        {chartHintFormatDate(crossHairValues[0].x)}
                      </p>
                      <p className="chart__hint-profit-wrap">
                        <span className="chart__hint-profit-title">Доходность</span> {'  '}
                        <span
                          className={cx('chart__hint-profit', {
                            'chart__hint-profit_negative': Number(crossHairValues[0].y) < 0,
                            'chart__hint-profit_positive': Number(crossHairValues[0].y) > 0,
                          })}
                        >
                          {Number(crossHairValues[0].y) > 0 && '+'}
                          {crossHairValues[0].y}%
                        </span>
                      </p>
                    </div>
                  </Crosshair>
                )}
              </FlexibleXYPlot>
            </>
          ) : (
            <p className="chart__error-message">{chartDataError}</p>
          )}
        </div>
      ) : (
        <div className="chart__loader">
          <CircleLoader />
        </div>
      )}
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  isChartDataReady: PropTypes.bool,
  chartData: PropTypes.arrayOf(PropTypes.shape({})),
  chartDataError: PropTypes.string,
  hideYAxis: PropTypes.bool,
  hideXAxis: PropTypes.bool,
  height: PropTypes.number,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  isNeedToShowHint: PropTypes.bool,
};

Chart.defaultProps = {
  className: null,
  isChartDataReady: false,
  hideYAxis: false,
  hideXAxis: false,
  marginRight: null,
  marginLeft: null,
  isNeedToShowHint: false,
};

export default Chart;
