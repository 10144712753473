import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import './GoToStrategies.scss';

const GoToStrategies = ({ isFooter }) => {
  return (
    <div
      className={cx('go-to-strategies', {
        'go-to-strategies_footer': isFooter,
      })}
    >
      <Link
        to="/"
        onClick={() => localStorage.setItem('scrollTo', 'strategies')}
        className="standard-button standard-button_yellow go-to-strategies__btn"
      >
        Посмотреть стратегии
      </Link>
    </div>
  );
};

GoToStrategies.defaultProps = {
  isFooter: false,
};

GoToStrategies.propTypes = {
  isFooter: PropTypes.bool,
};

export default GoToStrategies;
