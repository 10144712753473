import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FailMessage from 'modules/shared/components/FailMessage/FailMessage';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import LkStrategyItem from 'modules/lk/components/LkStrategyItem/LkStrategyItem';
import StatisticTable from 'modules/lk/components/StatisticTable/StatisticTable';

import { getAdvisorStrategies } from 'modules/lk/actions';
import { getStrategiesStatistic } from 'modules/main/actions';
import { LOADING, SUCCESS, FAIL, NO_RESULT } from 'constants/index';

import './LkMainAdviser.scss';

const LkMainAdviser = ({ dispatch, userId }) => {
  const [strategies, setStrategies] = useState([]);
  const [statistic, setStatistic] = useState([]);
  const [strategiesLoadingStatus, setStrategiesLoadingStatus] = useState(null);

  useEffect(() => {
    setStrategiesLoadingStatus(LOADING);
    dispatch(getAdvisorStrategies())
      .then((res) => {
        if (
          res.filter((item) => item.userid === userId) &&
          res.filter((item) => item.userid === userId).length
        ) {
          setStrategies(res.filter((item) => item.userid === userId));
          setStrategiesLoadingStatus(SUCCESS);
        } else {
          setStrategiesLoadingStatus(NO_RESULT);
        }
      })
      .catch(() => {
        setStrategiesLoadingStatus(FAIL);
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userId]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    dispatch(getStrategiesStatistic())
      .then((res) => {
        setStatistic(res);
      })
      .catch(() => {});
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div className="lk-main-adviser">
      <div className="lk-main-adviser__block">
        <div className="lk-main-adviser__top">
          <h2 className="lk-main-adviser__top-title">Мои стратегии</h2>
        </div>

        {strategiesLoadingStatus === LOADING && (
          <div className="lk-main__loader">
            <CircleLoader />
          </div>
        )}

        {strategiesLoadingStatus === SUCCESS && (
          <div className="lk-main-adviser__list">
            {strategies.map((item) => (
              <LkStrategyItem data={item} key={item.name} />
            ))}
          </div>
        )}

        {strategiesLoadingStatus === NO_RESULT && (
          <FailMessage
            text="У вас не создано ни одной стратегии"
            className="lk-main-adviser__fail-message"
          />
        )}

        {strategiesLoadingStatus === FAIL && (
          <FailMessage
            text="К сожалению, нам не удалось загрузить ваши счета. Попробуйте повторить запрос позже"
            className="lk-main-adviser__fail-message"
          />
        )}
      </div>

      {statistic && statistic.length ? (
        <div className="lk-main-adviser__block">
          <div className="lk-main-adviser__top">
            <h2 className="lk-main-adviser__top-title">Статистика</h2>
          </div>

          <StatisticTable statistic={statistic} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

LkMainAdviser.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default connect()(LkMainAdviser);
