export const REMOVE_REDIRECT = 'REMOVE_REDIRECT';
export const GET_STRATEGIES_LIST_SUCCESS = 'GET_STRATEGIES_LIST_SUCCESS';
export const GET_STRATEGIES_LIST_FAIL = 'GET_STRATEGIES_LIST_FAIL';
export const GET_STRATEGIES_BG_CHART_SUCCESS = 'GET_STRATEGIES_BG_CHART_SUCCESS';
export const GET_STRATEGIES_BG_CHART_FAIL = 'GET_STRATEGIES_BG_CHART_FAIL';
export const GET_STRATEGIES_BG_CHART_987_SUCCESS = 'GET_STRATEGIES_BG_CHART_987_SUCCESS';
export const GET_STRATEGIES_BG_CHART_987_FAIL = 'GET_STRATEGIES_BG_CHART_987_FAIL';
export const GET_SINGLE_STRATEGY_SUCCESS = 'GET_SINGLE_STRATEGY_SUCCESS';
export const GET_SINGLE_STRATEGY_FAIL = 'GET_SINGLE_STRATEGY_FAIL';
export const GET_PORTFOLIO_COMPOSITION_SUCCESS = 'GET_PORTFOLIO_COMPOSITION_SUCCESS';
export const GET_PORTFOLIO_COMPOSITION_FAIL = 'GET_PORTFOLIO_COMPOSITION_FAIL';
export const ACTIVATE_STRATEGY_SUCCESS = 'ACTIVATE_STRATEGY_SUCCESS';
export const ACTIVATE_STRATEGY_FAIL = 'ACTIVATE_STRATEGY_FAIL';
export const GET_STRATEGIES_ACCOUNT_LIST_SUCCESS = 'GET_STRATEGIES_ACCOUNT_LIST_SUCCESS';
export const GET_STRATEGIES_ACCOUNT_LIST_FAIL = 'GET_STRATEGIES_ACCOUNT_LIST_FAIL';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const GET_API_KEY_FOR_USER_SUCCESS = 'GET_API_KEY_FOR_USER_SUCCESS';
export const GET_API_KEY_FOR_USER_FAIL = 'GET_API_KEY_FOR_USER_FAIL';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAIL = 'GET_TOKEN_FAIL';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';
export const FIND_REGISTERED_USER_SUCCESS = 'FIND_REGISTERED_USER_SUCCESS';
export const FIND_REGISTERED_USER_FAIL = 'FIND_REGISTERED_USER_FAIL';
export const CONSULT_FORM_SENDING_SUCCESS = 'CONSULT_FORM_SENDING_SUCCESS';
export const CONSULT_FORM_SENDING_FAIL = 'CONSULT_FORM_SENDING_FAIL';
export const SET_SMTH = 'SET_SMTH';
export const GET_STRATEGY_COMMENT_SUCCESS = 'GET_STRATEGY_COMMENT_SUCCESS';
export const GET_STRATEGY_COMMENT_FAIL = 'GET_STRATEGY_COMMENT_FAIL';
export const GET_STRATEGIES_STATISTIC_SUCCESS = 'GET_STRATEGIES_STATISTIC_SUCCESS';
export const GET_STRATEGIES_STATISTIC_FAIL = 'GET_STRATEGIES_STATISTIC_FAIL';
export const GET_SMTH_STRANGE = 'GET_SMTH_STRANGE';
export const SET_IS_MENU_OPEN = 'SET_IS_MENU_OPEN';
export const GET_CONNECTIONS_SUCCESS = 'GET_CONNECTIONS_SUCCESS';
export const GET_CONNECTIONS_FAIL = 'GET_CONNECTIONS_FAIL';
export const CREATE_NEW_CONNECTION_SUCCESS = 'CREATE_NEW_CONNECTION_SUCCESS';
export const CREATE_NEW_CONNECTION_FAIL = 'CREATE_NEW_CONNECTION_FAIL';
export const REFRESH_TOKEN_ON_SERVER_SUCCESS = 'REFRESH_TOKEN_ON_SERVER_SUCCESS';
export const REFRESH_TOKEN_ON_SERVER_FAIL = 'REFRESH_TOKEN_ON_SERVER_FAIL';
export const GET_LAST_DEALS_SUCCESS = 'GET_LAST_DEALS_SUCCESS';
export const GET_LAST_DEALS_FAIL = 'GET_LAST_DEALS_FAIL';
export const SEND_FORM_SUCCESS = 'SEND_FORM_SUCCESS';
export const SEND_FORM_FAIL = 'SEND_FORM_FAIL';
export const GET_STRATEGY_AUTHOR_INFO_SUCCESS = 'GET_STRATEGY_AUTHOR_INFO_SUCCESS';
export const GET_STRATEGY_AUTHOR_INFO_FAIL = 'GET_STRATEGY_AUTHOR_INFO_FAIL';
export const OPERATION_CREATE_SUCCESS = 'OPERATION_CREATE_SUCCESS';
export const OPERATION_CREATE_FAIL = 'OPERATION_CREATE_FAIL';
export const GET_OPERATION_CREATE_SMS_CODE_SUCCESS = 'GET_OPERATION_CREATE_SMS_CODE_SUCCESS';
export const GET_OPERATION_CREATE_SMS_CODE_FAIL = 'GET_OPERATION_CREATE_SMS_CODE_FAIL';
export const OPERATION_SIGN_SUCCESS = 'OPERATION_SIGN_SUCCESS';
export const OPERATION_SIGN_FAIL = 'OPERATION_SIGN_FAIL';
export const GET_STRATEGY_DATA_SUCCESS = 'GET_STRATEGY_DATA_SUCCESS';
export const GET_STRATEGY_DATA_FAIL = 'GET_STRATEGY_DATA_FAIL';
export const GET_OPERATION_CONTEXT_SUCCESS = 'GET_OPERATION_CONTEXT_SUCCESS';
export const GET_OPERATION_CONTEXT_FAIL = 'GET_OPERATION_CONTEXT_FAIL';
