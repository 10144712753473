import React from 'react';
import cx from 'classnames';

import './PartnerItem.scss';

const PartnerItem = ({ data, className }) => {
  return (
    <div
      className={cx('partner-item', {
        [className]: className,
      })}
    >
      {data.icon && <div className="partner-item__icon-wrap">{data.icon}</div>}

      <div className="partner-item__content">
        {data.title && <div className="partner-item__title">{data.title}</div>}
        {data.desc && <div className="partner-item__desc">{data.desc}</div>}

        {data.logo}

        {!data.info && data.linkText && data.link && (
          <div className="partner-item__link-wrap">
            <a
              href={data.link}
              target="_blank"
              rel="noopener noreferrer"
              className="partner-item__link"
            >
              {data.linkText}
            </a>
          </div>
        )}

        {data.info && (
          <div className="partner-item__info">
            <div className="partner-item__license">
              <a
                href={data.link}
                target="_blank"
                rel="noopener noreferrer"
                className="partner-item__license-link"
              >
                <span className="partner-item__license-wrap">
                  <span className="partner-item__license-img-wrap">
                    <img src={data.license} alt="" className="partner-item__license-img" />
                  </span>
                  <span className="partner-item__license-text-wrap">
                    <span className="partner-item__license-text">{data.info}</span>
                  </span>
                </span>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerItem;
