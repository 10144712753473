import * as types from 'modules/main/actions/action-types';

const initialState = {
  redirectTo: null,
  strategies: [],
  useApiKey: '',
  isMenuOpen: false,
};

const main = (state = initialState, action) => {
  switch (action.type) {
    case types.REMOVE_REDIRECT:
      return {
        ...state,
        redirectTo: null,
      };

    case types.GET_STRATEGIES_LIST_SUCCESS:
      return {
        ...state,
        strategies: action.payload.res,
      };

    case types.GET_API_KEY_FOR_USER_SUCCESS:
      return {
        ...state,
        useApiKey: action.payload.res.userid,
      };

    case types.SET_IS_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload.isMenuOpen,
      };

    default:
      return state;
  }
};

export default main;
