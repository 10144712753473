import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from 'modules/shared/components/Breadcrumbs/Breadcrumbs';
import UserForm from 'modules/main/components/UserForm/UserForm';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import WorkScheme from 'modules/main/components/WorkScheme/WorkScheme';

import { YELLOW } from 'constants/index';

import { ReactComponent as IconPoint } from 'assets/images/icon-point-white.svg';
import { ReactComponent as IconCardTransfer } from 'assets/images/icon-card-transfer.svg';
import { ReactComponent as IconsPlus } from 'assets/images/icon-circle-plus.svg';
import { ReactComponent as IconExplore } from 'assets/images/icon-explore-white.svg';

import './ConnectPage.scss';

const ConnectPage = () => {
  const workSchemeItems = [
    {
      id: 1,
      title: (
        <>
          Откройте счет АЛОР&nbsp;БРОКЕР{' '}
          <a href="https://www.alorbroker.ru/open" target="_blank" rel="noopener noreferrer">
            онлайн
          </a>
        </>
      ),
      icon: <IconPoint className="work-scheme-block__icon" />,
    },
    {
      id: 2,
      title: (
        <>
          Внесите денежные средства в&nbsp;
          <a href="https://lk.alor.ru/login" target="_blank" rel="noopener noreferrer">
            Кабинете клиента
          </a>{' '}
          или{' '}
          <a
            href="https://storage.alorbroker.ru/openinfo/%D0%A0%D0%B5%D0%BA%D0%B2%D0%B8%D0%B7%D0%B8%D1%82%D1%8B_2020.09.29.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            банковским переводом
          </a>
        </>
      ),
      icon: <IconCardTransfer className="work-scheme-block__icon" />,
    },
    {
      id: 3,
      title: (
        <>
          Подключите понравившуюся <Link to="/strategies">стратегию</Link>
        </>
      ),
      icon: <IconsPlus className="work-scheme-block__icon" />,
    },
    {
      id: 4,
      title: (
        <>
          Отслеживайте результаты в&nbsp;<Link to="/lk">Кабинете FOLLOW&nbsp;ME</Link>
        </>
      ),
      icon: <IconExplore className="work-scheme-block__icon" />,
    },
  ];

  return (
    <main className="connect-page">
      <div className="connect-page__container container">
        <Breadcrumbs
          link={window.location.pathname}
          anchor={'Как подключиться'}
          className="connect-page__breadcrumbs"
        />
        <div className="connect-page__content">
          <h1 className="connect-page__title">Как подключиться</h1>

          <WorkScheme items={workSchemeItems} className="connect-page__work-scheme" />

          <div className="connect-page__btn-wrap">
            <StandardButton
              isLink
              href="/strategies"
              className="connect-page__btn"
              title="Посмотреть стратегии"
              bgType={YELLOW}
            />
          </div>
        </div>
      </div>
      <div className="connect-page__form-wrap">
        <UserForm
          className="connect-page__form"
          title="Получить консультацию"
          description="Оставьте контактную информацию, наши специалисты свяжутся с Вами в ближайшее время"
        />
      </div>
    </main>
  );
};

export default ConnectPage;
