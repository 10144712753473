import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import ym from 'react-yandex-metrika';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { sendUserDataForConsultation } from 'modules/main/actions';

import { ReactComponent as IconCheckbox } from 'assets/images/icon-check.svg';
import { LOADING, FAIL, SUCCESS, YELLOW } from 'constants/index';

import './UserForm.scss';

const UserForm = ({ title, description, className, isPartnersForm }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [formSendingStatus, setFormSendingStatus] = useState(null);

  const sendForm = () => {
    setFormSendingStatus(LOADING);

    dispatch(sendUserDataForConsultation(name, phone, isChecked))
      .then(() => {
        setFormSendingStatus(SUCCESS);
        setName('');
        setPhone('');
        setTimeout(() => {
          setFormSendingStatus(null);
        }, 10000);
      })
      .catch(() => {
        setFormSendingStatus(FAIL);
        setTimeout(() => {
          setFormSendingStatus(null);
        }, 10000);
      });
  };

  return (
    <div
      className={cx('user-form', {
        [className]: className,
        'user-form_partners': isPartnersForm,
      })}
    >
      <div className="user-form__content">
        <h2 className="user-form__title">{title}</h2>
        <p className="user-form__text">{description}</p>
      </div>

      <div className="user-form__form">
        <div className="user-form__form-line-list">
          <div className="user-form__form-line">
            <div className="user-form__input-holder">
              <input
                className="user-form__input"
                type="text"
                placeholder="Имя"
                name="name"
                value={name}
                required="required"
                onChange={(event) => setName(event.target.value)}
              />
            </div>

            <div className="user-form__input-holder">
              <InputMask
                className="user-form__input"
                type="tel"
                placeholder="Телефон"
                name="phone"
                maskChar={null}
                required="required"
                mask="+7\ (999) 999-99-99"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>

            <StandardButton
              title="Отправить"
              isLink={false}
              onClick={() => {
                ym('reachGoal', 'formclient');
                sendForm();
              }}
              disabled={!name || !phone || !isChecked || (phone && phone.length < 18)}
              className="user-form__button"
              isLoading={formSendingStatus === LOADING}
              bgType={YELLOW}
            />
          </div>

          <div className="user-form__form-line">
            <label className="user-form__privacy">
              <span className="user-form__privacy-checkbox-wrap">
                <button
                  className="user-form__privacy-checkbox"
                  role="checkbox"
                  type="button"
                  aria-label="Согласен на обработку персональных данных"
                  aria-checked={isChecked}
                  onClick={() => setIsChecked((prevState) => !prevState)}
                />

                {isChecked && <IconCheckbox className="user-form__privacy-checkbox-icon" />}
              </span>

              <a
                href="https://www.alorbroker.ru/assent"
                target="_blank"
                rel="noopener noreferrer"
                className="user-form__privacy-text"
              >
                Согласен на обработку персональных данных
              </a>
            </label>
          </div>
        </div>

        {formSendingStatus === SUCCESS && (
          <div className="user-form__message user-form__message_success">
            Ваше запрос на консультацию отправлен! С Вами свяжется наш менеджер
          </div>
        )}

        {formSendingStatus === FAIL && (
          <div className="user-form__message user-form__message_fail">
            К сожалению, у нас не получилось отправить запрос. Попробуйте повторить позже
          </div>
        )}
      </div>
    </div>
  );
};

UserForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isPartnersForm: PropTypes.bool,
};

UserForm.defaulProps = {
  className: null,
  title: 'Необходима консультация?',
  description: 'Вы можете выбрать готовый инвестиционный продукт, соответствующий вашим целям',
  isPartnersForm: false,
};

export default UserForm;
