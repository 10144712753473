import { fetchWrapper, getCookie } from 'helpers';
import * as types from './action-types';
import { AUTO_FOLLOW_ON } from 'constants/index';

export const removeRedirect = () => ({
  type: types.REMOVE_REDIRECT,
});

export const getStrategies = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}autofollow/strategies`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_STRATEGIES_LIST_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_STRATEGIES_LIST_FAIL,
        });
        reject(err);
      });
  });
};

export const getBgChartForStrategy = (id, count, from, to) => (dispatch) => {
  let url = `${process.env.REACT_APP_API_URL}taskmanager/tasks/${id}/equitypct?count=${
    count || 100
  }`;

  if (from) {
    url += `&&from=${from}`;
  }

  if (to) {
    url += `&&to=${to}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_STRATEGIES_BG_CHART_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_STRATEGIES_BG_CHART_FAIL,
        });
        reject(err);
      });
  });
};

export const getBgChartForStrategy987 = (startDate, endDate) => (dispatch) => {
  let url = `https://www.alorbroker.ru/cmsapi/v1/site/strategiesCharts/5897?`;

  if (startDate) {
    url += `&&startDate=${startDate}`;
  }

  if (endDate) {
    url += `&&endDate=${endDate}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: types.GET_STRATEGIES_BG_CHART_987_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_STRATEGIES_BG_CHART_987_FAIL,
        });
        reject(err);
      });
  });
};

export const getSingleStrategies = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}autofollow/strategies/${id}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_SINGLE_STRATEGY_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_SINGLE_STRATEGY_FAIL,
        });
        reject(err);
      });
  });
};

export const getPortfolioComposition = (id, isWithUserApiKey) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}autofollow/strategies/${id}/positions`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: isWithUserApiKey
        ? getCookie(getCookie('alor_user_name'))
        : process.env.REACT_APP_ROOT_KEY,
      // key: isWithUserApiKey ? '838C2CA004AA4CD9AEFF22A535E664E1' : process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_PORTFOLIO_COMPOSITION_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_PORTFOLIO_COMPOSITION_FAIL,
        });
        reject(err);
      });
  });
};

export const getStrategiesAccountList = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/accounts`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GET_STRATEGIES_ACCOUNT_LIST_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_STRATEGIES_ACCOUNT_LIST_FAIL,
        });
        reject(err);
      });
  });
};

export const activateStrategy = (strategyid, accountid) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/strategies/start`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          strategyid,
          accountid,
        },
      }),
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.ACTIVATE_STRATEGY_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.ACTIVATE_STRATEGY_FAIL,
        });
        reject(err);
      });
  });
};

export const getComment = (strategyid) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}autofollow/comments?strategyid=${strategyid}&count=1`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_STRATEGY_COMMENT_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_STRATEGY_COMMENT_FAIL,
        });
        reject(err);
      });
  });
};

export const registerUser = (username, password, name) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}client/users/register`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      key: process.env.REACT_APP_ROOT_KEY,
      body: JSON.stringify({
        user: {
          username,
          password,
          name,
        },
      }),
    })
      .then((res) => {
        dispatch({
          type: types.REGISTER_USER_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.REGISTER_USER_FAIL,
        });
        reject(err);
      });
  });
};

export const getApiKeyForUser = (userid) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}client/users/${userid}/apikeys`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      key: process.env.REACT_APP_ROOT_KEY,
      body: JSON.stringify({
        token: 'da2ae28cd640450988fd6530322c9620',
      }),
    })
      .then((res) => {
        dispatch({
          type: types.GET_API_KEY_FOR_USER_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_API_KEY_FOR_USER_FAIL,
        });
        reject(err);
      });
  });
};

export const getToken = (code) => (dispatch) => {
  const url = `${process.env.REACT_APP_TOKEN_HOST}${process.env.REACT_APP_TOKEN_PATH}`;
  let formData = new FormData();
  formData.append('code', code);
  formData.append('client_id', process.env.REACT_APP_CLIENT_ID);
  formData.append('scope', process.env.REACT_APP_SCOPE);
  formData.append('redirect_uri', process.env.REACT_APP_REDIRECT_URI);
  formData.append('grant_type', 'authorization_code');

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      formData: formData,
      mode: 'no-cors',
    })
      .then((res) => {
        dispatch({
          type: types.GET_TOKEN_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_TOKEN_FAIL,
        });
        reject(err);
      });
  });
};

export const refreshToken = (token, refreshToken) => (dispatch) => {
  const url = 'https://lk-api.alor.ru/auth/actions/refresh';

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        oldJwt: token,
        refreshToken,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.REFRESH_TOKEN_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.REFRESH_TOKEN_FAIL,
        });
        reject(err);
      });
  });
};

export const findRegisteredUser = (login) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}client/users?username=${login}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.FIND_REGISTERED_USER_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.FIND_REGISTERED_USER_FAIL,
        });
        reject(err);
      });
  });
};

export const getStrategiesStatistic = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/strategies/stats`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_STRATEGIES_STATISTIC_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_STRATEGIES_STATISTIC_FAIL,
        });
        reject(err);
      });
  });
};

export const getSmthStrange = (agreementNumber) => (dispatch) => {
  const url = `https://lk-api-dev.alorbroker.ru/client/v2.0/agreements/${agreementNumber}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      alorAuth: `Bearer ${getCookie('alor_token')}`,
    })
      .then((res) => {
        dispatch({
          type: types.GET_SMTH_STRANGE,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_SMTH_STRANGE,
        });
        reject(err);
      });
  });
};

export const operationCreate = (type, agreementNumber, accessToken, strategyId, accountId) => (
  dispatch,
) => {
  const url = `https://lk-api.alor.ru/client/v2.0/operations/create`;
  const formData = new FormData();
  formData.append('agreementNumber', agreementNumber);
  formData.append(
    'data',
    JSON.stringify(
      type === AUTO_FOLLOW_ON
        ? {
            strategy: strategyId,
            account: accountId,
          }
        : {
            strategy: strategyId,
            account: accountId,
            action: 'disable',
          },
    ),
  );
  formData.append('operationType', 'service_auto_follow_change');

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      multipart: true,
      formData,
      alorAuth: `Bearer ${accessToken}`,
    })
      .then((res) => {
        dispatch({
          type: types.OPERATION_CREATE_SUCCESS,
          payload: {
            res,
          },
        });
        resolve({
          createResult: res,
          accessToken,
          agreementNumber,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.OPERATION_CREATE_FAIL,
        });
        reject(err);
      });
  });
};

export const getOperationSmsCode = (agreementNumber, operationId, accessToken) => (dispatch) => {
  const url = `https://lk-api.alor.ru/client/v2.0/operations/code`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        agreementNumber,
        operationId,
      }),
      alorAuth: `Bearer ${accessToken}`,
    })
      .then((res) => {
        dispatch({
          type: types.GET_OPERATION_CREATE_SMS_CODE_SUCCESS,
          payload: {
            res,
          },
        });
        resolve({
          smsResult: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_OPERATION_CREATE_SMS_CODE_FAIL,
        });
        reject(err);
      });
  });
};

export const operationSign = (agreementNumber, operationId, confirmationCode, accessToken) => (
  dispatch,
) => {
  const url = `https://lk-api.alor.ru/client/v2.0/operations/sign`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        agreementNumber,
        operationId,
        confirmationCode,
      }),
      alorAuth: `Bearer ${accessToken}`,
    })
      .then((res) => {
        dispatch({
          type: types.OPERATION_SIGN_SUCCESS,
          payload: {
            res,
          },
        });
        resolve({ smsResult: res });
      })
      .catch((err) => {
        dispatch({
          type: types.OPERATION_SIGN_FAIL,
        });
        reject(err);
      });
  });
};

export const getOperationContext = (operationId, accessToken) => (dispatch) => {
  const url = `https://lk-api.alor.ru/client/v2.0/operations/${operationId}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      alorAuth: `Bearer ${accessToken}`,
    })
      .then((res) => {
        dispatch({
          type: types.GET_OPERATION_CONTEXT_SUCCESS,
          payload: {
            res,
          },
        });
        resolve({
          operationContext: res,
          accessToken,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_OPERATION_CONTEXT_FAIL,
        });
        reject(err);
      });
  });
};

export const getStrategyContext = (cloudstrategyid, accessToken, agreementNumber) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/strategies/${cloudstrategyid}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_STRATEGY_DATA_SUCCESS,
          payload: {
            res,
            accessToken,
            agreementNumber,
          },
        });
        resolve({ strategyContext: res });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_STRATEGY_DATA_FAIL,
        });
        reject(err);
      });
  });
};

export const refreshTokenOnServer = (token, connectionId) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/connections/${connectionId}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
      body: JSON.stringify({
        connection: {
          connectorid: 2,
          active: 1,
          login: token,
        },
      }),
    })
      .then((res) => {
        dispatch({
          type: types.REFRESH_TOKEN_ON_SERVER_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.REFRESH_TOKEN_ON_SERVER_FAIL,
        });
        reject(err);
      });
  });
};

export const createNewConnectionOnServer = (token) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/connections`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
      body: JSON.stringify({
        connection: {
          connectorid: 2,
          active: 1,
          login: token,
        },
      }),
    })
      .then((res) => {
        dispatch({
          type: types.CREATE_NEW_CONNECTION_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.CREATE_NEW_CONNECTION_FAIL,
        });
        reject(err);
      });
  });
};

export const getConnections = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/connections`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GET_CONNECTIONS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_CONNECTIONS_FAIL,
        });
        reject(err);
      });
  });
};

export const getLastDeals = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}taskmanager/tasks/${id}/trades?reverse=1&count=5&hideactive=1`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_LAST_DEALS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_LAST_DEALS_FAIL,
        });
        reject(err);
      });
  });
};

export const setIsMenuOpen = (isMenuOpen) => ({
  type: types.SET_IS_MENU_OPEN,
  payload: {
    isMenuOpen,
  },
});

export const sendUserDataForConsultation = (name, phone, personalDataAccess) => (dispatch) => {
  const url = `https://www.alorbroker.ru/cmsapi/v1/site/form/crm-form`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        phone,
        personalDataAccess,
        source: 'followme',
        ResponseUrl: 'https://followme.alor.ru/',
        Site: 'https://followme.alor.ru/',
        city: 'moscow',
      }),
    })
      .then((res) => {
        dispatch({
          type: types.SEND_FORM_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.SEND_FORM_FAIL,
        });
        reject(err);
      });
  });
};

export const getStrategyAuthorInfo = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}client/users/${id}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_STRATEGY_AUTHOR_INFO_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_STRATEGY_AUTHOR_INFO_FAIL,
        });
        reject(err);
      });
  });
};
