import React from 'react';
import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import StandardSelect from 'modules/shared/components/StandardSelect/StandardSelect';
import { FAIL, SUCCESS, YELLOW, YELLOW_BORDER } from 'constants/index';

import './SelectAccountForm.scss';

const SelectAccountForm = ({
  closePopup,
  accountList,
  setSelectedAccount,
  selectedAccount,
  status,
  activateSmsContent,
}) => {
  return (
    <div className="select-account-form">
      {status === SUCCESS && (
        <>
          {accountList.length > 0 && (
            <>
              <StandardSelect
                options={accountList}
                // defaultValue={accountList[0]}
                onChange={setSelectedAccount}
                placeholder="Выберите счет"
              />

              <div className="select-account-form__btn-wrap">
                <StandardButton
                  className="select-account-form__btn"
                  isLink={false}
                  onClick={closePopup}
                  title="Отмена"
                  bgType={YELLOW_BORDER}
                />
                <StandardButton
                  className="select-account-form__btn"
                  isLink={false}
                  onClick={activateSmsContent}
                  title="Подключить"
                  disabled={!selectedAccount}
                  bgType={YELLOW}
                />
              </div>
            </>
          )}

          {accountList.length === 0 && (
            <>
              <div className="select-account-form__msg">
                <p className="select-account-form__msg-item">
                  У Вас нет счета, доступного для подключения стратегии.
                </p>
                <p className="select-account-form__msg-item">
                  Для подключения новой стратегии отключите уже подключенную стратегию.
                </p>
              </div>
              <div className="select-account-form__btn-wrap">
                <StandardButton
                  className="select-account-form__btn"
                  isLink={false}
                  onClick={closePopup}
                  title="Закрыть"
                  bgType={YELLOW}
                />
              </div>
            </>
          )}
        </>
      )}

      {status === FAIL && (
        <div className="select-account-form__msg">
          К сожалению, что-то пошло не так и мы не смогли загрузить список доступных счетов.
          Попробуйте повторить операцию позже.
        </div>
      )}
    </div>
  );
};

const selectedAccountPropTypes = PropTypes.shape({
  value: PropTypes.string,
  accountid: PropTypes.string,
  label: PropTypes.string,
});

SelectAccountForm.propTypes = {
  closePopup: PropTypes.func.isRequired,
  accountList: PropTypes.arrayOf(selectedAccountPropTypes).isRequired,
  setSelectedAccount: PropTypes.func.isRequired,
  selectedAccount: selectedAccountPropTypes,
  status: PropTypes.string.isRequired,
  activateSmsContent: PropTypes.func.isRequired,
};

SelectAccountForm.defaultProps = {
  selectedAccount: null,
};

export default SelectAccountForm;
