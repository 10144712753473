import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LkTop from 'modules/lk/components/LkTop/LkTop';
import LkMain from 'modules/lk/components/LkMain/LkMain';
import LkMainAdviser from 'modules/lk/components/LkMainAdviser/LkMainAdviser';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { LOADING, FAIL } from 'constants/index';
import { getClientInfo, getClientStrategies, getClientDetailsByUserName } from 'modules/lk/actions';
import { getStrategiesAccountList } from 'modules/main/actions';

import { getCookie, riskDefiner } from 'helpers';

import './Lk.scss';

const Lk = ({ dispatch }) => {
  // const [balance, setBalance] = useState('');
  const [totalBalanceRub, setTotalBalanceRub] = useState(0);
  const [totalBalanceUsd, setTotalBalanceUsd] = useState(0);
  const [totalDifference, setTotalDifference] = useState('');
  const [strategies, setStrategies] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsLoadingStatus, setAccountsLoadingStatus] = useState(null);
  const [accountId, setAccountId] = useState('');
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [isAdviser, setIsAdviser] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [userRisk, setUserRisk] = useState('');

  /** Берем имя юзера */
  useEffect(() => {
    setIsLoadingState(true);
    dispatch(
      getClientDetailsByUserName(
        getCookie('alor_user_name') && getCookie('alor_user_name').toUpperCase(),
      ),
    )
      // dispatch(getClientDetailsByUserName('p066723'.toUpperCase()))
      .then((res) => {
        if (res) {
          setName(res.name);

          if (res.isadviser === '1') {
            setIsAdviser(true);
            setUserId(res.userid);
          }
          setIsLoadingState(false);

          if (res.isqualified === '1') {
            setUserRisk('Продвинутый');
          } else {
            setUserRisk(riskDefiner(Number(res.riskscore)));
          }
        }
      })
      .catch(() => {
        setName('юзер');
        setIsLoadingState(false);
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Находим accountId */
  useEffect(() => {
    setAccountsLoadingStatus(LOADING);
    dispatch(getStrategiesAccountList())
      .then((res) => {
        setAccounts(res.filter((item) => item.value !== '0.00'));
        setAccountsLoadingStatus(null);

        for (let i = 0, len = res.length; i < len; i++) {
          if (res[i].currencycode === 'RUB') {
            setTotalBalanceRub((prevState) => prevState + Number(res[i].value));
          }

          if (res[i].currencycode === 'USD') {
            setTotalBalanceUsd((prevState) => prevState + Number(res[i].value));
          }

          for (let j = 0, len = strategies.length; j < len; j++) {
            if (res[i].accountid === strategies[j].accountid) {
              setAccountId(res[i].accountid);
            }
          }
        }
      })
      .catch(() => {
        setAccountsLoadingStatus(FAIL);
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Загружаем инфу по юзеру */
  useEffect(() => {
    if (accountId) {
      dispatch(getClientInfo(accountId))
        .then((res) => {
          if (res) {
            // setBalance(res.value);
            setTotalDifference(res.changepercent);
          }
        })
        .catch(() => {});
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [accountId]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const getClientStrategiesFunction = () => {
    dispatch(getClientStrategies())
      .then((res) => {
        if (res) {
          setStrategies(res.filter((item) => item.status !== '5'));
        }
      })
      .catch(() => {});
  };

  /** Загружаем стратегии */
  useEffect(() => {
    if (!isAdviser) {
      getClientStrategiesFunction();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isAdviser]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div className="lk">
      <LkTop
        className="lk__top"
        name={name}
        totalBalanceRub={totalBalanceRub.toFixed(2)}
        totalBalanceUsd={totalBalanceUsd.toFixed(2)}
        totalDifference={totalDifference}
        userRisk={isAdviser ? null : userRisk}
      />

      {isLoadingState ? (
        <div className="lk__loader">
          <CircleLoader />
        </div>
      ) : (
        <>
          {isAdviser && <LkMainAdviser userId={userId} />}

          {!isAdviser && (
            <LkMain
              accounts={accounts}
              strategies={strategies}
              accountsLoadingStatus={accountsLoadingStatus}
              getClientStrategiesFunction={getClientStrategiesFunction}
            />
          )}
        </>
      )}

      <div className="lk__footer">
        <div className="lk__container container">
          <p className="lk__footer-text">
            Заходить на портал followme.alor.ru клиенту необходимо не реже одного раза в год.
          </p>
        </div>
      </div>
    </div>
  );
};

Lk.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isSimpleUser: PropTypes.bool,
};

Lk.defaultProps = {
  isSimpleUser: true,
};

const mapStateToProps = (state) => ({
  userId: state.lk.userId,
});

export default connect(mapStateToProps)(Lk);
