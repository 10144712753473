import React, { useEffect, useState } from 'react';
import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import startOfMonth from 'date-fns/startOfMonth';
import useInterval from 'react-useinterval';
import ym from 'react-yandex-metrika';

import {
  getSingleStrategies,
  getBgChartForStrategy,
  getBgChartForStrategy987,
  getPortfolioComposition,
  getStrategiesAccountList,
  activateStrategy,
  getComment,
  getStrategyAuthorInfo,
  refreshToken,
  getOperationSmsCode,
  operationCreate,
  operationSign,
  getStrategyContext,
  getOperationContext,
} from 'modules/main/actions';

// import {
//   getOperationSmsCodeFake,
//   operationCreateFake,
//   operationSignFake,
//   getOperationContextFake,
//   getStrategyContext,
// } from 'modules/main/actions/fake';

import { getClientStrategies, turnOffStrategy, syncStrategy } from 'modules/lk/actions';

import Breadcrumbs from 'modules/shared/components/Breadcrumbs/Breadcrumbs';
// import PortfolioContent from 'modules/main/components/PortfolioContent/PortfolioContent';
import PopupWrap from 'modules/shared/components/PopupWrap/PopupWrap';
import SmsContent from 'modules/main/components/SmsContent/SmsContent';
import ActivateStrategyPopup from 'modules/main/components/ActivateStrategyPopup/ActivateStrategyPopup';
import DeactivateStrategyWarning from 'modules/main/components/DeactivateStrategyWarning/DeactivateStrategyWarning';
import DeactivateStrategyPopup from 'modules/main/components/DeactivateStrategyPopup/DeactivateStrategyPopup';
import SyncStrategyForm from 'modules/main/components/SyncStrategyForm/SyncStrategyForm';
import ChartNav from 'modules/main/components/ChartNav/ChartNav';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import Chart from 'modules/shared/components/Chart/Chart';
import StrategyCharacteristics from 'modules/main/components/StrategyCharacteristics/StrategyCharacteristics';
import AuthorInfo from 'modules/main/components/AuthorInfo/AuthorInfo';
import Profit from 'modules/shared/components/Profit/Profit';
import StrategyDesc from 'modules/main/components/StrategyDesc/StrategyDesc';
import Trades from 'modules/main/components/Trades/Trades';
import ActivateStrategyAuthPopup from 'modules/main/components/ActivateStrategyAuthPopup/ActivateStrategyAuthPopup';
import ActivateStrategyResult from 'modules/main/components/ActivateStrategyResult/ActivateStrategyResult';
import UserForm from 'modules/main/components/UserForm/UserForm';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import SelectAccountForm from 'modules/main/components/SelectAccountForm/SelectAccountForm';

import {
  YELLOW,
  LOADING,
  FAIL,
  SUCCESS,
  MONTH,
  THREE_MONTHS,
  YEAR,
  ALL,
  AUTO_FOLLOW_ON,
  AUTO_FOLLOW_OFF,
  SELECT_ACCOUNT,
  SMS_FORM,
  OPERATION_RESULT,
  DEACTIVATE_WARNING,
} from 'constants/index';
import {
  formatDateMMDDYYYY,
  formatDateYYYYDDMM,
  getCookie,
  parseJwt,
  arrayFilterByCount,
} from 'helpers/index';
import fakePhoto from 'assets/images/fm-avatar.svg';

import 'assets/style/charts-styles.scss';
import './StrategiesSingle.scss';

const StrategiesSingle = ({ match: { params = {} }, dispatch, isLoggedIn }) => {
  const windowSize = useWindowSize();
  const [currentNavItem, setCurrentNavItem] = useState(3);
  const [data, setData] = useState();
  // const [portfolioList, setPortfolioList] = useState([]);

  const [isPopupActiveStrategyOpen, setIsPopupActiveStrategyOpen] = useState(false);
  const [isPopupDeactivateStrategyOpen, setIsPopupDeactivateStrategyOpen] = useState(false);
  const [isPopupSyncStrategyOpen, setIsPopupSyncStrategyOpen] = useState(false);
  const [isPopupLogInOrOpenAccount, setIsPopupLogInOrOpenAccount] = useState(false);

  const [operationProcess, setOperationProcess] = useState('');

  const [code, setCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [operationID, setOperationID] = useState(null);
  // const [activatedStrategyId, setActivatedStrategyId] = useState(null);

  const [seconds, setSeconds] = useState(0);
  const [isTimeEnded, setIsTimeEnded] = useState(false);
  const [interval, setInterval] = useState(1000);

  const [validations, setValidations] = useState([]);
  const [operationDocuments, setOperationDocuments] = useState([]);

  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [activateStrategyStatus, setActivateStrategyStatus] = useState('');
  const [syncStrategyStatus, setSyncStrategyStatus] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [isChartDataReady, setIsChartDataReady] = useState(false);
  const [isTurnOnStrategyButtonActive, setIsTurnOnStrategyButtonActive] = useState(false);
  const [isTurnOffStrategyButtonActive, setIsTurnOffStrategyButtonActive] = useState(false);
  const [accountIdOfActiveStrategy, setAccountIdOfActiveStrategy] = useState('');
  const [strategyIdToTurnOffStrategy, setStrategyIdToTurnOffStrategy] = useState('');
  const [clientStrategies, setClientStrategies] = useState([]);
  const [comment, setComment] = useState([]);
  const [chartDataError, setChartDataError] = useState('');
  const [authorInfo, setAuthorInfo] = useState(null);
  const [isAuthorInfoLoading, setIsAuthorInfoLoading] = useState(true);
  const [textForProfit, setTextForProfit] = useState('');
  const [profitPercent, setProfitPercent] = useState(0);
  // const [isStrategiesListUpdating, setIsStrategiesListUpdating] = useState(false);

  /** Берем с сервера данные по стратегии */
  useEffect(() => {
    if (!params.uniqueId) {
      return;
    }

    dispatch(getSingleStrategies(params.uniqueId))
      .then((res) => {
        if (res.strategyid === '997') {
          setData({
            ...res,
            userid: '12815',
          });
        } else if (res.strategyid === '991') {
          setData({
            ...res,
            userid: '12815',
          });
        } else {
          setData(res);
        }

        return dispatch(getPortfolioComposition(params.uniqueId));
      })
      .then(() => {
        // setPortfolioList(portfRes);

        return dispatch(getComment(params.uniqueId));
      })
      .then((res) => {
        setComment(res);
      })
      .catch((err) => {
        console.log(err);
      });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [params.uniqueId]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Берем с сервера данные по счетам юзера
   * после открытия попап - Подключить стратегию
   * */
  const openActivateStrategyPopup = () => {
    /** Открываем модалку */
    setIsPopupActiveStrategyOpen(true);

    /** Показываем контент выбора счета */
    setOperationProcess(SELECT_ACCOUNT);

    setActivateStrategyStatus(LOADING);

    dispatch(getStrategiesAccountList())
      .then((res) => {
        const accounts = res
          .filter((item) => {
            /** Чтобы увидеть какие счета доступны для выбора, мы сначала
             * смотрим, чтобы их value было больше data.minimuminvestment, затем
             * берем стратегии клиента и проверяем чтобы account
             * стратегии совпадал с account счета, но чтобы статус стратегии был не 5
             * */
            return (
              Number(item.value) >= Number(data.minimuminvestment) &&
              // Number(item.value) >= 100 &&
              ((data.marketname === 'ММВБ' && item.typeid === '1' && item.currencyid === '1') ||
                (data.marketname === 'SPBEX' && item.typeid === '1' && item.currencyid === '2') ||
                (data.marketname === 'FORTS' && item.typeid === '2' && item.currencyid === '1')) &&
              !clientStrategies.find(
                (clientStatItem) =>
                  clientStatItem.account === item.account && clientStatItem.status !== '5',
              )
            );
          })
          .map(({ account, accountid }) => {
            return {
              value: account,
              label: account,
              accountid,
            };
          });

        setAccountList(accounts);

        setActivateStrategyStatus(SUCCESS);
      })
      .catch(() => {
        setActivateStrategyStatus(FAIL);
      });
  };

  /** Функция для запроса данных по графику */
  const getNeededDataForChart = (count, from, to) => {
    setIsChartDataReady(false);

    if (data.strategyid === '987') {
      dispatch(getBgChartForStrategy987(formatDateYYYYDDMM(from), formatDateYYYYDDMM(to)))
        .then((result) => {
          if (result && result.length > 0) {
            const chartPoints = arrayFilterByCount(result, count);

            setChartData(
              chartPoints.map((item, i) => {
                if (i === chartPoints.length - 1) {
                  setProfitPercent(chartPoints[i].value);
                }
                return {
                  x: new Date(item.date),
                  y: item.value,
                };
              }),
            );
            setChartDataError('');
          } else {
            setChartDataError('Недостаточно данных для вывода графика');
          }

          setIsChartDataReady(true);
        })
        .catch(() => {
          setIsChartDataReady(true);
          setChartDataError('Недостаточно данных для вывода графика');
        });
    } else {
      dispatch(
        getBgChartForStrategy(data.taskid, count, formatDateYYYYDDMM(from), formatDateYYYYDDMM(to)),
      )
        .then((innerRes) => {
          if (innerRes && innerRes.length > 0) {
            setChartData(
              innerRes.map((item, i) => {
                if (i === innerRes.length - 1) {
                  setProfitPercent(innerRes[i].equity);
                }

                return {
                  x: formatDateMMDDYYYY(item.timestamp),
                  y: Number(item.equity),
                };
              }),
            );

            setChartDataError('');
          } else {
            setChartDataError('Недостаточно данных для вывода графика');
          }

          setIsChartDataReady(true);
        })
        .catch(() => {
          setIsChartDataReady(true);
          setChartDataError('Недостаточно данных для вывода графика');
        });
    }
  };

  /**
   * Устанавливаем данные для графика
   * в зависимости от выбранного периода
   * */
  useEffect(() => {
    if (data && data.taskid) {
      if (currentNavItem === 0) {
        getNeededDataForChart(10, addDays(new Date(), -30), new Date());
        setTextForProfit('доходность за месяц в рублях');
      }

      if (currentNavItem === 1) {
        getNeededDataForChart(100, addMonths(startOfMonth(new Date()), -3), new Date());
        setTextForProfit('доходность за 3 месяца в рублях');
      }

      if (currentNavItem === 2) {
        // addMonths(startOfMonth(new Date()), -12)
        getNeededDataForChart(100, startOfMonth(new Date(2020, 3, 1)), new Date());
        setTextForProfit('доходность за год в рублях');
      }

      if (currentNavItem === 3) {
        // addMonths(startOfMonth(new Date()), -12)
        getNeededDataForChart(100, startOfMonth(new Date(2020, 3, 1)));
        setTextForProfit('доходность за весь период в рублях');
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentNavItem, data]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Функция для взятия стратегий клиента */
  const getAndUpdateStrategies = () => {
    dispatch(getClientStrategies())
      .then((res) => {
        if (res && res.length > 0) {
          dispatch(getStrategiesAccountList())
            .then((accounts) => {
              const localAccounts = accounts.filter((item) => item.value !== '0.00');
              // const sameStrategy = res.find(
              //   (item) => item.strategy === data.name && item.status !== '5',
              // );
              // const freeAccount = !localAccounts.some((item) =>
              //   res.find((resItem) => resItem.account === item.account && resItem.status !== '3'),
              // );
              const freeAccount = localAccounts.filter(
                (el) => !res.find((i) => i.account === el.account && i.status === '3'),
              );

              if (freeAccount.length > 0) {
                setIsTurnOnStrategyButtonActive(true);
              } else {
                setIsTurnOnStrategyButtonActive(false);
                // if (sameStrategy) {
                //   setIsTurnOnStrategyButtonActive(false);
                // }
                // if (!sameStrategy) {
                //   setIsTurnOnStrategyButtonActive(true);
                // }
              }
            })
            .catch(() => {});

          setIsTurnOffStrategyButtonActive(
            res.find((item) => item.strategy === data.name && item.status !== '5'),
          );
          res.forEach((item) => {
            if (item.strategy === data.name && item.status !== '5') {
              setAccountIdOfActiveStrategy(item.accountid);
              setStrategyIdToTurnOffStrategy(item.strategyid);
            }
          });
        } else {
          setIsTurnOnStrategyButtonActive(true);
        }
        // if ((res && !res.length) || !res) {
        //   setIsTurnOnStrategyButtonActive(true);
        // }
        setClientStrategies(res);
      })
      .catch(() => {});
  };

  /**
   * Проверяем подключена ли эта стратегия у
   * юзера, если он авторизован
   * */
  useEffect(() => {
    if (data) {
      getAndUpdateStrategies();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Берем данные по автору стратегии
   * */
  useEffect(() => {
    if (data && data.userid) {
      dispatch(getStrategyAuthorInfo(data.userid))
        .then((res) => {
          setAuthorInfo(res);
          setIsAuthorInfoLoading(false);
        })
        .catch(() => {
          setIsAuthorInfoLoading(false);
        });
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Массив для кнопок, переключающих период отображения графика */
  const navList = [
    {
      id: MONTH,
      title: 'Месяц',
      onClick: () => setCurrentNavItem(0),
    },
    {
      id: THREE_MONTHS,
      title: '3 Месяца',
      onClick: () => setCurrentNavItem(1),
    },
    {
      id: YEAR,
      title: 'Год',
      onClick: () => setCurrentNavItem(2),
    },
    {
      id: ALL,
      title: 'Весь период',
      onClick: () => setCurrentNavItem(3),
    },
  ];

  /** Клик на кнопку "Подключить" после выбора счета в попапе */
  const activateStrategyHandler = () => {
    setCode('');

    if (isTimeEnded) {
      setActivateStrategyStatus(LOADING);

      /** Рефрешим токен, забираем AccessToken */
      dispatch(refreshToken(getCookie('alor_token'), getCookie('alor_refresh_token')))
        .then(({ jwt }) => {
          if (jwt) {
            /** Создаем операци, параметры:
             * type - AUTO_FOLLOW_ON
             * agreementNumber - номер учётки без первых двух символов P0,
             * accessToken - токен,
             * strategyId - id стратегии из урла, например 986,
             * account - название счёта, например D66723.
             * */
            return dispatch(
              operationCreate(
                AUTO_FOLLOW_ON,
                parseJwt(jwt).sub.slice(2),
                jwt,
                params.uniqueId,
                selectedAccount.value.split(' ')[0],
              ),
            );
          }
        })
        .then(({ createResult, accessToken }) => {
          if (createResult && createResult.success && createResult.operationId) {
            /** Если создание операции успешно, запрашиваем контекст операции, параметры:
             * operationId - id стратегии, например 12722,
             * accessToken - токен, */
            setOperationID(createResult.operationId); // зачем это сохранять???
            return dispatch(getOperationContext(createResult.operationId, accessToken));
          } else {
            /** Если при создании операции есть причины по которым невозможна операция,
             * сохраняем список причин для вывода */
            setValidations(createResult.validations);
            setActivateStrategyStatus(FAIL);
          }
        })
        .then(({ operationContext, accessToken }) => {
          if (operationContext && operationContext.agreementNumber && operationContext.documents) {
            /** Если получаем контекст операции, сохраняем и показываем документ,
             * и запрашиваем смску, параметры:
             * agreementNumber - номер учётки без первых двух символов P0,
             * operationId - id стратегии, например 12722,
             * accessToken - токен, */
            setOperationDocuments(operationContext.documents);
            localStorage.setItem('operation-docs', JSON.stringify(operationContext.documents));
            return dispatch(
              getOperationSmsCode(
                operationContext.agreementNumber,
                operationContext.id,
                accessToken,
              ),
            );
          } else {
            setActivateStrategyStatus(FAIL);
          }
        })
        .then(({ smsResult }) => {
          if (smsResult && smsResult.success) {
            setInterval(1000);
            setIsTimeEnded(false);
            setSeconds(60);

            setActivateStrategyStatus(SUCCESS);
          } else {
            setActivateStrategyStatus(FAIL);
          }
        })
        .catch(() => {
          setActivateStrategyStatus(FAIL);
        });
    } else {
      setActivateStrategyStatus(SUCCESS);
    }
  };

  /** Клик на кнопку "Отключить" */
  const deactivateStrategyHandler = () => {
    let account;
    setCode('');

    if (isTimeEnded) {
      setActivateStrategyStatus(LOADING);

      dispatch(getStrategyContext(strategyIdToTurnOffStrategy))
        .then(({ strategyContext }) => {
          if (strategyContext && strategyContext.account) {
            account = strategyContext.account;

            return dispatch(refreshToken(getCookie('alor_token'), getCookie('alor_refresh_token')));
          }
        })
        .then(({ jwt }) => {
          if (jwt) {
            return dispatch(
              operationCreate(
                AUTO_FOLLOW_OFF,
                parseJwt(jwt).sub.slice(2),
                jwt,
                params.uniqueId,
                account.split(' ')[0],
              ),
            );
          }
        })
        .then(({ createResult, accessToken }) => {
          if (createResult && createResult.success && createResult.operationId) {
            /** Если создание операции успешно, запрашиваем контекст операции, параметры:
             * operationId - id стратегии, например 12722,
             * accessToken - токен, */
            setOperationID(createResult.operationId); // зачем это сохранять???
            return dispatch(getOperationContext(createResult.operationId, accessToken));
          } else {
            /** Если при создании операции есть причины по которым невозможна операция,
             * сохраняем список причин для вывода */
            setValidations(createResult.validations);
            setActivateStrategyStatus(FAIL);
          }
        })
        .then(({ operationContext, accessToken }) => {
          if (operationContext && operationContext.agreementNumber && operationContext.documents) {
            /** Если получаем контекст операции, сохраняем и показываем документ,
             * и запрашиваем смску, параметры:
             * agreementNumber - номер учётки без первых двух символов P0,
             * operationId - id стратегии, например 12722,
             * accessToken - токен, */
            setOperationDocuments(operationContext.documents);
            localStorage.setItem('operation-docs', JSON.stringify(operationContext.documents));
            return dispatch(
              getOperationSmsCode(
                operationContext.agreementNumber,
                operationContext.id,
                accessToken,
              ),
            );
          } else {
            setActivateStrategyStatus(FAIL);
          }
        })
        .then(({ smsResult }) => {
          if (smsResult && smsResult.success) {
            setInterval(1000);
            setIsTimeEnded(false);
            setSeconds(60);

            setActivateStrategyStatus(SUCCESS);
          } else {
            setActivateStrategyStatus(FAIL);
          }
        })
        .catch(() => {
          setActivateStrategyStatus(FAIL);
        });
    } else {
      setActivateStrategyStatus(SUCCESS);
    }
  };

  /** Проверка на длину смс кода */
  useEffect(() => {
    setIsCodeValid(code.length === 5);
  }, [code]);

  /** Таймер для повторного запроса смс */
  const startTimer = () => {
    if (seconds > 0) {
      setSeconds((prevState) => prevState - 1);
    } else {
      setIsTimeEnded(true);
      setInterval(null);
    }
  };

  useInterval(startTimer, interval);

  /** Закрыть модалку - Активировать стратегию */
  const popupActiveStrategyClose = () => {
    setIsPopupActiveStrategyOpen(false);
    setOperationProcess('');
    setAccountList([]);
    setSelectedAccount(null);
    setActivateStrategyStatus('');
    localStorage.removeItem('operation-docs');
    setCode('');
    setValidations([]);
  };

  /** Закрыть модалку - Отключить стратегию */
  const popupDeactivateStrategyClose = () => {
    setIsPopupDeactivateStrategyOpen(false);
    setActivateStrategyStatus('');
    setOperationProcess('');
    localStorage.removeItem('operation-docs');
    setCode('');
  };

  /** Верификация смс кода */
  const operationSignHandler = (type) => {
    setCode('');
    setActivateStrategyStatus(LOADING);

    dispatch(refreshToken(getCookie('alor_token'), getCookie('alor_refresh_token')))
      .then(({ jwt }) => {
        if (jwt) {
          return dispatch(operationSign(parseJwt(jwt).sub.slice(2), operationID, code, jwt));
        }
      })
      .then(({ smsResult }) => {
        if (smsResult && smsResult.success) {
          return type === AUTO_FOLLOW_ON
            ? dispatch(activateStrategy(params.uniqueId, selectedAccount.accountid))
            : dispatch(turnOffStrategy(strategyIdToTurnOffStrategy));
        } else {
          setActivateStrategyStatus(FAIL);
        }
      })
      .then(() => {
        setTimeout(() => {
          getAndUpdateStrategies();
          setInterval(1000);
          setIsTimeEnded(false);
          setSeconds(0);
          setOperationID(null);
          setActivateStrategyStatus(SUCCESS);
        }, 5000);
      })
      .catch(() => {
        setActivateStrategyStatus(FAIL);
      });
  };

  /** Клик на кнопку "Синхронизировать" */
  const syncStrategyHandler = () => {
    setSyncStrategyStatus(LOADING);
    dispatch(syncStrategy(accountIdOfActiveStrategy))
      .then(() => {
        setSyncStrategyStatus(SUCCESS);
      })
      .catch(() => {
        setSyncStrategyStatus(FAIL);
      });
  };

  return (
    <main className="single">
      <div className="container">
        {data && data.name && (
          <Breadcrumbs
            link={window.location.pathname}
            anchor={data.name}
            className="single__bread-crumbs"
          />
        )}

        {windowSize.innerWidth <= 1000 && <h1 className="single__title">{data && data.name}</h1>}

        <div className="single__top">
          {/* ОПИСАНИЕ СТРАТЕГИИ И АВТОРА */}
          <div className="single__top-text">
            {windowSize.innerWidth > 1000 && <h1 className="single__title">{data && data.name}</h1>}

            {isAuthorInfoLoading ? (
              <div className="single__top-author-loader">
                <CircleLoader />
              </div>
            ) : (
              <div className="single__top-author">
                <img
                  src={(authorInfo && authorInfo.image) || fakePhoto}
                  alt=""
                  className="single__top-author-photo"
                />
                <div className="single__top-author-info">
                  <p className="single__top-author-name">
                    {authorInfo && authorInfo.lastname}
                    {'  '} {authorInfo && authorInfo.name}
                  </p>
                  {/*<p className="single__top-author-desc">Брокерская компания</p>*/}
                </div>
              </div>
            )}

            {data && (
              <div className="single__invest-type">
                <p className="single__invest-type-text">Тип инвестора: {data.risklevelname}</p>
              </div>
            )}

            {/*<p className="single__top-description">{data && data.description}</p>*/}

            <div className="single__goal">
              <p className="single__goal-title">Цель: </p>
              <p className="single__top-description">{data && data.content.goal}</p>
            </div>

            <div className="single__buttons-list">
              {!isLoggedIn ? (
                <StandardButton
                  title="Подключить"
                  isLink={false}
                  bgType={YELLOW}
                  className="single__button"
                  onClick={() => setIsPopupLogInOrOpenAccount(true)}
                />
              ) : (
                <>
                  {isTurnOnStrategyButtonActive && (
                    <StandardButton
                      title="Подключить"
                      isLink={false}
                      onClick={() => {
                        ym('reachGoal', 'activated');
                        openActivateStrategyPopup();
                      }}
                      bgType={YELLOW}
                      className="single__button"
                    />
                  )}

                  {isTurnOffStrategyButtonActive && (
                    <StandardButton
                      title="Отключить"
                      isLink={false}
                      onClick={() => {
                        ym('reachGoal', 'deactivated');
                        setIsPopupDeactivateStrategyOpen(true);
                        setOperationProcess(DEACTIVATE_WARNING);
                      }}
                      bgType={YELLOW}
                      className="single__button"
                    />
                  )}

                  {isTurnOffStrategyButtonActive && (
                    <StandardButton
                      title="Синхронизировать"
                      isLink={false}
                      onClick={() => setIsPopupSyncStrategyOpen(true)}
                      bgType={YELLOW}
                      className="single__button"
                      isLoading={syncStrategyStatus === LOADING}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {/* /ОПИСАНИЕ СТРАТЕГИИ И АВТОРА */}

          {/* ГРАФИК */}
          <div className="single__graphs">
            <h2 className="single__graphs-title">Прибыль за</h2>

            <ChartNav
              className="single__profit-nav"
              list={navList}
              currentNavItem={currentNavItem}
              isRendered={true}
            />

            <div className="single__profit">
              {!chartDataError && <Profit percent={profitPercent} customText={textForProfit} />}
            </div>

            <Chart
              isChartDataReady={isChartDataReady}
              chartData={chartData}
              chartDataError={chartDataError}
              marginRight={40}
              marginLeft={25}
              isNeedToShowHint
            />
          </div>
          {/* /ГРАФИК */}
        </div>
      </div>

      <section className="single__specifications">
        <div className="single__specifications-container container">
          {/* ХАРАКТЕРИСТИКИ СТРАТЕГИИ */}
          <StrategyCharacteristics list={data} className="single__specifications-column" />
          {/* /ХАРАКТЕРИСТИКИ СТРАТЕГИИ */}

          {data && (data.fulldescription || data.description) && (
            <StrategyDesc
              desc={data && (data.fulldescription || data.description)}
              className="single__specifications-column"
              title="Подробное описание"
            />
          )}
        </div>
      </section>

      <div className="single__deals-wrap container">
        <div className="single__deals">
          {data && data.taskid && <Trades className="single__trades" id={data && data.taskid} />}
          {/*<PortfolioContent portfolioList={portfolioList} isLoggedIn={isLoggedIn} />*/}
        </div>
      </div>

      {Array.isArray(comment) && comment[0] && comment[0].content && (
        <div className="single__comments-wrap container">
          <StrategyDesc
            desc={Array.isArray(comment) && comment[0] && comment[0].content}
            className="single__author-comment"
            title="Комментарий автора"
          />
        </div>
      )}

      <div className="single__author-info container">
        <AuthorInfo authorInfo={authorInfo} isLoading={isAuthorInfoLoading} />
      </div>

      {!isLoggedIn && (
        <div className="single__activate-btn-wrap container">
          <StandardButton
            title="Подключить"
            isLink={false}
            bgType={YELLOW}
            className="single__button"
            onClick={() => setIsPopupLogInOrOpenAccount(true)}
          />
        </div>
      )}

      <div className="single__user-form-wrap container">
        <UserForm
          className="single__user-form"
          title="Получить консультацию"
          description="Оставьте контактную информацию, наши специалисты свяжутся с Вами в ближайшее время"
        />
      </div>

      {/* ПОПАП - АВТОРИЗУЙТЕСЬ ЛИБО ОТКРОЙТЕ СЧЕТ */}
      <PopupWrap
        mounted={isPopupLogInOrOpenAccount}
        closePopup={() => {
          setIsPopupLogInOrOpenAccount(false);
        }}
        className="activate-strategy-auth-popup"
      >
        <ActivateStrategyAuthPopup />
      </PopupWrap>
      {/* /ПОПАП - АВТОРИЗУЙТЕСЬ ЛИБО ОТКРОЙТЕ СЧЕТ */}

      {/* ПОПАП - АКТИВИРОВАТЬ СТРАТЕГИЮ */}
      <PopupWrap
        mounted={isPopupActiveStrategyOpen}
        closePopup={popupActiveStrategyClose}
        className="activate-strategy-popup"
      >
        <ActivateStrategyPopup
          status={activateStrategyStatus}
          isResult={operationProcess === OPERATION_RESULT}
        />

        {operationProcess === SELECT_ACCOUNT && (
          <SelectAccountForm
            closePopup={popupActiveStrategyClose}
            accountList={accountList}
            activateSmsContent={() => {
              /** Запускаем запросы на создание операции - подключить стратегию */
              activateStrategyHandler();

              /** Скрываем контент выбора счета */
              setOperationProcess(SMS_FORM);
            }}
            setSelectedAccount={setSelectedAccount}
            selectedAccount={selectedAccount}
            status={activateStrategyStatus}
          />
        )}

        {operationProcess === SMS_FORM && (
          <SmsContent
            status={activateStrategyStatus}
            closeModal={popupActiveStrategyClose}
            operationCreateHandler={() => activateStrategyHandler()}
            code={code}
            onChangeCode={({ target }) => setCode(target.value)}
            isCodeValid={isCodeValid}
            operationSignHandler={() => {
              /** Запускаем запросы на смс верификацию */
              operationSignHandler(AUTO_FOLLOW_ON);

              /** Скрываем форму ввода смс */
              setOperationProcess(OPERATION_RESULT);
            }}
            seconds={seconds}
            isTimeEnded={isTimeEnded}
            validations={
              validations.length > 0 ? validations.filter((i) => i.isError) : validations
            }
            operationDocuments={operationDocuments}
          />
        )}

        {operationProcess === OPERATION_RESULT && (
          <ActivateStrategyResult
            closePopup={popupActiveStrategyClose}
            status={activateStrategyStatus}
          />
        )}
      </PopupWrap>
      {/* /ПОПАП - АКТИВИРОВАТЬ СТРАТЕГИЮ */}

      {/* ПОПАП - ОТКЛЮЧЕНИЯ СТРАТЕГИИ */}
      <PopupWrap
        mounted={isPopupDeactivateStrategyOpen}
        closePopup={popupDeactivateStrategyClose}
        className="deactivate-strategy-popup"
      >
        <DeactivateStrategyPopup
          status={activateStrategyStatus}
          isWarning={operationProcess === DEACTIVATE_WARNING}
          isResult={operationProcess === OPERATION_RESULT}
        />

        {operationProcess === DEACTIVATE_WARNING && (
          <DeactivateStrategyWarning
            closePopup={popupDeactivateStrategyClose}
            deactivateStrategyHandler={() => {
              /** Запускаем запросы на создание операции - отключить стратегию */
              deactivateStrategyHandler();

              /** Скрываем контент выбора счета */
              setOperationProcess(SMS_FORM);
            }}
          />
        )}

        {operationProcess === SMS_FORM && (
          <SmsContent
            status={activateStrategyStatus}
            closeModal={popupDeactivateStrategyClose}
            operationCreateHandler={() => deactivateStrategyHandler()}
            code={code}
            onChangeCode={({ target }) => setCode(target.value)}
            isCodeValid={isCodeValid}
            operationSignHandler={() => {
              /** Запускаем запросы на смс верификацию */
              operationSignHandler(AUTO_FOLLOW_OFF);

              /** Скрываем форму ввода смс */
              setOperationProcess(OPERATION_RESULT);
            }}
            seconds={seconds}
            isTimeEnded={isTimeEnded}
            isDeactivation
            validations={
              validations.length > 0 ? validations.filter((i) => i.isError) : validations
            }
            operationDocuments={operationDocuments}
          />
        )}

        {operationProcess === OPERATION_RESULT && (
          <ActivateStrategyResult
            closePopup={popupDeactivateStrategyClose}
            status={activateStrategyStatus}
            isDeactivation
          />
        )}
      </PopupWrap>
      {/* /ПОПАП - ОТКЛЮЧЕНИЯ СТРАТЕГИИ */}

      {/* ПОПАП - СИНХРОНИЗАЦИЯ СТРАТЕГИИ */}
      <PopupWrap
        mounted={isPopupSyncStrategyOpen}
        closePopup={() => {
          setIsPopupSyncStrategyOpen(false);
          setSyncStrategyStatus(null);
        }}
      >
        {(!syncStrategyStatus || syncStrategyStatus === LOADING) && (
          <SyncStrategyForm
            closePopup={() => {
              setIsPopupSyncStrategyOpen(false);
              setSyncStrategyStatus(null);
            }}
            status={syncStrategyStatus}
            syncStrategyHandler={syncStrategyHandler}
          />
        )}

        {syncStrategyStatus === SUCCESS && <p>Синхронизация стратегии прошла успешно!</p>}
        {syncStrategyStatus === FAIL && (
          <p>
            К сожалению, что-то пошло не так и у нас не получилось синхронизировать стратегию.
            Попробуйте повторить позже
          </p>
        )}
      </PopupWrap>
      {/* /ПОПАП - СИНХРОНИЗАЦИЯ СТРАТЕГИИ */}
    </main>
  );
};

StrategiesSingle.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uniqueId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

StrategiesSingle.defaultProps = {
  isLoggedIn: false,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.lk.isLoggedIn,
});

export default connect(mapStateToProps)(StrategiesSingle);
