import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import StandardSelect from 'modules/shared/components/StandardSelect/StandardSelect';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { savePortfolioFeaturesListChanges } from 'modules/lk/actions';
import { getPortfolioComposition } from 'modules/main/actions';
import { BLUE_BORDER, BLUE, LOADING, SUCCESS, FAIL } from 'constants/index';

import { ReactComponent as IconClose } from 'assets/images/close.svg';

import './LkChangeFeaturesPopup.scss';

const LkChangeFeaturesPopup = ({ dispatch, id, closePopup, strategyData }) => {
  const [positionsList, setPositionsList] = useState([]);
  const [options, setOptions] = useState([]);
  // const [isLimit, setIsLimit] = useState(false);
  const [isPositionsListLoading, setIsPositionsListLoading] = useState(false);
  const [saveChangesStatus, setSaveChangesStatus] = useState(null);

  useEffect(() => {
    if (strategyData && strategyData.symbols) {
      const tempOptions = strategyData.symbols
        .filter(
          (item) => !positionsList.find((positionsListItem) => positionsListItem.symbol === item),
        )
        .map((item) => {
          return {
            value: item,
            label: item,
          };
        });

      setOptions(tempOptions);
    }
  }, [positionsList, strategyData]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setIsPositionsListLoading(true);
    if (id) {
      dispatch(getPortfolioComposition(id, true))
        .then((res) => {
          setPositionsList(res);
          setIsPositionsListLoading(false);
        })
        .catch(() => {
          setIsPositionsListLoading(false);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [id]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleSumChange = (value, name, isNew) => {
    setPositionsList((prevState) =>
      prevState.map((item) => {
        if (isNew) {
          if (item.tempId === name) {
            return {
              ...item,
              size: value.floatValue,
            };
          }
        } else {
          if (item.symbol === name) {
            return {
              ...item,
              size: value.floatValue,
            };
          }
        }

        return item;
      }),
    );
  };

  const handleNameChange = ({ value }) => {
    setPositionsList((prevState) =>
      prevState.map((item) => {
        if (item.tempId && item.tempId === 'new-item-1') {
          return {
            ...item,
            symbol: value,
            isNew: false,
            tempId: '',
          };
        }
        return item;
      }),
    );
  };

  const addNew = () => {
    setPositionsList((prevState) => [
      ...prevState,
      {
        symbol: '',
        size: 0,
        isNew: true,
        tempId: 'new-item-1',
      },
    ]);
  };

  const removePosition = (name, isNewPosition) => {
    setPositionsList((prevState) => {
      if (isNewPosition) {
        return prevState.filter((item) => item.tempId !== name);
      } else {
        return prevState.filter((item) => item.symbol !== name);
      }
    });
  };

  useEffect(() => {
    if (
      positionsList.reduce((curr, acc) => {
        return curr + Math.round(Number(acc.size));
      }, 0) <= 100
    ) {
      // setIsLimit(false);
    } else {
      // setIsLimit(true);
    }
  }, [positionsList]);

  const saveChanges = () => {
    setSaveChangesStatus(LOADING);
    dispatch(savePortfolioFeaturesListChanges(id, positionsList))
      .then((res) => {
        setSaveChangesStatus(SUCCESS);
      })
      .catch((err) => {
        setSaveChangesStatus(FAIL);
      });
  };

  return (
    <div className="lk-change-features-popup">
      <h2 className="lk-change-features-popup__title">Изменение состава портфеля</h2>

      {isPositionsListLoading || (saveChangesStatus && saveChangesStatus === LOADING) ? (
        <div className="lk-change-features-popup__loader">
          <CircleLoader />
        </div>
      ) : (
        <>
          {!saveChangesStatus && (
            <>
              {positionsList && positionsList.length > 0 && (
                <div className="lk-change-features-popup__list">
                  {positionsList.map((item) => (
                    <React.Fragment key={item.symbol}>
                      {item.isNew ? (
                        <div
                          className="lk-change-features-popup__line lk-change-features-popup__line_new"
                          key={item.symbol}
                        >
                          <StandardSelect
                            onChange={handleNameChange}
                            options={options}
                            className="lk-change-features-popup__line-title"
                          />
                          {/*<input type="text"  onChange={event => handleNameChange(event.target.value)}>{item.symbol}</input>*/}

                          <div className="lk-change-features-popup__line-main">
                            <NumberFormat
                              className="lk-change-features-popup__line-amount"
                              value={Number(item.size)}
                              thousandSeparator=" "
                              allowNegative
                              allowEmptyFormatting={false}
                              decimalScale={2}
                              isNumericString
                              allowedDecimalSeparators={[',', '.']}
                              type="tel"
                              onValueChange={(values) =>
                                handleSumChange(values, item.tempId, item.isNew)
                              }
                              isAllowed={(values) => {
                                const { value } = values;

                                // if (value.charAt(0) === '0') {
                                //   return value.charAt(1) === '.';
                                // }
                                // return true;

                                return value >= -100 && value <= 400;
                              }}
                            />
                            <p className="lk-change-features-popup__line-text">%</p>
                            <button
                              className="lk-change-features-popup__line-remove-button"
                              type="button"
                              onClick={() => removePosition(item.tempId, item.isNew)}
                            >
                              <IconClose className="lk-change-features-popup__line-remove-button-icon" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="lk-change-features-popup__line" key={item.symbol}>
                          <p className="lk-change-features-popup__line-title">{item.symbol}</p>

                          <div className="lk-change-features-popup__line-main">
                            <NumberFormat
                              className="lk-change-features-popup__line-amount"
                              value={Number(item.size)}
                              thousandSeparator=" "
                              allowNegative
                              allowEmptyFormatting={false}
                              decimalScale={2}
                              isNumericString
                              allowedDecimalSeparators={[',', '.']}
                              type="tel"
                              onValueChange={(values) => handleSumChange(values, item.symbol)}
                              isAllowed={(values) => {
                                const { value } = values;

                                // if (value.charAt(0) === '0') {
                                //   return value.charAt(1) === '.';
                                // }
                                // return true;

                                return value >= -100 && value <= 400;
                              }}
                            />
                            <p className="lk-change-features-popup__line-text">%</p>

                            <button
                              className="lk-change-features-popup__line-remove-button"
                              type="button"
                              onClick={() => removePosition(item.symbol, item.isNew)}
                            >
                              <IconClose className="lk-change-features-popup__line-remove-button-icon" />
                            </button>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}

              <div className="lk-change-features-popup__add-new-wrap">
                <button
                  className="lk-change-features-popup__add-new"
                  type="button"
                  onClick={addNew}
                  // disabled={positionsList.filter((item) => item.isNew).length || isLimit}
                >
                  <i className="lk-change-features-popup__add-new-icon-wrap">
                    <IconClose className="lk-change-features-popup__add-new-icon" />
                  </i>

                  <span className="lk-change-features-popup__add-new-text"> Добавить компанию</span>
                </button>
              </div>

              <div className="lk-change-features-popup__buttons-list">
                <StandardButton
                  isLink={false}
                  title="Отмена"
                  className="lk-change-features-popup__buttons-item"
                  bgType={BLUE_BORDER}
                  onClick={closePopup}
                />

                <StandardButton
                  isLink={false}
                  title="Сохранить"
                  className="lk-change-features-popup__buttons-item"
                  bgType={BLUE}
                  onClick={saveChanges}
                />
              </div>
            </>
          )}
        </>
      )}

      {saveChangesStatus && saveChangesStatus === SUCCESS && (
        <>
          <p className="lk-change-features-popup__error-message">
            Изменение состава портфеля выполнено
          </p>

          <div className="lk-change-features-popup__buttons-list">
            <StandardButton
              isLink={false}
              title="Вернуться в лк"
              className="lk-change-features-popup__buttons-item"
              bgType={BLUE}
              onClick={closePopup}
            />
          </div>
        </>
      )}

      {saveChangesStatus && saveChangesStatus === FAIL && (
        <>
          <p className="lk-change-features-popup__error-message">
            Что-то пошло не так, и мы не смогли сохранить ваши изменения. Попробуйте повторить
            запрос позже.
          </p>

          <div className="lk-change-features-popup__buttons-list">
            <StandardButton
              isLink={false}
              title="Вернуться в лк"
              className="lk-change-features-popup__buttons-item"
              bgType={BLUE}
              onClick={closePopup}
            />
          </div>
        </>
      )}
    </div>
  );
};

LkChangeFeaturesPopup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  closePopup: PropTypes.func.isRequired,
  strategyData: PropTypes.shape({}).isRequired,
};

export default connect()(LkChangeFeaturesPopup);
