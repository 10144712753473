import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import AuthorInfo from 'modules/main/components/AuthorInfo/AuthorInfo';
import { getStrategyAuthorInfo } from 'modules/main/actions';

const ManagersItem = ({ strategyData, className }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [authorInfo, setAuthorInfo] = useState(null);

  /** Запрашиваем данные по автору */
  useEffect(() => {
    if (strategyData && strategyData.userid) {
      dispatch(getStrategyAuthorInfo(strategyData.userid))
        .then((res) => {
          setAuthorInfo(res);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [strategyData]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      className={cx('', {
        [className]: className,
      })}
    >
      <AuthorInfo authorInfo={authorInfo} isLoading={isLoading} />
    </div>
  );
};

ManagersItem.defaultTypes = {
  className: null,
};

ManagersItem.propTypes = {
  className: PropTypes.string,
  strategyData: PropTypes.shape({}).isRequired,
};

export default ManagersItem;
