import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import useToggleAndOutClick from 'use-toggle-and-outclick';
import { Link } from 'react-router-dom';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import Chart from 'modules/shared/components/Chart/Chart';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { YELLOW } from 'constants/index';
import {
  getBgChartForStrategy,
  getStrategyAuthorInfo,
  getBgChartForStrategy987,
} from 'modules/main/actions';

import { ReactComponent as IconInfo } from 'assets/images/icon-info.svg';
import fakePhoto from 'assets/images/fm-avatar.svg';

// import { dateDiffInDays, formatDateMMDDYYYY, formatNumber } from 'helpers/index';
import { formatDateMMDDYYYY, formatNumber, arrayFilterByCount } from 'helpers/index';

import './StrategiesItem.scss';

const StrategiesItem = ({ className, strategiesData }) => {
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([]);
  const [chartDateStart, setChartDateStart] = useState(null);
  const [chartDateEnd, setChartDateEnd] = useState(null);
  const [chartPercent, setChartPercent] = useState(null);
  // const fakeSubscribers =
  //   (+strategiesData.strategyid % 10) * 17 + dateDiffInDays(new Date(), new Date('08/15/2020')) * 7;
  // const [subscribers, setSubscribers] = useState(fakeSubscribers);
  const [isChartDataReady, setIsChartDataReady] = useState(false);
  const [isDropOpen, dropEl, toggleDrop] = useToggleAndOutClick();
  const [authorInfo, setAuthorInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   if (realUsers && realUsers.length && realUsers[0] && realUsers[0].total) {
  //     setSubscribers(fakeSubscribers + Number(realUsers[0].total));
  //   } else {
  //     setSubscribers(fakeSubscribers);
  //   }
  //   /* eslint-disable react-hooks/exhaustive-deps */
  // }, [realUsers]);
  // /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (strategiesData && strategiesData.userid) {
      dispatch(getStrategyAuthorInfo(strategiesData.userid))
        .then((res) => {
          setAuthorInfo(res);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [strategiesData]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (strategiesData.strategyid === '987') {
      dispatch(getBgChartForStrategy987('2020-04-01'))
        .then((innerRes) => {
          if (innerRes && innerRes.length) {
            const chartPoints = arrayFilterByCount(innerRes, 30);

            setChartData(
              chartPoints.map((item, index) => {
                if (index === 0) {
                  setChartDateStart(item.date.slice(0, 10).split('-').reverse().join('.'));
                }
                if (index === chartPoints.length - 1) {
                  setChartDateEnd(item.date.slice(0, 10).split('-').reverse().join('.'));

                  setChartPercent(item.value);
                }

                return {
                  x: new Date(item.date),
                  y: item.value,
                };
              }),
            );
          }

          setIsChartDataReady(true);
        })
        .catch(() => {
          setIsChartDataReady(true);
        });
    } else {
      dispatch(getBgChartForStrategy(strategiesData.taskid, 30, '2020-04-01'))
        .then((innerRes) => {
          if (innerRes && innerRes.length) {
            setChartData(
              innerRes.map((item, index) => {
                if (index === 0) {
                  /** Сохраняем первую дату */
                  setChartDateStart(item.timestamp.slice(0, 10));
                }
                if (index === innerRes.length - 1) {
                  /** Сохраняем последнюю дату */
                  setChartDateEnd(item.timestamp.slice(0, 10));
                  /** Сохраняем процент */
                  setChartPercent(item.equity);
                }

                return {
                  x: formatDateMMDDYYYY(item.timestamp),
                  y: Number(item.equity),
                };
              }),
            );
          }

          setIsChartDataReady(true);
        })
        .catch(() => {
          setIsChartDataReady(true);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      className={cx('strategies-item', {
        [className]: className,
      })}
    >
      <Link to={'/strategies/' + strategiesData.strategyid} className="strategies-item__title">
        {strategiesData.name}
      </Link>
      <div className="strategies-item__risk">{strategiesData.risklevelname}</div>

      {strategiesData.content && strategiesData.content.goal && (
        <div className="strategies-item__purpose-block">
          <p className="strategies-item__purpose-title">Цель</p>

          <p className="strategies-item__purpose-itself">{strategiesData.content.goal}</p>
        </div>
      )}

      <div className="strategies-item__main">
        <div className="strategies-item__features">
          {strategiesData.minimuminvestment && (
            <div className="strategies-item__features-item">
              <p className="strategies-item__features-title">
                от {formatNumber(strategiesData.minimuminvestment)}{' '}
                {strategiesData.marketname === 'SPBEX' ? '$' : 'рублей'}
              </p>
              <p className="strategies-item__features-desc">Минимальная сумма</p>
            </div>
          )}

          {strategiesData.content && (
            <div className="strategies-item__features-item">
              <p className="strategies-item__features-title">
                {strategiesData.content.investmentperiod}
              </p>
              <p className="strategies-item__features-desc">Срок инвестиционных идей</p>
            </div>
          )}

          <div className="strategies-item__features-item">
            <p className="strategies-item__features-title">
              до {strategiesData.apr} % годовых{' '}
              {strategiesData.marketname === 'ММВБ' ? 'в рублях' : 'в долларах'}
            </p>
            <p className="strategies-item__features-desc">Потенциальная доходность</p>
          </div>
        </div>

        <div className="strategies-item__chart-block">
          <div className="strategies-item__chart-top">
            {/*<p className="strategies-item__subscribers">Подписчики: {subscribers}</p>*/}

            <button className="strategies-item__hint-button" type="button" onClick={toggleDrop}>
              <IconInfo className="strategies-item__hint-button-icon" />
            </button>

            {isDropOpen && (
              <div className="strategies-item__hint" ref={dropEl}>
                {`Результаты работы стратегии «${strategiesData.name.toUpperCase()}» с\u00a0${chartDateStart} по\u00a0${chartDateEnd} ${
                  chartPercent > 0 ? '+' : ''
                }${chartPercent}% в\u00a0${
                  strategiesData.marketname === 'SPBEX' ? 'долларах\u00a0США' : 'рублях'
                }`}
              </div>
            )}
          </div>

          <Chart
            chartData={chartData}
            hideXAxis
            isChartDataReady={isChartDataReady}
            height={156}
            marginRight={40}
            marginLeft={0}
          />
        </div>
      </div>

      <div className="strategies-item__bottom">
        {/* АВТОР */}
        {isLoading ? (
          <CircleLoader />
        ) : (
          <div className="strategies-item__author">
            <img
              src={(authorInfo && authorInfo.image) || fakePhoto}
              alt={strategiesData.author || 'FOLLOWME'}
              loading={'lazy'}
              className="strategies-item__author-photo"
            />
            <div className="strategies-item__author-text">
              <p className="strategies-item__author-name">
                {authorInfo && authorInfo.lastname} {'  '} {authorInfo && authorInfo.name}
              </p>
              {/*<p className="strategies-item__author-info">Брокерская компания</p>*/}
            </div>
          </div>
        )}

        {/* /АВТОР */}

        <StandardButton
          title="Подробнее"
          isLink
          href={'/strategies/' + strategiesData.strategyid}
          className="strategies-item__link"
          bgType={YELLOW}
        />
      </div>
    </div>
  );
};

StrategiesItem.propTypes = {
  className: PropTypes.string,
  strategiesData: PropTypes.shape({
    strategyid: PropTypes.string,
    author: PropTypes.string,
    risklevelid: PropTypes.string,
    risklevelname: PropTypes.string,
    name: PropTypes.string,
    taskresult: PropTypes.shape({
      apr: PropTypes.string,
    }),
  }).isRequired,
  // realUsers: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     strategy: PropTypes.string,
  //     total: PropTypes.string,
  //     totalvalue: PropTypes.string,
  //   }),
  // ),
};

StrategiesItem.defaultProps = {
  className: null,
  // realUsers: [],
};

export default StrategiesItem;
