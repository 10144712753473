export const NAVIGATION = [
  { title: 'Стратегии', href: '/strategies' },
  { title: 'Управляющие', href: '/managers' },
  { title: 'Подключиться', href: '/connect' },
  { title: 'Стать партнером', href: '/become-a-partner' },
  { title: 'О платформе', href: '/about' },
  // { title: 'Контакты', href: '/contacts' },
];

export const BLUE = 'BLUE';
export const WHITE = 'WHITE';
export const WHITE_BORDER = 'WHITE_BORDER';
export const BLUE_BORDER = 'BLUE_BORDER';
export const ORANGE = 'ORANGE';
export const ORANGE_BORDER = 'ORANGE_BORDER';
export const GREY_BORDER = 'GREY_BORDER';
export const YELLOW = 'YELLOW';
export const YELLOW_BORDER = 'YELLOW_BORDER';

export const LOADING = 'LOADING';
export const FAIL = 'FAIL';
export const SUCCESS = 'SUCCESS';
export const NO_RESULT = 'NO_RESULT';
export const NO_STRATEGIES = 'NO_STRATEGIES';

export const AUTO_FOLLOW_ON = 'AUTO_FOLLOW_ON';
export const AUTO_FOLLOW_OFF = 'AUTO_FOLLOW_OFF';

export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const SMS_FORM = 'SMS_FORM';
export const DEACTIVATE_WARNING = 'DEACTIVATE_WARNING';
export const OPERATION_RESULT = 'OPERATION_RESULT';

export const MONTH = 'MONTH';
export const THREE_MONTHS = 'THREE_MONTHS';
export const YEAR = 'YEAR';
export const ALL = 'ALL';
export const DAY = 'DAY';
export const PERIOD = 'PERIOD';

export const MONTH_LIST = [
  'января',
  'фераля.',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const WEEK_DAY_LIST = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
