import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '@rehooks/window-size';
import { Link } from 'react-router-dom';

import UserForm from 'modules/main/components/UserForm/UserForm';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { ReactComponent as IconIllustration } from 'assets/images/about-platform-illustration.svg';
import { YELLOW } from 'constants/index';

import './WorkFlow.scss';

const WorkFlow = forwardRef(({ scrollToPartnerCta, scrollToStrategies }, { ref1, ref2 }) => {
  const windowSize = useWindowSize();

  const howToConnectList = [
    {
      id: 1,
      desc: (
        <p>
          Откройте счет в АЛОР БРОКЕР {'  '}
          <a href="https://www.alorbroker.ru/open" target="_blank" rel="noopener noreferrer">
            онлайн
          </a>
        </p>
      ),
    },
    {
      id: 2,
      desc: (
        <p>
          Внесите денежные средства в{'  '}
          <a href="https://lk.alor.ru/login" target="_blank" rel="noopener noreferrer">
            Кабинете клиента
          </a>
          {'  '}
          или
          {'  '}{' '}
          <a
            href="https://storage.alorbroker.ru/openinfo/%D0%A0%D0%B5%D0%BA%D0%B2%D0%B8%D0%B7%D0%B8%D1%82%D1%8B_2020.09.29.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            банковским переводом
          </a>
        </p>
      ),
    },
    {
      id: 3,
      desc: (
        <p>
          Подключите понравившуюся{'  '}
          <button type="button" onClick={scrollToStrategies}>
            стратегию
          </button>
        </p>
      ),
    },
    {
      id: 4,
      desc: (
        <p>
          Отслеживайте результаты в&nbsp;<Link to="/lk">Кабинете Follow&nbsp;me</Link>
        </p>
      ),
    },
  ];
  const howToChooseStrategy = [
    {
      id: 1,
      desc: 'Изучаем  документацию о работе стратегии',
    },
    {
      id: 2,
      desc: 'Проверяем и оцениваем опыт управляющего',
    },
    {
      id: 3,
      desc: 'Определяем риск-профиль стратегии',
    },
    {
      id: 4,
      desc: 'Тестируем стратегию на соответствие заявленным параметрам',
    },
    {
      id: 5,
      desc: 'Принимаем решение о возможности применения',
    },
  ];

  return (
    <div ref={ref1} className="work-flow">
      <div className="work-flow__container container">
        <div className="work-flow__list">
          {/* О ПЛАТФОРМЕ */}
          <div className="work-flow__block">
            <div className="work-flow__block-content">
              <div className="work-flow__block-title">О платформе</div>

              <div className="work-flow__block-desc-wrap">
                <p className="work-flow__block-desc">
                  Разработанная нами система Автоследования позволяет подключить счет к выбранной
                  стратегии профессионального управляющего.
                </p>
                <p className="work-flow__block-desc">
                  Операции по счету совершаются автоматически, копируя сделки на счете Управляющего.
                </p>
              </div>

              <StandardButton
                bgType={YELLOW}
                title="Подробнее"
                isLink
                href="/about"
                className="work-flow__block-button"
              />
            </div>

            {windowSize.innerWidth > 980 && (
              <div className="work-flow__block-illustration-wrap">
                <IconIllustration className="work-flow__block-illustration" />
              </div>
            )}
          </div>
          {/* О ПЛАТФОРМЕ */}

          {/* КАК ПОДКЛЮЧИТЬСЯ */}
          <div ref={ref2} className="work-flow__block">
            <div className="work-flow__block-content">
              <div className="work-flow__block-title">Как подключиться</div>

              <div className="work-flow__block-desc-wrap">
                {howToConnectList.map((item) => (
                  <div className="work-flow__block-desc-item" key={item.id}>
                    <div className="work-flow__block-desc-item-number">
                      {item.id.toString().padStart(2, '0')}
                    </div>

                    <div className="work-flow__block-desc">{item.desc}</div>
                  </div>
                ))}
              </div>

              <StandardButton
                bgType={YELLOW}
                title="Открыть счет"
                isLink={true}
                isOutAppLink
                href="https://www.alorbroker.ru/open"
                className="work-flow__block-button"
              />
            </div>
          </div>
          {/* /КАК ПОДКЛЮЧИТЬСЯ */}

          {/* КАК ВЫБИРАЕМ СТРАТЕГИИ */}
          <div className="work-flow__block">
            <div className="work-flow__block-content">
              <div className="work-flow__block-title">Как выбираем стратегии</div>

              <div className="work-flow__block-desc-wrap">
                {howToChooseStrategy.map((item) => (
                  <div className="work-flow__block-desc-item" key={item.id}>
                    <div className="work-flow__block-desc-item-number">
                      {item.id.toString().padStart(2, '0')}
                    </div>

                    <div className="work-flow__block-desc">{item.desc}</div>
                  </div>
                ))}
              </div>

              <StandardButton
                bgType={YELLOW}
                title="Предложить стратегию"
                isLink
                href={'/become-a-partner'}
                // onClick={scrollToPartnerCta}
                className="work-flow__block-button"
              />
            </div>
          </div>
          {/* /КАК ВЫБИРАЕМ СТРАТЕГИИ */}
        </div>
      </div>

      <div className="work-flow__cta container">
        <UserForm
          className="homepage__form"
          title="Получить консультацию"
          description="Оставьте контактную информацию, наши специалисты свяжутся с Вами в ближайшее время"
        />
      </div>
    </div>
  );
});

WorkFlow.propTypes = {
  scrollToPartnerCta: PropTypes.func.isRequired,
  scrollToStrategies: PropTypes.func.isRequired,
};

export default WorkFlow;
