import React from 'react';

import TextBLock from 'modules/main/components/TextBLock/TextBLock';

import advImg01 from 'assets/images/advantages-01.svg';
import advImg02 from 'assets/images/advantages-02.svg';

import './Advantages.scss';

const Advantages = () => {
  const advantages = [
    {
      title: 'Аккредитация',
      img: advImg01,
      flipped: false,
      text:
        '<p>Требование об обязательной аккредитации программ автоследования предусмотрено действующим  законодательством об инвестиционном консультировании.</p>' +
        '<br />' +
        '<p>Платформа «FOLLOW ME» 20.10.2020 года  прошла аккредитацию в Национальной ассоциации участников фондового рынка (НАУФОР).</p>' +
        '<br />' +
        '<p>НАУФОР - некоммерческая саморегулируемая организация на российском финансовом рынке, является уполномоченной Банком России организацией на проведение аккредитации автоматических программ по инвестиционному консультированию.</p>' +
        '<br />' +
        '<p>Надзор за деятельностью НАУФОР осуществляет Банк России.</p>',
      footer: '',
    },
    {
      title: 'Надежность',
      img: advImg02,
      flipped: true,
      text:
        '<p>АЛОР БРОКЕР – обслуживает частных инвесторов с 1997 года, имеет лицензию  профессионального участника рынка ценных бумаг Российской Федерации на осуществление брокерской деятельности на рынке ценных бумаг № 077-04827-100000.</p>' +
        '<br />' +
        '<p>АЛОР БРОКЕР является членом НАУФОР в отношении брокерской, дилерской и депозитарной деятельности, а также деятельности по инвестиционному консультированию и по управлению ценными бумагами.</p>' +
        '<br />' +
        '<p>В соответствии с требованиями Банка России к брокерским организациям, компания ежемесячно предоставляет отчетность, подтверждает финансовую устойчивость и соответствие осуществляемой ей деятельности нормам действующего законодательства в отношении профессиональных участников рынка ценных бумаг.</p>',
      footer:
        '<p>Информация о компании на сайте Банка России</p>' +
        '<a href="http://www.cbr.ru/finorg/foinfo/?ogrn=1027700075941" target="_blank">www.cbr.ru/finorg/foinfo/?ogrn=1027700075941</a>',
    },
  ];

  return (
    <div className="advantages">
      <div className="advantages__container container">
        {advantages.map(({ title, text, img, flipped, footer }) => (
          <TextBLock
            key={title}
            title={title}
            text={text}
            img={img}
            flipped={flipped}
            footer={footer}
          />
        ))}
      </div>
    </div>
  );
};

export default Advantages;
