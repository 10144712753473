import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconArrow } from 'assets/images/icon-scheme-arrow.svg';

import './WorkSchemeBlock.scss';

const WorkSchemeBlock = ({ info: { title, icon } }) => {
  return (
    <div className="work-scheme-block">
      <div className="work-scheme-block__arrow">
        <IconArrow className="work-scheme-block__arrow-icon" />
      </div>
      <div className="work-scheme-block__content">
        {icon && <div className="work-scheme-block__icon-wrap">{icon}</div>}
        {title && <div className="work-scheme-block__title">{title}</div>}
      </div>
    </div>
  );
};

WorkSchemeBlock.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.element,
    icon: PropTypes.element,
    link: PropTypes.bool,
  }).isRequired,
};

export default WorkSchemeBlock;
