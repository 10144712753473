import React from 'react';

import HowItWorksInfo from 'modules/main/components/HowItWorksInfo/HowItWorksInfo';
import WorkScheme from 'modules/main/components/WorkScheme/WorkScheme';

import { ReactComponent as IconPoint } from 'assets/images/icon-point-white.svg';
import { ReactComponent as IconRefresh } from 'assets/images/icon-refresh-white.svg';
import { ReactComponent as IconMonitor } from 'assets/images/icon-monitor-white.svg';
import { ReactComponent as IconExplore } from 'assets/images/icon-explore-white.svg';
import { ReactComponent as IconKey } from 'assets/images/icon-key-white.svg';

import './HowItWorks.scss';
import { Link } from 'react-router-dom';

const HowItWorks = () => {
  const workSchemeItems = [
    {
      id: 1,
      title: <>Выбор стратегии</>,
      icon: <IconPoint className="work-scheme-block__icon" />,
    },
    {
      id: 2,
      title: <>Открытие счета&nbsp;в АЛОР&nbsp;БРОКЕР и&nbsp;инвестиционное профилирование</>,
      icon: <IconKey className="work-scheme-block__icon" />,
    },
    {
      id: 3,
      title: <>Подключение счета к стратегии</>,
      icon: <IconRefresh className="work-scheme-block__icon" />,
    },
    {
      id: 4,
      title: <>Автоматическое повторение на счете сделок Управляющего</>,
      icon: <IconExplore className="work-scheme-block__icon" />,
    },
    {
      id: 5,
      title: (
        <>
          Отслеживайте результаты&nbsp;в <Link to="/lk">Кабинете FOLLOW&nbsp;ME</Link>
        </>
      ),
      icon: <IconMonitor className="work-scheme-block__icon" />,
    },
  ];

  return (
    <div className="how-it-works">
      <div className="how-it-works__container container">
        <h2 className="how-it-works__title">Как это работает?</h2>
        <HowItWorksInfo />
        <WorkScheme items={workSchemeItems} />
      </div>
    </div>
  );
};

HowItWorks.propTypes = {};

export default HowItWorks;
