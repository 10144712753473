import React from 'react';

import { ReactComponent as IconHorse } from 'assets/images/icon-horse.svg';
import { ReactComponent as IconUsers } from 'assets/images/icon-users.svg';

import './StatisticTable.scss';

const StatisticTable = ({ statistic }) => {
  return (
    <div className="statistic-table">
      <div className="statistic-table__head">
        <div className="statistic-table__col">
          <IconHorse className="statistic-table__col-icon" />
          <div className="statistic-table__col-text">Стратегия</div>
        </div>
        <div className="statistic-table__col">
          <IconUsers className="statistic-table__col-icon" />
          <div className="statistic-table__col-text">Всего</div>
        </div>
      </div>

      <div className="statistic-table__body">
        {statistic &&
          statistic.map((item) => (
            <div className="statistic-table__line">
              <div className="statistic-table__col">
                <div className="statistic-table__col-text">{item.strategy}</div>
              </div>
              <div className="statistic-table__col">
                <div className="statistic-table__col-texT">{item.total}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default StatisticTable;
