import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CalendarButton from 'modules/lk/components/CalendarButton/CalendarButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import IirReportTable from 'modules/lk/components/IirReportTable/IirReportTable';

import { MONTH, DAY, PERIOD, YELLOW_BORDER, YELLOW, LOADING, SUCCESS, FAIL } from 'constants/index';
import { formatDateYYYYDDMM, daysInMonth } from 'helpers/index';
import { generateReport, setIsDataForIrrPopup, getDealsForReport } from 'modules/lk/actions';
import { getStrategiesAccountList } from 'modules/main/actions';

import { ReactComponent as IconCheck } from 'assets/images/icon-check.svg';

import './IirReportPopup.scss';

const IirReportPopup = ({ closePopup, dispatch, isDealsPopup }) => {
  const [currentNavItem, setCurrentNavItem] = useState(DAY);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState(null);
  const [table, setTable] = useState([]);
  const [deals, setDeals] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [chosenAccounts, setChosenAccounts] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  /** Запрос на список счетов */
  useEffect(() => {
    dispatch(getStrategiesAccountList())
      .then((res) => {
        res
          .filter((item) => item.value !== '0.00')
          .forEach((item) => {
            setAccountList((prevState) =>
              [...prevState].concat([
                {
                  value: item.account,
                  label: `${item.account}`,
                  accountid: item.accountid,
                },
              ]),
            );
          });
      })
      .catch(() => {});
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (currentNavItem === DAY) {
      setFromDate(`${formatDateYYYYDDMM(new Date(startDate))} 00:00:00`);
      setToDate(`${formatDateYYYYDDMM(new Date(startDate))} 23:59:59`);
    }

    if (currentNavItem === MONTH) {
      const month = startDate.getMonth();
      const year = startDate.getFullYear();
      setFromDate(`${year}-${(month + 1).toString().padStart(2, '0')}-01 00:00:00`);
      setToDate(
        `${year}-${(month + 1).toString().padStart(2, '0')}-${daysInMonth(
          month + 1,
          year,
        )} 23:59:59`,
      );
    }

    if (currentNavItem === PERIOD) {
      setFromDate(`${formatDateYYYYDDMM(startDate)} 00:00:00`);
      setToDate(`${formatDateYYYYDDMM(endDate)} 23:59:59`);
    }
  }, [currentNavItem, startDate, endDate]);

  const makeReport = () => {
    setStatus(LOADING);
    chosenAccounts.forEach((item) => {
      dispatch(generateReport(item.accountid, fromDate, toDate))
        .then((res) => {
          setStatus(SUCCESS);
          if (res) {
            setTable((prevState) =>
              [...prevState].concat([
                {
                  id: item.label,
                  list: res,
                },
              ]),
            );
            dispatch(setIsDataForIrrPopup(true));
          }
          return dispatch(getDealsForReport(item.accountid, fromDate, toDate));
        })
        .then((dealsRes) => {
          setDeals((prevState) =>
            [...prevState].concat([
              {
                id: item.label,
                list: dealsRes,
              },
            ]),
          );
        })
        .catch(() => {
          setStatus(FAIL);
        });
    });
  };

  return (
    <div className="iir-report-popup">
      {!status && (
        <>
          <h2 className="iir-report-popup__title">
            {isDealsPopup ? 'Подготовка отчета по сделкам' : 'Подготовка отчета ИИР'}
          </h2>

          <p className="iir-report-popup__nav-wrap-title">Отметьте счета для формирования отчета</p>

          <div className="iir-report-popup__account-list">
            {accountList &&
              accountList.map((item) => (
                <div className="iir-report-popup__account-item-wrap" key={item.accountid}>
                  <label className="iir-report-popup__account-item">
                    <button
                      aria-checked={chosenAccounts.find(
                        (chosenItem) => chosenItem.accountid === item.accountid,
                      )}
                      role="checkbox"
                      type="button"
                      className="iir-report-popup__account-item-checkbox"
                      onClick={() =>
                        setChosenAccounts((prevState) =>
                          [...prevState].find(
                            (chosenItem) => chosenItem.accountid === item.accountid,
                          )
                            ? [...prevState].filter(
                                (chosenItem) => chosenItem.accountid !== item.accountid,
                              )
                            : [...prevState].concat([item]),
                        )
                      }
                    >
                      {chosenAccounts.find(
                        (chosenItem) => chosenItem.accountid === item.accountid,
                      ) && <IconCheck className="iir-report-popup__account-item-checkbox-icon" />}
                    </button>
                    <p className="iir-report-popup__account-item-checkbox-text">{item.label}</p>
                  </label>
                </div>
              ))}
          </div>

          <div className="iir-report-popup__nav-wrap">
            <p className="iir-report-popup__nav-wrap-title">Выбрать временной период</p>

            <div className="iir-report-popup__nav">
              <CalendarButton
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                isActive={currentNavItem === DAY}
                additionalClick={() => setCurrentNavItem(DAY)}
                type={DAY}
                title="День"
                isSimple
                currentNavItem={currentNavItem}
              />

              <CalendarButton
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                isActive={currentNavItem === MONTH}
                additionalClick={() => setCurrentNavItem(MONTH)}
                type={MONTH}
                range={30}
                title="Месяц"
                isMonth
                currentNavItem={currentNavItem}
              />

              <CalendarButton
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                isActive={currentNavItem === PERIOD}
                additionalClick={() => setCurrentNavItem(PERIOD)}
                type={PERIOD}
                isRange
                range={30}
                title="Период"
                currentNavItem={currentNavItem}
              />
            </div>
          </div>

          <div className="iir-report-popup__buttons-list">
            <StandardButton
              title="Отмена"
              isLink={false}
              onClick={closePopup}
              bgType={YELLOW_BORDER}
              className="iir-report-popup__buttons-item"
            />
            <StandardButton
              title="Сформировать отчет"
              isLink={false}
              onClick={makeReport}
              bgType={YELLOW}
              className="iir-report-popup__buttons-item"
              disabled={(chosenAccounts && !chosenAccounts.length) || !currentNavItem}
            />
          </div>
        </>
      )}

      {status === LOADING && <CircleLoader />}

      {status === SUCCESS && <IirReportTable deals={deals} isDeals={isDealsPopup} table={table} />}
    </div>
  );
};

IirReportPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  isDealsPopup: PropTypes.bool.isRequired,
};

IirReportPopup.defaultProps = {
  isDealsPopup: false,
};

export default connect()(IirReportPopup);
