import React from 'react';
import ym from 'react-yandex-metrika';

import { ReactComponent as IconPhone } from 'assets/images/icon-phone.svg';
import { ReactComponent as IconWhatsapp } from 'assets/images/icon-whatsapp.svg';
import { ReactComponent as IconMail } from 'assets/images/icon-mail.svg';
import { ReactComponent as IconMapPin } from 'assets/images/icon-map-pin.svg';

import './TopBar.scss';

const TopBar = () => {
  const items = [
    {
      id: 1,
      icon: <IconPhone className="top-bar__nav-list-icon" />,
      text: '+7 800 775 11-99',
      href: 'tel:+78007751199',
      target: false,
      onCLick: () => ym('reachGoal', 'call'),
    },
    {
      id: 2,
      icon: <IconWhatsapp className="top-bar__nav-list-icon" />,
      text: '+7 966 050 56-63',
      href: 'https://wa.me/+79660505663',
      target: true,
      onCLick: () => ym('reachGoal', 'whatsapp'),
    },
    {
      id: 3,
      icon: <IconMail className="top-bar__nav-list-icon" />,
      text: 'FM@alor.ru',
      href: 'mailto:FM@alor.ru',
      target: false,
    },
    {
      id: 4,
      icon: <IconMapPin className="top-bar__nav-list-icon" />,
      text: 'Офисы',
      href: '/contacts',
      target: false,
    },
  ];

  return (
    <div className="top-bar">
      <div className="top-bar__container container">
        <nav className="top-bar__nav">
          <ul className="top-bar__nav-list">
            {items.map(({ id, icon, text, href, target, onCLick }) => (
              <li key={id} className="top-bar__nav-list-item">
                <div className="top-bar__nav-list-icon-wrap">{icon}</div>
                <a
                  href={href}
                  target={target ? '_blank' : '_self'}
                  className="top-bar__nav-list-link"
                  onClick={onCLick}
                >
                  {text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default TopBar;
