import React from 'react';

import Breadcrumbs from 'modules/shared/components/Breadcrumbs/Breadcrumbs';
import GoToStrategies from 'modules/main/components/GoToStrategies/GoToStrategies';

import heroImg from 'assets/images/about-hero-img.svg';

import './AboutHero.scss';

const AboutHero = () => {
  return (
    <div className="about-hero">
      <div className="about-hero__container">
        <Breadcrumbs
          link={window.location.pathname}
          anchor="О платформе"
          className="about-hero__bread-crumbs"
        />
        <div className="about-hero__content">
          <div className="about-hero__info-wrap">
            <h1 className="about-hero__title">О платформе</h1>
            <div className="about-hero__info">
              {/*<p className="about-hero__info-text">*/}
              {/*  Система автоследования «FOLLOW ME» позволяет инвесторам, не обладая специальными*/}
              {/*  знаниями, использовать для размещения денежных средств проверенные торговые*/}
              {/*  стратегии, а их авторам получать вознаграждение, если стратегия приносит инвесторам*/}
              {/*  прибыль.*/}
              {/*</p>*/}
              <p className="about-hero__info-text">
                Разработанная система автоследования позволяет подключить счет к&nbsp;торговым
                стратегиям профессиональных управляющих. Сделки по&nbsp;счету будут совершаться
                автоматически, следуя сигналам экспертов рынка.
              </p>
            </div>
            <GoToStrategies />
          </div>
          <div className="about-hero__img-wrap">
            <img src={heroImg} alt="" className="about-hero__img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
