import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import { YELLOW } from 'constants/index';
import { ReactComponent as IconLaptop } from 'assets/images/top-block-img.svg';

import './TopBanner.scss';

const TopBanner = ({ className, scrollToStrategies, scrollToPartner }) => {
  const infoList = [
    {
      id: 1,
      title: 'Работаем с',
      desc: '1997 года',
    },
    {
      id: 2,
      title: 'Оборот',
      desc: '7.2 трлн ₽',
    },
    {
      id: 3,
      title: 'Клиентов по всей России *',
      desc: '37 000 +',
    },
  ];

  return (
    <div
      className={cx('top-banner', {
        [className]: className,
      })}
    >
      <div className="top-banner__container">
        <div className="top-banner__content">
          <h1 className="top-banner__title">Инвестируйте, используя проверенные стратегии</h1>

          <div className="top-banner__button-wrap">
            <StandardButton
              className="top-banner__button"
              title="Начать"
              isLink={false}
              onClick={scrollToStrategies}
              // isOutAppLink
              // href="https://www.alorbroker.ru/open"
              bgType={YELLOW}
            />
          </div>

          <div className="top-banner__info">
            <button className="top-banner__info-button" onClick={scrollToPartner} type="button">
              Стратегический партнер платформы: АЛОР БРОКЕР
            </button>

            <div className="top-banner__info-list">
              {infoList.map((item) => (
                <div className="top-banner__info-item" key={item.id}>
                  <p className="top-banner__info-item-key">{item.title}</p>
                  <p className="top-banner__info-item-desc">{item.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="top-banner__image-container">
          <IconLaptop className="top-banner__image" />
        </div>
      </div>
    </div>
  );
};

TopBanner.propTypes = {
  className: PropTypes.string,
  scrollToStrategies: PropTypes.func.isRequired,
  scrollToPartner: PropTypes.func.isRequired,
};

TopBanner.defaultProps = {
  className: null,
};

export default TopBanner;
