import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ru } from 'date-fns/esm/locale';
import cx from 'classnames';

import './CalendarButton.scss';

const CalendarButton = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isActive,
  additionalClick,
  title,
  isRange,
  isMonth,
  isSimple,
}) => {
  registerLocale('ru', ru);

  return (
    <a
      className={cx('calendar-button', {
        'calendar-button_active': isActive,
      })}
      type="button"
      href="/"
      onClick={(e) => e.preventDefault()}
    >
      <p className="calendar-button__title">{title}</p>
      {isMonth && (
        <DatePicker
          locale="ru"
          className="calendar-button__date-input"
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            additionalClick();
          }}
          onFocus={(e) => (e.target.readOnly = true)}
          dateFormat="MM/yyyy"
          maxDate={new Date()}
          showMonthYearPicker
          shouldCloseOnSelect
          popperPlacement="top-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            offset: {
              enabled: true,
              offset: '0, 5px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              // boundariesElement: 'viewport',
            },
          }}
        />
      )}

      {isSimple && (
        <DatePicker
          locale="ru"
          className="calendar-button__date-input"
          selected={startDate}
          startDate={startDate}
          shouldCloseOnSelect
          maxDate={new Date()}
          onChange={(date) => {
            setStartDate(date);
            additionalClick();
          }}
          onFocus={(e) => (e.target.readOnly = true)}
          popperPlacement="top-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            offset: {
              enabled: true,
              offset: '0, 5px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              // boundariesElement: 'viewport',
            },
          }}
        />
      )}

      {isRange && (
        <DatePicker
          locale="ru"
          className="calendar-button__date-input"
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          shouldCloseOnSelect={false}
          maxDate={new Date()}
          onChange={(date) => {
            setStartDate(date[0]);
            setEndDate(date[1]);
            additionalClick();
          }}
          onFocus={(e) => (e.target.readOnly = true)}
          popperPlacement="top-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            offset: {
              enabled: true,
              offset: '0, 5px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              // boundariesElement: 'viewport',
            },
          }}
        />
      )}
    </a>
  );
};

export default CalendarButton;
