import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';

import StrategyCharacteristicItem from 'modules/main/components/StrategyCharacteristicItem/StrategyCharacteristicItem';
import { formatNumber } from 'helpers/index';

import rub from 'assets/images/icon-rub.svg';
import calendar from 'assets/images/icon-calendar.svg';
import detail from 'assets/images/icon-detail.svg';
import calc from 'assets/images/icon-calc.svg';
import bars from 'assets/images/icon-bars.svg';
import flag from 'assets/images/icon-flag.svg';
import search from 'assets/images/icon-search.svg';
import searchInList from 'assets/images/icon-search-in-list.svg';
import money from 'assets/images/icon-card-coins.svg';

import './StrategyCharacteristics.scss';

const StrategyCharacteristics = ({ list, className }) => {
  return (
    <div
      className={cx('strategy-characteristics', {
        [className]: className,
      })}
    >
      <p className="strategy-characteristics__title">Характеристики стратегии</p>
      <div className="strategy-characteristics__list">
        {/* МИНИМАЛЬНАЯ СУММА */}
        {list && list.minimuminvestment && (
          <StrategyCharacteristicItem
            icon={rub}
            className="strategy-characteristics__item"
            value={`${formatNumber(list.minimuminvestment)} ${
              list.marketname === 'SPBEX' ? '$' : '₽'
            }`}
            title="Минимальная сумма"
          />
        )}
        {/* /МИНИМАЛЬНАЯ СУММА */}

        {/* ПОТЕНЦИАЛЬНАЯ ДОХОДНОСТЬ */}
        {list && list.apr && (
          <StrategyCharacteristicItem
            icon={calc}
            className="strategy-characteristics__item"
            value={`до ${list.apr} % годовых ${
              list.marketname === 'ММВБ' ? 'в рублях' : 'в долларах США'
            }`}
            title="Потенциальная доходность"
          />
        )}
        {/* /ПОТЕНЦИАЛЬНАЯ ДОХОДНОСТЬ */}

        {/* СРОК ИНВЕСТИЦИЙ */}
        {list && list.content.investmentperiod && (
          <StrategyCharacteristicItem
            icon={calendar}
            className="strategy-characteristics__item"
            value={`${list.content.investmentperiod}`}
            title="Срок инвестиционных идей"
          />
        )}
        {/* /СРОК ИНВЕСТИЦИЙ */}

        {/* ИНСТРУМЕНТЫ */}
        {list && list.content && list.content.instrumenttypes && (
          <StrategyCharacteristicItem
            icon={detail}
            className="strategy-characteristics__item"
            value={list.content.instrumenttypes}
            isArr
            title="Инструменты"
          />
        )}
        {/* /ИНСТРУМЕНТЫ */}

        {/* КОНТРОЛЬ РИСКОВ */}
        {list && list.content && list.content.riskcontrol && (
          <StrategyCharacteristicItem
            icon={flag}
            className="strategy-characteristics__item"
            value={list.content.riskcontrol}
            title="Контроль рисков"
          />
        )}
        {/* /КОНТРОЛЬ РИСКОВ */}

        {/* ДИВЕРСИФИКАЦИЯ */}
        {list && list.content && list.content.diversification && (
          <StrategyCharacteristicItem
            icon={search}
            className="strategy-characteristics__item"
            value={list.content.diversification}
            title="Диверсификация"
          />
        )}
        {/* /ДИВЕРСИФИКАЦИЯ */}

        {/* ДОПУСТИМЫЕ ОПЕРАЦИИ */}
        {list && list.content && list.content.operations && (
          <StrategyCharacteristicItem
            icon={searchInList}
            className="strategy-characteristics__item"
            value={list.content.operations}
            title="Допустимые операции"
          />
        )}
        {/* /ДОПУСТИМЫЕ ОПЕРАЦИИ */}

        {/* МАКСИМАЛЬНАЯ ПРОСАДКА */}
        {list && list.drawdown && (
          <StrategyCharacteristicItem
            icon={bars}
            className="strategy-characteristics__item"
            value={`${Math.abs(list.drawdown)}%`}
            title="Максимальная просадка"
          />
        )}
        {/* /МАКСИМАЛЬНАЯ ПРОСАДКА */}

        {/* ЧАСТОТА СДЕЛОК */}
        {list && list.content && list.content.trades && (
          <StrategyCharacteristicItem
            icon={money}
            className="strategy-characteristics__item"
            value={list.content.trades}
            title="Частота сделок"
          />
        )}
        {/* /ЧАСТОТА СДЕЛОК */}

        {/* СТОИМОСТЬ АВТОСЛЕДОВАНИЯ */}
        {list && list.cost && (
          <StrategyCharacteristicItem
            icon={calc}
            className="strategy-characteristics__item"
            value={list.cost}
            title="Стоимость Автоследования"
          />
        )}
        {/* /СТОИМОСТЬ АВТОСЛЕДОВАНИЯ */}
      </div>
    </div>
  );
};

StrategyCharacteristics.propTypes = {
  // list: PropTypes
};

export default StrategyCharacteristics;
