import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { FAIL, LOADING, SUCCESS } from 'constants/index';

import './DeactivateStrategyPopup.scss';

const DeactivateStrategyPopup = ({ status, isWarning, isResult }) => {
  return (
    <div className="deactivate-strategy-popup">
      <h2
        className={cx('deactivate-strategy-popup__title', {
          'deactivate-strategy-popup__title_error': status === FAIL,
          'deactivate-strategy-popup__title_success': isResult && status === SUCCESS,
        })}
      >
        {isWarning ? 'Вы действительно хотите отключить стратегию?' : 'Отключение стратегии'}
      </h2>

      {status === LOADING && (
        <div className="deactivate-strategy-popup__loader">
          <CircleLoader />
        </div>
      )}
    </div>
  );
};

DeactivateStrategyPopup.propTypes = {
  status: PropTypes.string.isRequired,
  isWarning: PropTypes.bool.isRequired,
  isResult: PropTypes.bool.isRequired,
};

export default DeactivateStrategyPopup;
