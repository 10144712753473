import React, { useState } from 'react';
import cx from 'classnames';

import { ReactComponent as IconPlus } from 'assets/images/icon-plus.svg';
import { ReactComponent as IconMinus } from 'assets/images/icon-minus.svg';

import './FaqItem.scss';

const FaqItem = ({ question, answer }) => {
  const [isItemOpen, setIsItemOpen] = useState(false);

  return (
    <div
      className={cx('faq-item', {
        'faq-item_open': isItemOpen,
      })}
    >
      <button
        className="faq-item__summary"
        type="button"
        onClick={() => setIsItemOpen((prevState) => !prevState)}
      >
        <p className="faq-item__summary-text"> {question}</p>

        {isItemOpen ? (
          <IconMinus className="faq-item__summary-icon" />
        ) : (
          <IconPlus className="faq-item__summary-icon" />
        )}
      </button>
      {isItemOpen && (
        <div className="faq-item__body" dangerouslySetInnerHTML={{ __html: answer }} />
      )}
    </div>
  );
};

export default FaqItem;
