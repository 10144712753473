import React from 'react';
import PropTypes from 'prop-types';

// import ReturnOnMain from 'modules/shared/components/ReturnOnMain/ReturnOnMain';
import Breadcrumbs from 'modules/shared/components/Breadcrumbs/Breadcrumbs';
import AvatarUpload from 'modules/lk/components/AvatarUpload/AvatarUpload';
import { formatNumber } from 'helpers/index';

import './LkTop.scss';

const LkTop = ({ name, totalBalanceRub, totalBalanceUsd, userRisk }) => {
  return (
    <div className="lk-top">
      <div className="lk-top__container container">
        <Breadcrumbs link="/lk" anchor="Личный кабинет" className="lk-top__return" />
        {/*<ReturnOnMain className="lk-top__return" />*/}

        <div className="lk-top__main">
          <AvatarUpload className="lk-top__avatar" />

          <div className="lk-top__user-info">
            {name && <div className="lk-top__name">Привет, {name}!</div>}

            <div className="lk-top__balance-block-list">
              <div className="lk-top__balance-block-wrap">
                <p className="lk-top__balance-title">Текущий баланс:</p>

                <div className="lk-top__balance-block">
                  {totalBalanceRub ? (
                    <div className="lk-top__balance-item">
                      <div className="lk-top__balance-current">
                        {formatNumber(totalBalanceRub)} ₽
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {totalBalanceUsd ? (
                    <div className="lk-top__balance-item">
                      <div className="lk-top__balance-current">
                        {formatNumber(totalBalanceUsd)} $
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/*{totalDifference && (
                <div className="lk-top__balance-item">
                  <p className="lk-top__balance-item-title">За все время:</p>
                  <p
                    className={cx('lk-top__balance-difference', {
                      'lk-top__balance-difference_positive': Number(totalDifference) > 0,
                      'lk-top__balance-difference_negative': Number(totalDifference) < 0,
                    })}
                  >
                    {formatNumber(totalDifference)} ₽
                  </p>
                </div>
              )}*/}
                </div>
                {/* <div className="lk-top__balance-block">


              {totalDifference && (
                <div className="lk-top__balance-item">
                  <p className="lk-top__balance-item-title">За все время:</p>
                  <p
                    className={cx('lk-top__balance-difference', {
                      'lk-top__balance-difference_positive': Number(totalDifference) > 0,
                      'lk-top__balance-difference_negative': Number(totalDifference) < 0,
                    })}
                  >
                    {formatNumber(totalDifference)} ₽
                  </p>
                </div>
              )}
            </div>*/}
              </div>

              {userRisk ? (
                <div className="lk-top__balance-block-wrap">
                  <p className="lk-top__balance-title">Инвестиционный профиль:</p>

                  <div className="lk-top__balance-block">
                    <div className="lk-top__balance-current">{userRisk}</div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LkTop.propTypes = {
  name: PropTypes.string,
  totalBalanceRub: PropTypes.string,
  totalBalanceUsd: PropTypes.string,
  userRisk: PropTypes.string,
  // totalDifference: PropTypes.string,
};

export default LkTop;
