import React from 'react';

import FaqItem from 'modules/main/components/FaqItem/FaqItem';

import './Faq.scss';

const Faq = () => {
  const faq = [
    {
      title: 'Общие вопросы',
      content: [
        {
          question: 'Что такое «Автоследование»?',
          answer:
            '<b>Автоследование</b> — это сервис автоматической синхронизации позиций по счету клиента с выбранной им стратегией. Сделки по счету клиента повторяются без участия клиента. Сервис автоматически рассчитывает объем сделки в лотах, пропорционально сделке в рамках стратегии и формирует поручение на совершение сделки по подключенному счету клиента.',
        },
        {
          question: 'Что такое «тип инвестора»?',
          answer:
            '<b>Тип инвестора</b> определяется компанией АЛОР БРОКЕР на&nbsp;основании ответов клиента на&nbsp;вопросы анкеты. Цель определения типа инвестора&nbsp;— подобрать для клиента наиболее подходящие инструменты для инвестирования или торговли, а&nbsp;также снизить риски, связанные с&nbsp;неверным выбором таких инструментов.',
        },
        {
          question: 'Что такое торговый сигнал или индивидуальная инвестиционная рекомендация?',
          answer:
            'Торговый сигнал (индивидуальная инвестиционная рекомендация (ИИР))&nbsp;&mdash; это адресованная клиенту информация, которая отвечает следующим критериям:' +
            '<ul>' +
            '<li>информация касается совершения сделок по&nbsp;приобретению, отчуждению, погашению определенных ценных бумаг&nbsp;и (или) заключения определенных договоров, являющихся производными финансовыми инструментами;</li>' +
            '<li>информация содержит цену сделки с&nbsp;ценными бумагами&nbsp;и (или) цену договоров, являющихся производными финансовыми инструментами, и&nbsp;(или) количество финансового инструмента.</li>' +
            '</ul>',
        },
        {
          question: 'Стоимость услуги «Автоследование»',
          answer:
            'Ставки вознаграждения АЛОР БРОКЕР за оказание Клиенту услуги «Автоследование» устанавливаются рамках Категории Стратегии. Актуальные ставки вознаграждения по каждой Категории Стратегии указаны в Тарифах компании АЛОР БРОКЕР <a href="https://storage.alorbroker.ru/openinfo/Тарифы.pdf" target="_blank" rel="noreferrer noopener">https://storage.alorbroker.ru/openinfo/Тарифы.pdf</a>',
        },
      ],
    },

    {
      title: 'Использование сайта',
      content: [
        {
          question: 'Как посмотреть торговые сигналы?',
          answer:
            'История торговых сигналов доступна в личном кабинете сервиса в разделе «Отчет по ИИР».',
        },
        {
          question: 'Подключение счета к услуге «Автоследование»',
          answer:
            'Для подключения торгового счета к выбранной стратегии нужно:' +
            '<ul>' +
            '<li>Пройти авторизацию в сервисе, используя логин и пароль от Кабинета клиента <a href="https://lk.alor.ru" target="_blank" rel="noreferrer noopener">lk.alor.ru</a>. Если Вы еще не являетесь нашим клиентом, то открыть счет можно удаленно по <a href="//www.alorbroker.ru/open" target="_blank" rel="noreferrer noopener">по ссылке</a>.</li>' +
            '<li>Обеспечить на подключаемом счете сумму в рублях или иностранной валюте (или иные активы) не менее минимально рекомендуемой суммы в описании выбранной стратегии.</li>' +
            '<li>Зайти на страницу стратегии и нажать на “Подключить”. Далее система предложит пройти стандартные шаги по подключению счета.</li>' +
            '</ul>' +
            'На один брокерский счет можно подключить только одну стратегию.',
        },
        {
          question: 'Отключение счета от услуги «Автоследование»',
          answer:
            'Отключить счет от стратегии можно в любой момент в соответствующем разделе или на странице стратегии. При этом, после отключения счета от услуги «Автоследование» позиции по счету клиента не закрываются автоматически. Кроме того, Компания может отключить счет от услуги в случае отсутствия необходимого объема средств на счете клиента для проведения сделок в рамках стратегии и/или для списания комиссии за услугу.',
        },
        {
          question: 'Расхождения в фактической доходности ',
          answer:
            'При подключении к стратегии, необходимо учитывать ряд технических нюансов, которые могут оказывать существенное влияние на фактическую доходность по счету клиента в сравнении с доходностью стратегии. Перечислим наиболее распространенные причины.' +
            '<ul>' +
            '<li>Вводы и выводы средств. Могут оказывать как положительное, так и отрицательное влияние на доходность, особенно при длительном отсутствии синхронизации с подключенной стратегией.</li>' +
            '<li>Самостоятельная торговля по счету. Наличие параллельных сделок по счету со стороны клиента ожидаемо приведет к расхождению в доходностях.</li>' +
            '<li>Фаза стратегии. Например, подключение к популярной стратегии в момент ее достижения пиковых значений доходности и последующая коррекция на рынке может привести к получению временной просадки по счету. </li>' +
            '<li>Время подключения к стратегии. Необходимо учитывать тот факт, что доходность по стратегии за день может фиксироваться в строго определенное время, например, по ценам закрытия основной сессии, а подключение к стратегии и последующая синхронизация происходят внутри торговой сессии, т.е. будет иная точка отсчета доходности по каждому из инструментов, входящих в стратегию.</li>' +
            '<li>Исполнение сделок. Система автоматически исполняет сделки клиентов после получения информации о проведении сделки по счету управляющего стратегией. Однако, рынок динамичен и фактические цены исполнения по счетам клиентов могут отличаться от цен, по которым были открыты позиции по стратегии.</li>' +
            '<li>Комиссия. Различные тарифные планы по счету стратегии и подключенным к ней счетам могут приводить к расхождениям в доходностях. </li>' +
            '<li>Несоответствие пропорций. Например, объем активов по счету автора стратегии 1 млн рублей, а на подключенном к стратегии счете - 100 тыс. По счету стратегии покупается 1 лот акций ГМК НорНикель за 20 000 рублей. Это соответствует 2% активов по счету управляющего. 2% от 100 тыс рублей составят 2 тыс рублей, этой суммы не хватит на покупку акций ГМК НорНикель и сделка по счету подключенного клиента не пройдет. Соответственно, доходность от этой операции не будет учтена по подключенному счету.</li>' +
            '</ul>',
        },
        {
          question: 'Подключение услуги «Автоследование» в неторговое время',
          answer:
            'При подключении к стратегии в неторговое время синхронизация портфеля клиента будет выполнена в начале ближайшей торговой сессии.',
        },
      ],
    },

    {
      title: 'Синхронизация портфеля',
      content: [
        {
          question: 'Правила балансировки',
          answer:
            'При подключении счета к стратегии в торговое время происходит автоматическая синхронизация портфеля клиента со счетом управляющего стратегии. Синхронизация предполагает проведение автоматически сделок по счету клиента с целью привести его в максимальное соответствие со счетом управляющего стратегии. У клиента есть возможность в любой момент синхронизировать портфель, особенно актуально это при вводах и выводах средств по подключенному счету.',
        },
        {
          question: 'Синхронизация при пополнении счета',
          answer:
            'При пополнении подключенного к стратегии счета рекомендуется проводить синхронизацию счета для максимально точного следования стратегии.' +
            '<br><br>' +
            'При этом балансировка проводится вне зависимости от срока давности открытого трейда, текущего уровня доходности и текущей доли инструментов в портфеле клиента.',
        },
        {
          question: 'Синхронизация при частичном выводе средств со счета',
          answer:
            'Если после вывода части средств оценка ликвидационной стоимости счета превышает минимальную сумму, необходимую для подключения к стратегии, рекомендуется провести синхронизацию счета.\n' +
            '<br><br>' +
            'В случае, если оценка счета становится меньше минимальной суммы подключения к стратегии, Компания или автор стратегии имеют право отключить счет от стратегии в любой момент. В случае отключения счета от стратегии, позиции по счету автоматически не закрываются. Рекомендуем временно отключиться от стратегии или подключится на стратегию с меньшими требованиями к минимальной сумме. ',
        },
      ],
    },

    {
      title: 'Алгоритмы и параметры стратегий',
      content: [
        {
          question: 'Когда происходит синхронизация портфеля?',
          answer:
            'При подключении счета к услуге «Автоследование» происходит автоматическая синхронизация портфеля. Клиент может самостоятельно в любой момент запустить процесс синхронизации портфеля со стратегией, например, при пополнении счета.',
        },
        {
          question: 'Как считается размер заявки?',
          answer:
            'Размер заявки рассчитывается в процентах от ликвидной части портфеля, при этом, округление числа лотов происходит до целого числа в меньшую сторону.',
        },
        {
          question: 'Правила очередности выставления клиентских заявок',
          answer:
            'Все заявки по клиентским счетам в рамках автоматического исполнения торгового сигнала по стратегии отправляются на биржу в случайном порядке. При этом, выставляются лимитные заявки до определенного порогового значения. В случае, если заявки не исполнены в полном объеме, то оставшиеся неисполненные заявки снимаются и выставляются повторно на биржу с новыми параметрами.<br>' +
            'Заявки группируются по клиентам и сортируются по принципу “сначала заявки на продажу, затем заявки на покупку”.',
        },
      ],
    },
  ];

  return (
    <main className="faq">
      <div className="faq__container container">
        <article className="faq__container-inner">
          {faq.map((item) => (
            <section className="faq__list" key={item.title}>
              <h2 className="faq__list-heading">{item.title}</h2>
              <div className="faq__list-question-list">
                {item.content.map(({ question, answer }) => (
                  <FaqItem question={question} answer={answer} key={question} />
                ))}
              </div>
            </section>
          ))}
        </article>
      </div>
    </main>
  );
};

export default Faq;
