import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import TopBanner from 'modules/main/components/TopBanner/TopBanner';
import AboutHero from 'modules/main/components/AboutHero/AboutHero';
import Faq from 'modules/main/components/Faq/Faq';
import Advantages from 'modules/main/components/Advantages/Advantages';
import HowItWorks from 'modules/main/components/HowItWorks/HowItWorks';
import GoToStrategies from 'modules/main/components/GoToStrategies/GoToStrategies';

import './About.scss';

const About = () => {
  return (
    <div className="about">
      {/*<TopBanner isLoggedIn={isLoggedIn} />*/}
      <AboutHero />

      <HowItWorks />

      <Advantages />

      <Faq />

      <GoToStrategies isFooter />
    </div>
  );
};

About.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

About.defaultProps = {
  isLoggedIn: false,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.lk.isLoggedIn,
});

export default connect(mapStateToProps)(About);
