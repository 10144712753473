import { fetchWrapper, getCookie } from 'helpers';
import * as types from './action-types';

export const getClientInfo = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/accounts/${id}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GET_CLIENT_INFO_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_CLIENT_INFO_FAIL,
        });
        reject(err);
      });
  });
};

export const getClientStrategies = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/strategies`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GET_CLIENT_STRATEGIES_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_CLIENT_STRATEGIES_FAIL,
        });
        reject(err);
      });
  });
};

export const getAdvisorStrategies = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}autofollow/strategies`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GET_ADVISOR_STRATEGIES_LIST_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_ADVISOR_STRATEGIES_LIST_FAIL,
        });
        reject(err);
      });
  });
};

export const syncStrategy = (accountId) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/accounts/${accountId}/sync`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.SYNC_STRATEGY_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.SYNC_STRATEGY_FAIL,
        });
        reject(err);
      });
  });
};

export const closeAllPositions = (accountId) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/accounts/${accountId}/closeall`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.CLOSE_ALL_POSITIONS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.CLOSE_ALL_POSITIONS_FAIL,
        });
        reject(err);
      });
  });
};

/** FAKE */
// export const turnOffStrategy = (strategyId) => (dispatch) => {
//   // const fake = Math.random() < 0.5;
//   const fake = true;
//   const result = {
//     strategyId,
//   };
//   const err = {
//     error: 'Ошибка при turnOffStrategy',
//   };
//
//   return new Promise((resolve, reject) => {
//     if (fake) {
//       setTimeout(() => {
//         dispatch({
//           type: types.TURN_OFF_STRATEGY_SUCCESS,
//           payload: {
//             strategyId,
//           },
//         });
//         resolve(result);
//       }, 150);
//     } else {
//       setTimeout(() => {
//         dispatch({
//           type: types.TURN_OFF_STRATEGY_FAIL,
//         });
//         reject(err);
//       }, 0);
//     }
//   });
// };
/** FAKE */

export const turnOffStrategy = (strategyId) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/strategies/${strategyId}/stop`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.TURN_OFF_STRATEGY_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.TURN_OFF_STRATEGY_FAIL,
        });
        reject(err);
      });
  });
};

export const generateReport = (accountId, from, to) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/accounts/${accountId}/orders?from=${from}&to=${to}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GENERATE_REPORT_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GENERATE_REPORT_FAIL,
        });
        reject(err);
      });
  });
};

export const getDealsForReport = (accountId, from, to) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/accounts/${accountId}/trades?from=${from}&to=${to}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GET_DEALS_FOR_REPORT_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_DEALS_FOR_REPORT_FAIL,
        });
        reject(err);
      });
  });
};

export const getClientDetailsByUserName = (name) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}client/users?username=${name}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: process.env.REACT_APP_ROOT_KEY,
    })
      .then((res) => {
        dispatch({
          type: types.GET_CLIENT_DETAILS_BY_USERNAME_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_CLIENT_DETAILS_BY_USERNAME_FAIL,
        });
        reject(err);
      });
  });
};

export const setIsDataForIrrPopup = (isDataForIrrPopup) => ({
  type: types.SET_DATA_FOR_IIR_POPUP,
  payload: {
    isDataForIrrPopup,
  },
});

export const getAccountChart = (accountId) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}cloud/accounts/${accountId}/snapshots`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GET_ACCOUNT_CHART_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_ACCOUNT_CHART_FAIL,
        });
        reject(err);
      });
  });
};

export const getListOfAdminInstruments = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}marketdata/symbols?marketid=1`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
    })
      .then((res) => {
        dispatch({
          type: types.GET_LIST_OF_ADMIN_INSTRUMENTS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_LIST_OF_ADMIN_INSTRUMENTS_FAIL,
        });
        reject(err);
      });
  });
};

export const savePortfolioFeaturesListChanges = (strategyid, positions) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}autofollow/strategies/${strategyid}/positions`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
      body: JSON.stringify({
        positions: positions,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.SAVE_PORTFOLIO_FEATURES_LIST_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.SAVE_PORTFOLIO_FEATURES_LIST_FAIL,
        });
        reject(err);
      });
  });
};

export const setIsUserLoggedIn = (isLoggedIn) => ({
  type: types.SET_IS_USER_LOGGED_IN,
  payload: {
    isLoggedIn,
  },
});

export const changeComment = (strategyId, content, commentid) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}autofollow/comments/${commentid}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
      body: JSON.stringify({
        comment: { strategyid: strategyId, content: content },
      }),
    })
      .then((res) => {
        dispatch({
          type: types.SAVE_STRATEGY_COMMENT_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.SAVE_STRATEGY_COMMENT_FAIL,
        });
        reject(err);
      });
  });
};

export const saveComment = (strategyId, content) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}autofollow/comments`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      key: getCookie(getCookie('alor_user_name')),
      // key: '838C2CA004AA4CD9AEFF22A535E664E1',
      body: JSON.stringify({
        comment: { strategyid: strategyId, content: content },
      }),
    })
      .then((res) => {
        dispatch({
          type: types.SAVE_STRATEGY_COMMENT_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.SAVE_STRATEGY_COMMENT_FAIL,
        });
        reject(err);
      });
  });
};
