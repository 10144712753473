import React from 'react';
import AriaModal from 'react-aria-modal';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ym from 'react-yandex-metrika';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { setIsMenuOpen } from 'modules/main/actions';

import { YELLOW, YELLOW_BORDER, NAVIGATION } from 'constants/index';

import './MobileMenu.scss';

const MobileMenu = ({ className, closeMenu, mounted, isLoggedIn }) => {
  const dispatch = useDispatch();
  return (
    <AriaModal
      underlayClass={cx('mobile-menu', {
        [className]: className,
      })}
      onExit={closeMenu}
      mounted={mounted}
      titleText=" "
      underlayColor="white"
      focusTrapPaused
    >
      <div className="mobile-menu__inner">
        <nav className="mobile-menu__nav" role="navigation">
          <ul className="mobile-menu__nav-list">
            {NAVIGATION.map(({ title, href }) => (
              <li className="mobile-menu__nav-list-item-wrap" key={title}>
                <Link
                  to={href}
                  onClick={() => dispatch(setIsMenuOpen(false))}
                  className="mobile-menu__nav-list-item"
                >
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {!isLoggedIn && (
          <div className="mobile-menu__buttons">
            <StandardButton
              title="Открыть счет"
              isLink
              isOutAppLink
              href="https://www.alorbroker.ru/open"
              bgType={YELLOW}
              className="mobile-menu__buttons-item"
              onClick={() => ym('reachGoal', 'openaccount')}
            />
            <StandardButton
              title="Личный кабинет"
              isLink
              isOutAppLink
              href={process.env.REACT_APP_SSO_AUTH_URL}
              bgType={YELLOW_BORDER}
              className="mobile-menu__buttons-item"
            />
          </div>
        )}
      </div>
    </AriaModal>
  );
};

MobileMenu.propTypes = {
  className: PropTypes.string,
  closeMenu: PropTypes.func.isRequired,
  mounted: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

// MobileMenu.defaultProps = {};

export default MobileMenu;
