import React from 'react';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import { ReactComponent as IconPortfolio } from 'assets/images/partners-portfolio-img.svg';
import { YELLOW } from 'constants/index';

import './PartnerCta.scss';

const PartnerCta = () => {
  return (
    <div className="partner-cta">
      <div className="partner-cta__content">
        <h2 className="partner-cta__title">Стать партнером</h2>
        <div className="partner-cta__desc">
          <p className="partner-cta__desc-item">
            Если Вы профессиональный трейдер и ваша стратегия эффективна и приносит стабильный доход
            , Вы можете поделиться данным решением с пользователями платформы FOLLOW ME и получать
            дополнительное вознаграждение.
          </p>
          <p className="partner-cta__desc-item">
            Всё, что потребуется – это подтвердить ваш опыт и результаты работы стратегии.
          </p>
        </div>

        <div className="partner-cta__button-wrap">
          <StandardButton
            title="Оставить заявку"
            isLink={true}
            href="/become-a-partner"
            bgType={YELLOW}
          />
        </div>
      </div>

      <IconPortfolio className="partner-cta__img" />
    </div>
  );
};
export default PartnerCta;
