import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './TextBLock.scss';

const TextBLock = ({ title, text, img, flipped, footer }) => {
  return (
    <div
      className={cx('text-block', {
        'text-block_flipped': flipped,
      })}
    >
      <div className="text-block__col text-block__col_text">
        <h3 className="text-block__title">{title}</h3>
        <div className="text-block__wrap">
          <div className="text-block__body" dangerouslySetInnerHTML={{ __html: text }} />
          {footer && (
            <div className="text-block__footer" dangerouslySetInnerHTML={{ __html: footer }} />
          )}
        </div>
      </div>
      <div className="text-block__col text-block__col_img">
        <img src={img} alt="" className="text-block__img" />
      </div>
    </div>
  );
};

TextBLock.defaultProps = {
  flipped: false,
  footer: '',
};

TextBLock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  flipped: PropTypes.bool,
  footer: PropTypes.string,
};

export default TextBLock;
