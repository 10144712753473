import { MONTH_LIST, WEEK_DAY_LIST } from 'constants/index';

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const removeCookie = (name) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const defineGetParameter = () => {
  let getParameter = {};
  if (document.location.toString().indexOf('?') !== -1) {
    const query = document.location
      .toString()
      // get the query string
      .replace(/^.*?\?/, '')
      // and remove any existing hash string (thanks, @vrijdenker)
      .replace(/#.*$/, '')
      .split('&');

    for (let i = 0, l = query.length; i < l; i++) {
      const aux = decodeURIComponent(query[i]).split('=');
      getParameter[aux[0]] = aux[1];
    }
  }

  return getParameter;
};

export const fetchWrapper = (
  url,
  {
    method = 'GET',
    lang = null,
    body = null,
    multipart = false,
    formData = false,
    credentials = 'omit',
    isBlob = false,
    mode = false,
    key = false,
    alorAuth = false,
  } = {},
) => {
  if (!url) {
    throw new Error('Url should be specified');
  }

  const headers = {
    Accept: '*/*',
    // 'Cache-Control': 'no-cache',
    // 'X-API-KEY': '67CDF9E22568488587197F4F862ED860',
    // 'X-API-KEY': '2B71343890FA4D9695F198EAA35F1DBD',
  };

  if (key) {
    headers['X-API-KEY'] = key;
  }

  if (!multipart) {
    headers['Content-Type'] = 'application/json; charset=utf-8';
  }

  if (lang) {
    headers['Accept-Language'] = lang;
  }

  if (alorAuth) {
    headers['Authorization'] = alorAuth;
  }

  const options = {
    method,
    headers,
    credentials,
  };

  if (body) {
    options.body = body;
  }

  if (formData) {
    options.body = formData;
  }

  if (mode) {
    options.mode = mode;
  }

  if (!isBlob) {
    return fetch(url, options).then(handleFetchErrors).then(handleJSON);
  }

  return fetch(url, options).then(handleFetchBlob).then(handleBlob);
};

export const handleFetchErrors = async (res) =>
  Promise.resolve({
    ok: res.ok,
    statusText: res.statusText,
    status: res.status,
    response: await res.text(),
  });

export const handleFetchBlob = async (res) =>
  Promise.resolve({
    ok: res.ok,
    statusText: res.statusText,
    status: res.status,
    response: await res.blob(),
  });

export const handleBlob = (res) => {
  if (res.ok) {
    return res.response;
  }

  let toThrow = null;
  try {
    toThrow = JSON.parse(res.response);
  } catch (e) {
    toThrow = Error(res.statusText || res.status);
  }
  throw toThrow;
};

export const handleJSON = (res) => {
  if (res.ok) {
    try {
      return JSON.parse(res.response);
    } catch (e) {
      return res.response;
    }
  } else {
    let toThrow = null;
    try {
      toThrow = JSON.parse(res.response);
    } catch (e) {
      toThrow = Error(res.statusText || res.status);
    }
    throw toThrow;
  }
};

export const validateEmail = (string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(string).toLowerCase());
};

export const normalizePhone = (input) => {
  return input.replace(/[^0-9]/g, '');
};

export default (num) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

export const generatePassword = () => {
  const length = 12;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const formatDateMMDDYYYY = (string) => {
  let dd = string.slice(0, 2);
  let mm = string.slice(3, 5);
  let yy = string.slice(6, 10);
  let time = string.slice(11);

  return new Date(`${mm}/${dd}/${yy} ${time}`);
};

export const formatDateMMDDYYYYFromAccountData = (string) => {
  const yy = string.slice(0, 4);
  const mm = string.slice(5, 7);
  const dd = string.slice(8, 10);
  const time = string.slice(11);

  return new Date(`${mm}/${dd}/${yy} ${time}`);
};

export const formatDateYYYYDDMM = (date) => {
  if (date && date instanceof Date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yy = date.getFullYear();

    if (dd < 10) {
      dd = `0${dd}`;
    }

    if (mm < 10) {
      mm = `0${mm}`;
    }

    return `${yy}-${mm}-${dd}`;
  }

  return false;
};

export const formatDateMMDDYYYYFromDate = (date) => {
  if (date && date instanceof Date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yy = date.getFullYear();

    if (dd < 10) {
      dd = `0${dd}`;
    }

    if (mm < 10) {
      mm = `0${mm}`;
    }

    return `${dd}.${mm}.${yy}`;
  }

  return false;
};

export const formatDateMMDDYYFromDate = (date) => {
  if (date && date instanceof Date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yy = date.getFullYear().toString().slice(2);

    if (dd < 10) {
      dd = `0${dd}`;
    }

    if (mm < 10) {
      mm = `0${mm}`;
    }

    return `${dd}.${mm}.${yy}`;
  }

  return false;
};

export const formatNumber = (num) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

// Склонение слов в зависимости от числительных
export const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

export const dateDiffInDays = (date1, date2) => {
  return Math.floor(
    (Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) -
      Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())) /
      (1000 * 60 * 60 * 24),
  );
};

export const riskDefiner = (amount) => {
  if (Number(amount) >= 2 && Number(amount) <= 4) {
    return 'Сверхконсервативный';
  }
  if (Number(amount) >= 5 && Number(amount) <= 6) {
    return 'Консервативный';
  }
  if (Number(amount) >= 7 && Number(amount) <= 8) {
    return 'Рациональный';
  }
  if (Number(amount) >= 9 && Number(amount) <= 14) {
    return 'Опытный';
  }
  if (Number(amount) >= 15 && Number(amount) <= 25) {
    return 'Продвинутый';
  }
};

export const chartHintFormatDate = (date) => {
  if (date && date instanceof Date) {
    let dd = date.getDate();
    let mm = date.getMonth();
    const yy = date.getFullYear();
    const weekDay = date.getDay();

    if (dd < 10) {
      dd = `0${dd}`;
    }

    return `${WEEK_DAY_LIST[weekDay]}, ${dd} ${MONTH_LIST[mm]} ${yy}`;
  }

  return false;
};

export const arrayFilterByCount = (arr, count) => {
  if (arr && count) {
    const length = arr.length;
    const mod = length / count;
    const result = [];
    let i = 0;

    while (i < length) {
      result.push(arr[Math.floor(i)]);
      i += mod;
    }

    return result;
  }
};
