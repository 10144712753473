import React from 'react';
import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { ReactComponent as IconSuccess } from 'assets/images/check-circle-green.svg';

import { FAIL, SUCCESS, YELLOW } from 'constants/index';

import './ActivateStrategyResult.scss';

const ActivateStrategyResult = ({ closePopup, status, isDeactivation }) => {
  return (
    <div className="activate-strategy-result">
      {status === SUCCESS && (
        <>
          <div className="activate-strategy-result__success">
            <IconSuccess className="activate-strategy-result__success-icon" />
          </div>
          <div className="activate-strategy-result__btn-wrap">
            <StandardButton
              className="activate-strategy-result__btn"
              isLink={false}
              onClick={closePopup}
              title="Продолжить"
              bgType={YELLOW}
            />
          </div>
        </>
      )}

      {status === FAIL && (
        <>
          <div className="activate-strategy-result__msg">
            <p>
              К сожалению, что-то пошло не так и у нас не получилось{' '}
              {isDeactivation ? 'отключить' : 'подключить'} стратегию. Попробуйте повторить позже.
            </p>
          </div>

          <div className="activate-strategy-result__btn-wrap">
            <StandardButton
              className="activate-strategy-result__btn"
              isLink={false}
              onClick={closePopup}
              title="Закрыть"
              bgType={YELLOW}
            />
          </div>
        </>
      )}
    </div>
  );
};

ActivateStrategyResult.defaultProps = {
  isDeactivation: false,
};

ActivateStrategyResult.propTypes = {
  closePopup: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  isDeactivation: PropTypes.bool,
  // errorMessage: PropTypes.string.isRequired,
};

export default ActivateStrategyResult;
