import React from 'react';
import PropTypes from 'prop-types';

import SmsForm from 'modules/main/components/SmsForm/SmsForm';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import SmsValidations from 'modules/main/components/SmsValidations/SmsValidations';

import { FAIL, SUCCESS, YELLOW } from 'constants/index';

import './SmsContent.scss';

const SmsContent = ({
  status,
  closeModal,
  operationCreateHandler,
  code,
  onChangeCode,
  isCodeValid,
  operationSignHandler,
  seconds,
  isTimeEnded,
  isDeactivation,
  validations,
  operationDocuments,
}) => {
  return (
    <div className="sms-content">
      {status === SUCCESS && (
        <div className="sms-content__form">
          <SmsForm
            closeModal={closeModal}
            operationCreateHandler={operationCreateHandler}
            code={code}
            onChangeCode={onChangeCode}
            isCodeValid={isCodeValid}
            operationSignHandler={operationSignHandler}
            seconds={seconds}
            isTimeEnded={isTimeEnded}
            operationDocuments={operationDocuments}
          />
        </div>
      )}
      {status === FAIL && (
        <>
          {validations.length === 0 && (
            <div className="sms-content__msg">
              <p>
                К сожалению, что-то пошло не так и у нас не получилось{' '}
                {isDeactivation ? 'отключить' : 'подключить'} стратегию. Попробуйте повторить позже.
              </p>
            </div>
          )}

          {validations.length > 0 && (
            <SmsValidations list={validations} isDeactivation={isDeactivation} />
          )}
          <div className="sms-content__btn-wrap">
            <StandardButton
              className="sms-content__btn"
              isLink={false}
              onClick={closeModal}
              title="ОК"
              bgType={YELLOW}
            />
          </div>
        </>
      )}
    </div>
  );
};

SmsContent.defaultProps = {
  isDeactivation: false,
  operationDocuments: [],
};

SmsContent.propTypes = {
  status: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  operationCreateHandler: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  onChangeCode: PropTypes.func.isRequired,
  isCodeValid: PropTypes.bool.isRequired,
  operationSignHandler: PropTypes.func.isRequired,
  seconds: PropTypes.number.isRequired,
  isTimeEnded: PropTypes.bool.isRequired,
  isDeactivation: PropTypes.bool,
  validations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  operationDocuments: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SmsContent;
