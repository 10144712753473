import React from 'react';

import Breadcrumbs from 'modules/shared/components/Breadcrumbs/Breadcrumbs';
import YandexMap from 'modules/main/components/YandexMap/YandexMap';

import './Contacts.scss';

const Contacts = () => {
  const phoneNumber = '8 (800) 775-11-99';

  return (
    <main className="contacts">
      <article className="contacts__container container">
        <Breadcrumbs
          link={window.location.pathname}
          anchor="Контакты"
          className="contacts__breadcrumbs"
        />

        <h1 className="contacts__title">Контакты</h1>

        <div className="contacts__main">
          <div className="contacts__info-wrap">
            <section className="contacts__item">
              <h2 className="contacts__item-heading">У вас есть вопрос?</h2>
              <div className="contacts__content">
                <div className="contacts__content-item">
                  <p className="contacts__content-key">Единая справочная служба:</p>
                  <p className="contacts__content-value">
                    <a className="contacts__content-value-link" href={`tel:${phoneNumber}`}>
                      {phoneNumber}
                    </a>
                  </p>
                </div>
                <div className="contacts__content-item">
                  <p className="contacts__content-key">Электронная почта:</p>
                  <p className="contacts__content-value">
                    <a
                      className="contacts__content-value-link contacts__content-value-link_blue"
                      href="mailto:info@alor.ru"
                    >
                      info@alor.ru
                    </a>
                  </p>
                </div>
              </div>
            </section>

            <section className="contacts__item">
              <h2 className="contacts__item-heading">Информация об услугах и офисах</h2>
              <div className="contacts__content">
                <div className="contacts__content-item">
                  <p className="contacts__content-key">Телефон:</p>
                  <p className="contacts__content-value">
                    <a href={`tel:${phoneNumber}`} className="contacts__content-value-link">
                      {phoneNumber}
                    </a>
                  </p>
                </div>
                <div className="contacts__content-item">
                  <p className="contacts__content-key">Сайт:</p>
                  <p className="contacts__content-value">
                    <a
                      href="//alorbroker.ru"
                      target={'_blank'}
                      rel="noopener noreferrer"
                      className="contacts__content-value-link contacts__content-value-link_blue"
                    >
                      alorbroker.ru
                    </a>
                  </p>
                </div>
                <div className="contacts__content-item">
                  <p className="contacts__content-key">Адрес местонахождения:</p>
                  <p className="contacts__content-value">
                    115162, г. Москва, ул. Шаболовка, д. 31, стр. Б, подъезд 2, этаж 2
                  </p>
                </div>
                <div className="contacts__content-item">
                  <p className="contacts__content-key">Почтовый адрес:</p>
                  <p className="contacts__content-value">
                    115162, г. Москва, ул. Шаболовка, д. 31 стр. Б
                  </p>
                </div>
              </div>
            </section>

            <section className="contacts__item">
              <h2 className="contacts__item-heading">Информация о Компании</h2>
              <p className="contacts__info">
                Название организации: ООО «АЛОР +»
                <br />
                Полное название организации: Общество с ограниченной ответственностью «АЛОР +»
              </p>
            </section>
          </div>

          <div className="contacts__map">
            <YandexMap center={[55.72067826574474, 37.61446857569946]} />
          </div>
        </div>
      </article>
    </main>
  );
};
export default Contacts;
