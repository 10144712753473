import React from 'react';
// import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { YELLOW_BORDER, YELLOW } from 'constants/index';

import './ActivateStrategyAuthPopup.scss';

const ActivateStrategyAuthPopup = () => {
  return (
    <div className="activate-strategy-auth-popup">
      <h2 className="activate-strategy-auth-popup__title">Подключение стратегии</h2>
      <div className="activate-strategy-auth-popup__info">
        Для подключения стратегии вам нужно авторизоваться или открыть счет
      </div>
      <div className="activate-strategy-auth-popup__footer">
        <StandardButton
          isOutAppLink
          isLink
          title="Авторизоваться"
          href={process.env.REACT_APP_SSO_AUTH_URL}
          bgType={YELLOW_BORDER}
          target="_blank"
          rel="noopener noreferrer"
          className="activate-strategy-auth-popup__btn"
        />
        <StandardButton
          title="Открыть счет"
          isLink
          isOutAppLink
          href="https://www.alorbroker.ru/open"
          bgType={YELLOW}
          className="activate-strategy-auth-popup__btn"
        />
      </div>
    </div>
  );
};

// ActivateStrategyAuthPopup.propTypes = {};

export default ActivateStrategyAuthPopup;
