import React from 'react';
import PropTypes from 'prop-types';

import './IirReportTable.scss';

const IirReportTable = ({ table, isDeals, deals }) => {
  return (
    <div className="iir-report-table">
      <div className="iir-report-table__scroll-x-wrap">
        <div className="iir-report-table__body">
          {table.length > 0 &&
            table.map((account) => (
              <React.Fragment key={`${account.id}`}>
                {account.list.length > 0 && (
                  <div className="iir-report-table__account">
                    <p className="iir-report-table__account-title">Счет {account.id}</p>

                    <div className="iir-report-table__head">
                      <div className="iir-report-table__col iir-report-table__col_date">
                        Дата/время
                      </div>
                      <div className="iir-report-table__col">Инструмент</div>
                      <div className="iir-report-table__col">Направление</div>
                      <div className="iir-report-table__col">Кол-во</div>
                      <div className="iir-report-table__col">Счет</div>
                      <div className="iir-report-table__col">Цена</div>
                    </div>

                    {isDeals && (
                      <div className="iir-report-table__list iir-report-table__list_deals">
                        {account.list.map((item, i) => (
                          <React.Fragment key={i}>
                            {deals &&
                              deals.length &&
                              deals.filter((dealBlock) => dealBlock.id === account.id) &&
                              deals.filter((dealBlock) => dealBlock.id === account.id)[0] &&
                              deals.filter((dealBlock) => dealBlock.id === account.id)[0].list &&
                              deals.filter((dealBlock) => dealBlock.id === account.id)[0].list
                                .length &&
                              deals.filter((dealBlock) => dealBlock.id === account.id)[0].list
                                .length && (
                                <div className="iir-report-table__list-item">
                                  {deals.filter((dealBlock) => dealBlock.id === account.id) &&
                                    deals.filter((dealBlock) => dealBlock.id === account.id)[0] &&
                                    deals.filter((dealBlock) => dealBlock.id === account.id)[0]
                                      .list &&
                                    deals.filter((dealBlock) => dealBlock.id === account.id)[0].list
                                      .length &&
                                    deals
                                      .filter((dealBlock) => dealBlock.id === account.id)[0]
                                      .list.filter((deal) => deal.orderid === item.orderid)
                                      .map((deal) => (
                                        <div
                                          className="iir-report-table__list-item-inner"
                                          key={deal.orderid}
                                        >
                                          <div className="iir-report-table__col iir-report-table__col_date">
                                            {deal.timestamp}
                                          </div>
                                          <div className="iir-report-table__col">{deal.symbol}</div>
                                          <div className="iir-report-table__col">
                                            {deal.buy === '1' ? 'Покупка' : 'Продажа'}
                                          </div>
                                          <div className="iir-report-table__col">{deal.shares}</div>
                                          <div className="iir-report-table__col">
                                            {deal.account}
                                          </div>
                                          <div className="iir-report-table__col">
                                            {Math.floor(deal.price * 100) / 100}&nbsp;
                                            {/USD/g.test(item.account) ? '$' : '₽'}
                                          </div>
                                        </div>
                                      ))}
                                </div>
                              )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}

                    {!isDeals && (
                      <div className="iir-report-table__list">
                        {account.list.map((item) => (
                          <div key={item.orderid} className="iir-report-table__list-item">
                            <div className="iir-report-table__list-item-inner">
                              <div className="iir-report-table__col iir-report-table__col_date">
                                {item.timestamp}
                              </div>
                              <div className="iir-report-table__col">{item.symbol}</div>
                              <div className="iir-report-table__col">
                                {item.buy === '1' ? 'Покупка' : 'Продажа'}
                              </div>
                              <div className="iir-report-table__col">{item.shares}</div>
                              <div className="iir-report-table__col">{item.account}</div>
                              <div className="iir-report-table__col">
                                {Math.floor(item.price * 100) / 100}&nbsp;
                                {/USD/g.test(item.account) ? '$' : '₽'}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {account.list.length === 0 && (
                  <div className="iir-report-table__msg">
                    У вас пока нет сделок, которые мы можем отобразить.
                  </div>
                )}
              </React.Fragment>
            ))}

          {table.length === 0 && (
            <div className="iir-report-table__msg">
              У вас пока нет сделок, которые мы можем отобразить.
            </div>
          )}
          <div className="iir-report-table__risk-text">
            <p>
              Клиенту следует ознакомиться с предупреждением о рисках, связанных с проведением
              операций на рынке ценных бумаг и срочном рынке (Приложение 5 к Регламенту брокерского
              обслуживания Компании, размещенного на сайте Брокера. Особенно Клиенту стоит оценить
              свои силы и знания при совершении сделок с финансовыми инструментами предназначенными
              для квалифицированных инвесторов. Следует учитывать, что финансовые инструменты,
              предназначенные для квалифицированных инвесторов, несут в себе повышенные риски
              инвестирования и подходят не для каждого лица, являющего квалифицированным инвестором.
            </p>

            <p>
              Также следует учитывать, что требования по сделкам с производными финансовыми
              инструментами, предусматривающим обязанность стороны или сторон сделки уплачивать
              денежные суммы в зависимости от изменения цен на товары, ценные бумаги, курса
              соответствующей валюты, величины процентных ставок, уровня инфляции или от значений,
              рассчитываемых на основании совокупности указанных показателей, либо от наступления
              иного обстоятельства, которое предусмотрено законом и относительно которого
              неизвестно, наступит оно или не наступит, не подлежат судебной защите, за исключением
              требований по указанным сделкам, если хотя бы одной из сторон сделки является
              юридическое лицо, получившее лицензию на осуществление банковских операций или
              лицензию на осуществление профессиональной деятельности на рынке ценных бумаг, либо
              хотя бы одной из сторон сделки, заключенной на бирже, является юридическое лицо,
              получившее лицензию, на основании которой возможно заключение сделок на бирже;
              требований, связанные с участием граждан в указанных сделках, при условии их
              заключения на бирже, а также в иных случаях, предусмотренных законом.
            </p>
            <p>Конфликт интересов.</p>
            <p>
              Возможно возникновение конфликта интересов в случае предоставления индивидуальной
              инвестиционной рекомендации, содержащей описание ценных бумаг, сделок с ними, если
              Инвестиционный советник владеет такими же ценными бумагами или намерен совершить с
              ними сделку. С полным перечнем возможных конфликтов интересов можно ознакомиться на
              сайте Брокера.
            </p>
            <p>
              Брокерские услуги предоставляются ООО «АЛОР +». Лицензия ФСФР на осуществление
              брокерской деятельности № 077-04827-100000 от 13.03.2001 г. Депозитарные услуги
              предоставляются ООО «АЛОР +». Лицензия ФСФР на осуществление депозитарной деятельности
              № 077-10965-000100 от 22.01.2008 г. ООО «АЛОР +». ООО «АЛОР +», ИНН 7709221010/ ОГРН
              1027700075941 Юридический адрес: 115162, Москва, ул. Шаболовка, д. 31, стр. Б.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

IirReportTable.defaultTypes = {
  table: [],
  deals: [],
};

IirReportTable.propTypes = {
  isDeals: PropTypes.bool.isRequired,
  table: PropTypes.arrayOf(PropTypes.shape({})),
  deals: PropTypes.arrayOf(PropTypes.shape({})),
};

export default IirReportTable;
