import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import { FAIL, LOADING, SUCCESS } from 'constants/index';

import './ActivateStrategyPopup.scss';

const ActivateStrategyPopup = ({ status, isResult }) => {
  return (
    <div className="activate-strategy-popup">
      <h2
        className={cx('activate-strategy-popup__title', {
          'activate-strategy-popup__title_error': status === FAIL,
          'activate-strategy-popup__title_success': isResult && status === SUCCESS,
        })}
      >
        Подключение стратегии
      </h2>

      {status === LOADING && (
        <div className="activate-strategy-popup__loader">
          <CircleLoader />
        </div>
      )}
    </div>
  );
};

ActivateStrategyPopup.propTypes = {
  status: PropTypes.string.isRequired,
  isResult: PropTypes.bool.isRequired,
};

export default ActivateStrategyPopup;
