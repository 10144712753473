import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Breadcrumbs from 'modules/shared/components/Breadcrumbs/Breadcrumbs';
import StrategiesList from 'modules/main/components/StrategiesList/StrategiesList';
import UserForm from 'modules/main/components/UserForm/UserForm';

import { getStrategies } from 'modules/main/actions';

import { LOADING, FAIL, NO_STRATEGIES, SUCCESS } from 'constants/index';

import './StrategiesPage.scss';

const StrategiesPage = () => {
  const dispatch = useDispatch();
  const [strategiesStatus, setStrategiesStatus] = useState('');
  const [strategies, setStrategies] = useState(null);

  /** Запрашиваем данные по стратегиям */
  useEffect(() => {
    setStrategiesStatus(LOADING);
    dispatch(getStrategies())
      .then((res) => {
        if (res) {
          setStrategies(
            res.map((el) => {
              if (el.strategyid === '991') {
                return {
                  ...el,
                  userid: '12815',
                };
              }

              if (el.strategyid === '997') {
                return {
                  ...el,
                  userid: '12815',
                };
              }
              return el;
            }),
          );
          setStrategiesStatus(SUCCESS);
        } else {
          setStrategiesStatus(NO_STRATEGIES);
        }
      })
      .catch(() => {
        setStrategiesStatus(FAIL);
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div className="strategies-page">
      <div className="strategies-page__container container">
        <Breadcrumbs
          link={window.location.pathname}
          anchor={'Стратегии'}
          className="strategies-page__breadcrumbs"
        />
      </div>

      <StrategiesList
        isPage
        strategies={strategies}
        status={strategiesStatus}
        className="strategies-page__list"
      />

      <div className="strategies-page__form-wrap">
        <UserForm
          className="managers__form"
          title="Получить консультацию"
          description="Оставьте контактную информацию, наши специалисты свяжутся с Вами в ближайшее время"
        />
      </div>
    </div>
  );
};

// StrategiesPage.defaultProps = {
//   className: null,
// };
// StrategiesPage.propTypes = {
//   className: PropTypes.string,
// };

export default StrategiesPage;
