import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import { BLUE, BLUE_BORDER, LOADING, SUCCESS, FAIL } from 'constants/index';
import { getComment } from 'modules/main/actions';
import { saveComment, changeComment } from 'modules/lk/actions';

import './LkCommentsPopup.scss';

const LkCommentsPopup = ({ id, closePopup, dispatch }) => {
  const [comment, setComment] = useState('');
  const [commentId, setCommentId] = useState('');
  const [isCommentExist, setIsCommentExist] = useState(false);
  const [isChangeOfCommentsStared, setIsChangeOfCommentsStared] = useState(false);
  const [isInfoAboutCommentReady, setIsInfoAboutCommentReady] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);

  useEffect(() => {
    setIsInfoAboutCommentReady(false);
    dispatch(getComment(id))
      .then((res) => {
        if (res && Array.isArray(res) && res.length) {
          setComment(res[0].content);
          setCommentId(res[0].commentid);
          setIsCommentExist(true);
        } else {
          setComment('');
          setIsCommentExist(false);
        }
        setIsInfoAboutCommentReady(true);
      })
      .catch(() => {});
  }, [id, dispatch]);

  const saveChanges = () => {
    setSaveStatus(LOADING);
    if (isCommentExist) {
      dispatch(changeComment(id, comment, commentId))
          .then(() => {
            setSaveStatus(SUCCESS);
            setIsChangeOfCommentsStared(false);
            setTimeout(() => {
              setSaveStatus(null);
            }, 5000);
          })
          .catch(() => {
            setSaveStatus(FAIL);
            setTimeout(() => {
              setSaveStatus(null);
            }, 5000);
          });
    } else {
      dispatch(saveComment(id, comment))
          .then(() => {
            setSaveStatus(SUCCESS);
            setIsChangeOfCommentsStared(false);
            setTimeout(() => {
              setSaveStatus(null);
            }, 5000);
          })
          .catch(() => {
            setSaveStatus(FAIL);
            setTimeout(() => {
              setSaveStatus(null);
            }, 5000);
          });
    }

  };

  return (
    <div className="lk-comment-popup">
      <h2 className="lk-comment-popup__title">Добавьте комментарий</h2>

      <div className="lk-comment-popup__textarea-holder">
        <textarea
          cols="30"
          rows="10"
          className="lk-comment-popup__textarea"
          value={comment}
          disabled={saveStatus === LOADING || !isInfoAboutCommentReady}
          onChange={(event) => {
            setComment(event.target.value);
            setIsChangeOfCommentsStared(true);
          }}
        />
      </div>

      <div className="lk-comment-popup__buttons-list">
        <StandardButton
          isLink={false}
          title="Отмена"
          className="lk-comment-popup__buttons-item"
          bgType={BLUE_BORDER}
          onClick={closePopup}
        />

        <StandardButton
          isLink={false}
          title="Сохранить"
          className="lk-comment-popup__buttons-item"
          bgType={BLUE}
          onClick={saveChanges}
          disabled={!isChangeOfCommentsStared}
          isLoading={saveStatus === LOADING}
        />
      </div>

      {saveStatus === SUCCESS && (
        <p className="lk-comment-popup__message">Комментарий успешно обновлен!</p>
      )}

      {saveStatus === FAIL && (
        <p className="lk-comment-popup__message">
          К сожалению, на не удалось обновить ваш комментарий. Попробуйте повторить запрос позже!
        </p>
      )}
    </div>
  );
};

LkCommentsPopup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default connect()(LkCommentsPopup);
