import React from 'react';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import { BLUE } from 'constants/index';

import './LogOutPopup.scss';

const LogOutPopup = ({ isWarning, closeAction }) => {
  return (
    <div className="logout-popup">
      {isWarning ? (
        <>
          <div className="logout-popup__text">
            Вы бездействовали на сайте. Через 5 минут ваша сессия закончится.
          </div>

          <StandardButton
            title="Вернуться"
            isLink={false}
            onClick={closeAction}
            bgType={BLUE}
            className="logout-popup__button"
          />
        </>
      ) : (
        <>
          <div className="logout-popup__text">Ваша сессия завершена из-за неактивности</div>

          <StandardButton
            title="Войти снова"
            isLink
            href={process.env.REACT_APP_SSO_AUTH_URL}
            bgType={BLUE}
            isOutAppLink
            className="logout-popup__button"
          />
        </>
      )}
    </div>
  );
};

export default LogOutPopup;
