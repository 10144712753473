import React from 'react';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as IconFile } from 'assets/images/icon-file-plus.svg';

import './UploadFiles.scss';

const UploadFiles = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path} className="upload-files__list-item">
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="upload-files">
      <div {...getRootProps({ className: 'upload-files__input-wrap' })}>
        <input {...getInputProps()} />
        <IconFile className="upload-files__icon" />
        <p className="upload-files__text">Приложить файл с описанием</p>
      </div>
      {files.length > 0 && (
        <div className="upload-files__list-wrap">
          <ul className="upload-files__list">{files}</ul>
        </div>
      )}
    </div>
  );
};

export default UploadFiles;
