import React from 'react';
import PropTypes from 'prop-types';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import { BLUE, BLUE_BORDER, LOADING } from 'constants/index';

import './CloseAllPositionsPopup.scss';

const CloseAllPositionsPopup = ({ closePopup, closeAllPositionsHandler, status }) => {
  return (
    <div className="close-all-positions-popup">
      {!status && (
        <>
          <h2 className="close-all-positions-popup__title">Закрытие всех позиций</h2>
          <div className="close-all-positions-popup__desc">
            Вы действительно хотите закрыть все позиции?
          </div>

          <div className="close-all-positions-popup__list">
            <StandardButton
              className="close-all-positions-popup__list-item"
              isLink={false}
              onClick={closePopup}
              title="Отмена"
              bgType={BLUE_BORDER}
            />
            <StandardButton
              className="close-all-positions-popup__list-item"
              isLink={false}
              onClick={closeAllPositionsHandler}
              title="Продолжить"
              bgType={BLUE}
            />
          </div>
        </>
      )}

      {status === LOADING && (
        <div className="close-all-positions-popup__loader">
          <CircleLoader />
        </div>
      )}
    </div>
  );
};

CloseAllPositionsPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  closeAllPositionsHandler: PropTypes.func.isRequired,
  status: PropTypes.string,
};

CloseAllPositionsPopup.defaultProps = {
  status: null,
};

export default CloseAllPositionsPopup;
