import React from 'react';

import Breadcrumbs from 'modules/shared/components/Breadcrumbs/Breadcrumbs';
import BecomePartnerForm from 'modules/main/components/BecomePartnerForm/BecomePartnerForm';

import partnersImage from 'assets/images/partners-portfolio-img.svg';

import './PartnerPage.scss';

const PartnerPage = () => {
  return (
    <div className="partner-page">
      <div className="partner-page__container container">
        <Breadcrumbs
          link={window.location.pathname}
          anchor="Форма заявки"
          className="partner-page__bread-crumbs"
        />
        <div className="partner-page__main">
          {/* СТАТЬ ПАРТНЕРОМ */}
          <div className="partner-page__info">
            <div className="partner-page__info-col partner-page__info-col_info">
              <div className="partner-page__title">Стать партнером</div>
              <div className="partner-page__desc">
                <p className="partner-page__desc-item">
                  Если Вы профессиональный трейдер и Ваша стратегия эффективна и приносит стабильный
                  доход, Вы можете поделиться данным решением с пользователями платформы FOLLOW ME и
                  получать дополнительное вознаграждение.
                </p>
                <p className="partner-page__desc-item">
                  Всё, что потребуется – это подтвердить Ваш опыт и результаты работы стратегии.
                </p>
              </div>
            </div>
            <div className="partner-page__info-col partner-page__info-col_img">
              <img src={partnersImage} alt="" className="partner-page__img" />
            </div>
          </div>
          {/* СТАТЬ ПАРТНЕРОМ */}

          <BecomePartnerForm />
        </div>
      </div>
    </div>
  );
};

export default PartnerPage;
