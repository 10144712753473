import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
// import Dropzone from 'react-dropzone';
// import { connect } from 'react-redux';

// import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import fakePhoto from 'assets/images/default-avatar.svg';
// import { ReactComponent as IconPhoto } from 'assets/images/icon-photo.svg';

import './AvatarUpload.scss';

export const AvatarUpload = ({ userAvatar, className }) => {
  // const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const avatarUrl = fakePhoto;
  // const [avatarImg, setAvatarImg] = useState(userAvatar !== null ? avatarUrl : fakePhoto);
  const avatarImg = userAvatar !== null ? avatarUrl : fakePhoto;

  /** Устанавливаем новый автаар */
  /*const handleAvatarChange = (file) => {
    const fileSizeMb = file.size / 1024 / 1024;

    if (fileSizeMb > 8) {
      // dispatch(setAvatarError(true));
    } else {
      setIsAvatarLoading(true);
      // dispatch(setAvatarError(false));

      // dispatch(setAvatar(file))
      //   .then(() => dispatch(getUser()))
      //   .then(res => {
      //     if (res && res.data && res.data.avatar) {
      //       setAvatarImg(`${process.env.REACT_APP_API_URL}/settings/avatar/${res.data.avatar}`);
      //     } else {
      //       throw new Error('Ответ от сервера не содержит нужных полей');
      //     }
      //   })
      //   .catch(() => {
      //     /!** Показываем сообщение об ошибке загрузки аватара на 10сек *!/
      //     dispatch(setAvatarError(true));
      //
      //     /!** После чего убираем его *!/
      //     setTimeout(() => {
      //       dispatch(setAvatarError(false));
      //     }, 10000);
      //   })
      //   .then(() => {
      //     setIsAvatarLoading(false);
      //   });
    }
  };*/

  // const handleDropEvent = (acceptedFiles) => {
  //   handleAvatarChange(acceptedFiles[0]);
  // };

  return (
    <div
      className={cx('avatar-upload', {
        [className]: className,
      })}
      data-testid="avatar-upload"
    >
      <div className="avatar-upload__dropzone-wrap">
        {/*{!isAvatarLoading && <img src={avatarImg} alt="" className="avatar-upload__avatar" />}*/}
        <img src={avatarImg} alt="" className="avatar-upload__avatar" />
        {/* eslint-disable react/prop-types, react/jsx-props-no-spreading */}
        {/*<Dropzone onDrop={(acceptedFiles) => handleDropEvent(acceptedFiles)}>*/}
        {/*  {({ getRootProps, getInputProps }) => (*/}
        {/*    <section>*/}
        {/*      <div {...getRootProps()}>*/}
        {/*        <input {...getInputProps()} />*/}
        {/*      </div>*/}
        {/*    </section>*/}
        {/*  )}*/}
        {/*</Dropzone>*/}
        {/* eslint-enable react/prop-types, react/jsx-props-no-spreading */}

        {/*{isAvatarLoading ? (*/}
        {/*  <div className="avatar-upload__avatar-loading">*/}
        {/*    <CircleLoader className="avatar-upload__avatar-loading-icon" mainColor="#ffffff" />*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <>{!avatarImg && <IconPhoto className="avatar-upload__avatar-icon" />}</>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

AvatarUpload.propTypes = {
  className: PropTypes.string,
  userAvatar: PropTypes.string,
  // dispatch: PropTypes.func.isRequired,
};

AvatarUpload.defaultProps = {
  userAvatar: null,
  className: null,
};

export default AvatarUpload;
