import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Profit.scss';

const Profit = ({ className, percent, customText }) => {
  return (
    <div
      className={cx('profit', {
        [className]: className,
      })}
    >
      <span
        className={cx('profit__value', {
          profit__value_negative: percent < 0,
          profit__value_positive: percent > 0,
        })}
      >
        {percent > 0 && '+'}
        {/*{percent || 0} %*/}
        {percent} %
      </span>
      <p className="profit__text">
        {customText || 'среднегодовая доходность в рублях с учетом издержек'}
      </p>
    </div>
  );
};

Profit.propTypes = {
  className: PropTypes.string,
  percent: PropTypes.number,
  customText: PropTypes.string,
};

Profit.defaultProps = {
  className: null,
  customText: null,
  percent: 0,
};

export default Profit;
