import React from 'react';
import AriaModal from 'react-aria-modal';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as IconClose } from 'assets/images/close.svg';

import './PopupWrap.scss';

const PopupWrap = ({ children, mounted, closePopup, className, isCustomCloseButton }) => {
  return (
    <AriaModal
      underlayClass={cx('popup-wrap', {
        [className]: className,
      })}
      onExit={closePopup}
      mounted={mounted}
      titleText=" "
      underlayColor="rgba(10, 17, 37, 0.5)"
    >
      <div className="popup-wrap__inner">
        {children}

        {!isCustomCloseButton && (
          <button className="popup-wrap__close" onClick={closePopup} type="button">
            <IconClose className="popup-wrap__close-icon" />
          </button>
        )}
      </div>
    </AriaModal>
  );
};

PopupWrap.propTypes = {
  children: PropTypes.node.isRequired,
  mounted: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  className: PropTypes.string,
  isCustomCloseButton: PropTypes.bool,
};

PopupWrap.defaultProps = {
  isCustomCloseButton: false,
};

export default PopupWrap;
