import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import './Breadcrumbs.scss';

const Breadcrumbs = ({ link, anchor, className }) => {
  return (
    <div
      className={cx('breadcrumbs', {
        [className]: className,
      })}
    >
      <Link className={'breadcrumbs__item'} to={'/'}>
        Главная
      </Link>
      <Link className={'breadcrumbs__item'} to={link}>
        {anchor}
      </Link>
    </div>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
};

Breadcrumbs.defaultProps = {
  className: null,
};

export default Breadcrumbs;
