import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { selectStyles } from 'assets/style/select';
import './StandardSelect.scss';

const StandardSelect = ({
  options,
  defaultValue,
  onChange,
  className,
  placeholder,
  // isCustomChange,
}) => {
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      onChange={(selectedOption) => onChange(selectedOption)}
      styles={selectStyles}
      className={className}
      classNamePrefix="standard-select"
      placeholder={placeholder || 'Выберите опцию'}
    />
  );
};

const selectOption = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  accountid: PropTypes.string,
});

StandardSelect.propTypes = {
  options: PropTypes.arrayOf(selectOption),
  defaultValue: selectOption,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  // isCustomChange: PropTypes.bool,
};

StandardSelect.defaultProps = {
  defaultValue: null,
  className: '',
  placeholder: '',
  // isCustomChange: false,
};

export default StandardSelect;
