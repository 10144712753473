import React from 'react';
import cx from 'classnames';

import PartnerItem from 'modules/main/components/PartnerItem/PartnerItem';

import { ReactComponent as IconBankLogo } from 'assets/images/bank-rus.svg';
import { ReactComponent as IconCalendar } from 'assets/images/icon-date-calendar.svg';
import { ReactComponent as IconTransfer } from 'assets/images/icon-transfer.svg';
import { ReactComponent as IconCertificate } from 'assets/images/icon-certificate.svg';
import { ReactComponent as IconFlag } from 'assets/images/icon-flag.svg';
import { ReactComponent as IconUsers } from 'assets/images/icon-users-big.svg';
import { ReactComponent as IconBuilding } from 'assets/images/icon-building.svg';
import licenseImg from 'assets/images/license.jpg';

import './Partner.scss';

const Partner = React.forwardRef((props, ref) => {
  const list = [
    {
      id: 1,
      title: '1997 год',
      desc: 'Год основания компании',
      icon: <IconCalendar className="partner-item__icon" />,
    },
    {
      id: 2,
      title: '7.2 трлн ₽',
      desc: 'Оборот в год',
      icon: <IconTransfer className="partner-item__icon" />,
    },
    {
      id: 3,
      title: 'АА-',
      desc: 'Очень высокая степень надежности. Присвоен Национальным Рейтинговым Агентством',
      icon: <IconCertificate className="partner-item__icon" />,
    },
    {
      id: 4,
      title: 'Ведущий оператор',
      desc: 'Входит в ТОП 10 на Московской бирже и Санкт-Петербургской бирже',
      icon: <IconFlag className="partner-item__icon" />,
    },
    {
      id: 5,
      title: '37 000+',
      desc: '* Клиентов, зарегистрированных на Московской бирже',
      icon: <IconUsers className="partner-item__icon" />,
    },
    {
      id: 6,
      title: '18',
      desc: 'Региональных офисов',
      icon: <IconBuilding className="partner-item__icon" />,
    },
    {
      id: 7,
      link: 'http://www.cbr.ru/finorg/foinfo/?ogrn=1027700075941',
      linkText: 'АЛОР БРОКЕР на сайте Банка России',
      logo: <IconBankLogo className="partner-item__logo partner-item__logo_bank" />,
    },
    {
      id: 8,
      link: 'https://www.alorbroker.ru/openinfo/license',
      linkText: 'Официальный сайт alorbroker.ru',
      info:
        'Лицензия профессионального участника рынка ценных бумаг Российской Федерации на осуществление брокерской деятельности на рынке ценных бумаг без ограничения срока действия № 077-04827-100000 от 13.03.2001',
      license: licenseImg,
    },
  ];

  return (
    <div className="partner" ref={ref}>
      <div className="partner__container">
        <h2 className="partner__title">Стратегический партнер платформы</h2>
        <div className="partner__desc">
          Платформа разработана на базе одной из крупнейших брокерских компаний в России - АЛОР
          БРОКЕР
        </div>
      </div>

      <div className="partner__main">
        <div className="partner__list">
          <div className="partner__list-line">
            <div className="partner__list-line-container">
              {list.slice(0, 3).map((item) => (
                <PartnerItem className="partner__item" data={item} key={item.id} />
              ))}
            </div>
          </div>

          <div className="partner__list-line">
            <div className="partner__list-line-container">
              {list.slice(3, 6).map((item) => (
                <PartnerItem className="partner__item" data={item} key={item.id} />
              ))}
            </div>
          </div>

          <div className="partner__list-line">
            <div className="partner__list-line-container partner__list-line-container_wide">
              {list.slice(6, 8).map((item, index) => (
                <PartnerItem
                  className={cx('partner__item', {
                    partner__item_text: index === 1,
                  })}
                  data={item}
                  key={item.id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Partner;
