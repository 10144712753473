import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as IconLogout } from 'assets/images/icon-logout.svg';

import './ProfileDropdown.scss';

const ProfileDropdown = React.forwardRef((props, ref) => {
  const { name, goToLk, logOut } = props;

  return (
    <div ref={ref} className="profile-dropdown">
      <div className="profile-dropdown__item">
        <Link className="profile-dropdown__name" to="/lk" onClick={goToLk}>
          {name}
        </Link>
      </div>

      <div className="profile-dropdown__item">
        <button className="profile-dropdown__logout" type="button" onClick={logOut}>
          <span className="profile-dropdown__logout-text">Выйти</span>
          <IconLogout className="profile-dropdown__logout-icon" />
        </button>
      </div>
    </div>
  );
});

ProfileDropdown.rpopTypes = {
  name: PropTypes.string,
  goToLk: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
};

ProfileDropdown.defaultProps = {
  name: 'Юзер',
};

export default ProfileDropdown;
