import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { YMInitializer } from 'react-yandex-metrika';

import HomePage from 'modules/main/components/HomePage/HomePage';
import About from 'modules/main/components/About/About';
import Managers from 'modules/main/components/Managers/Managers';
import StrategiesSingle from 'modules/main/components/StrategiesSingle/StrategiesSingle';
import StrategiesPage from 'modules/main/components/StrategiesPage/StrategiesPage';
import Lk from 'modules/lk/components/Lk/Lk';
import AccessRoute from 'HOC/AccessComponent';
import Contacts from 'modules/main/components/Contacts/Contacts';
import ScrollToTop from 'modules/shared/components/ScrollToTop/ScrollToTop';
import Header from 'modules/shared/components/Header/Header';
import Loader from 'modules/shared/components/Loader/Loader';
import Footer from 'modules/shared/components/Footer/Footer';
import PopupWrap from 'modules/shared/components/PopupWrap/PopupWrap';
import LogOutPopup from 'modules/main/components/LogOutPopup/LogOutPopup';
import PartnerPage from 'modules/main/components/PartnerPage/PartnerPage';
import ConnectPage from 'modules/main/components/ConnectPage/ConnectPage';
import DocumentPage from 'modules/main/components/DocumentPage/DocumentPage';

import {
  findRegisteredUser,
  registerUser,
  getApiKeyForUser,
  refreshTokenOnServer,
  createNewConnectionOnServer,
  getConnections,
} from 'modules/main/actions';
import {
  defineGetParameter,
  getCookie,
  setCookie,
  generatePassword,
  removeCookie,
} from 'helpers/index';
import { getClientDetailsByUserName, setIsUserLoggedIn } from 'modules/lk/actions';

import './Main.scss';

const Main = ({ dispatch, isLoggedIn }) => {
  const [userName, setUserName] = useState('');
  const [name, setName] = useState('');
  // const [redirectOnOAuth, setRedirectOnOAuth] = useState(false);
  const [redirectOnStartWithoutCode, setRedirectOnStartWithoutCode] = useState(false);
  // const [isAuthProcessLoading, setIsAuthProcessLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState(false);
  const [isAutoLogoutPopupOpen, setIsAutoLogoutPopupOpen] = useState(false);

  /** Берем данные из параметров url после того как залогинились на сайте login.alor.ru */
  useEffect(() => {
    const { token, userName, refreshToken } = defineGetParameter();

    if (token && userName && refreshToken) {
      setCookie('alor_refresh_token', refreshToken);
      setCookie('alor_token', token);
      setUserName(userName);
    }
  }, []);

  useEffect(() => {
    if (userName) {
      setIsLoading(true);
      dispatch(findRegisteredUser(userName))
        .then((res) => {
          if (!res) {
            dispatch(registerUser(userName, generatePassword(), userName))
              .then((userIdData) => {
                if (userIdData && userIdData.userid) {
                  return dispatch(getApiKeyForUser(userIdData.userid));
                }
              })
              .then((apiKeyRes) => {
                setCookie(userName, apiKeyRes && apiKeyRes.key);
                setCookie('alor_user_name', userName);
                return dispatch(createNewConnectionOnServer(getCookie('alor_refresh_token')));
              })
              .then(() => {
                setIsLoading(false);
                setRedirectOnStartWithoutCode(true);
              })
              .catch(() => {});
          } else {
            dispatch(getApiKeyForUser(res.userid))
              .then((apiKeyRes) => {
                setCookie(userName, apiKeyRes && apiKeyRes.key);
                setCookie('alor_user_name', userName);

                return dispatch(getConnections());
              })
              .then((connectionsArr) => {
                return dispatch(
                  refreshTokenOnServer(
                    getCookie('alor_refresh_token'),
                    connectionsArr && connectionsArr[0] && connectionsArr[0].connectionid,
                  ),
                );
              })
              .then(() => {
                setIsLoading(false);
                setRedirectOnStartWithoutCode(true);
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userName]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (
      getCookie('alor_user_name') &&
      getCookie('alor_refresh_token') &&
      getCookie(getCookie('alor_user_name'))
    ) {
      dispatch(setIsUserLoggedIn(true));
    } else {
      dispatch(setIsUserLoggedIn(false));
      // dispatch(setIsUserLoggedIn(true));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const logOut = () => {
    const userName = getCookie('alor_user_name');
    if (userName) {
      removeCookie(userName);
      removeCookie('alor_user_name');
      removeCookie('alor_refresh_token');
      removeCookie('alor_token');
      dispatch(setIsUserLoggedIn(false));
    }
  };

  /** Берем имя юзера */
  useEffect(() => {
    dispatch(
      getClientDetailsByUserName(
        getCookie('alor_user_name') && getCookie('alor_user_name').toUpperCase(),
      ),
    )
      // dispatch(getClientDetailsByUserName('p066723'.toUpperCase()))
      .then((res) => {
        if (res) {
          setName(res.name || 'Юзер');
        }
      })
      .catch(() => {
        setName('юзер');
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  let timer = null;
  let finalTimer = null;
  const seconds = 900000;
  const finalSeconds = 1800000;

  const handleMouseMove = () => {
    setIsAutoLogoutPopupOpen(false);

    if (timer) {
      clearTimeout(timer);
    }

    if (finalTimer) {
      clearTimeout(finalTimer);
    }
    timer = setTimeout(() => {
      setIsWarningPopupOpen(true);
    }, seconds);

    finalTimer = setTimeout(() => {
      setIsWarningPopupOpen(false);
      logOut();
      setIsAutoLogoutPopupOpen(true);
    }, finalSeconds);
  };

  useEffect(() => {
    if (isLoggedIn) {
      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        if (timer) {
          clearTimeout(timer);
        }
        if (finalTimer) {
          clearTimeout(finalTimer);
        }
      };
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isLoggedIn]);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (redirectOnStartWithoutCode) {
    window.location.href = window.location.origin;
  }

  return (
    <Router>
      <ScrollToTop />

      <YMInitializer
        accounts={[68569111]}
        options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
      />

      <Route
        render={({ location }) => (
          <Header
            isLight={location.pathname === '/' || location.pathname === '/about'}
            name={name}
          />
        )}
      />
      <div className="main">
        <Switch>
          {/* The Switch decides which component to show based on the current URL.*/}
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={About} />
          <Route path="/managers" component={Managers} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/become-a-partner" component={PartnerPage} />
          <Route path="/connect" component={ConnectPage} />
          <Route path="/doc/:uniqueId" component={DocumentPage} />
          <AccessRoute path="/lk" component={Lk} isPrivate />

          <Route exact path="/strategies" component={StrategiesPage} />
          <Route path="/strategies/:uniqueId" component={StrategiesSingle} />
        </Switch>
      </div>

      <Footer />

      {isLoading && <Loader inverted />}

      <PopupWrap
        closePopup={() => setIsWarningPopupOpen(false)}
        mounted={isWarningPopupOpen}
        className="popup-wrap_logout"
      >
        <LogOutPopup closeAction={() => setIsWarningPopupOpen(false)} isWarning />
      </PopupWrap>

      <PopupWrap
        closePopup={() => setIsAutoLogoutPopupOpen(false)}
        mounted={isAutoLogoutPopupOpen}
        className="popup-wrap_logout"
      >
        <LogOutPopup closeAction={() => setIsAutoLogoutPopupOpen(false)} />
      </PopupWrap>
    </Router>
  );
};

Main.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.lk.isLoggedIn,
});

export default connect(mapStateToProps)(Main);
